import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Breadcrumb, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateSampleQueues } from '../../actions'
import moment from 'moment'
import axios from 'axios'
import print from 'print-js'
import * as JSZip from 'jszip'
import * as JSZipUtils from 'jszip-utils'
import { isMobile } from 'react-device-detect'
import { saveAs } from 'file-saver'
import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import ShareReportModal from '../ShareReportModal'
import ConditionalRender from '../ConditionalRender'
import ActionsMenu from './ActionsMenu'
import SampleSummary from './SampleSummary'
import ClearModal from './ClearModal'
import QCBarChart from '../Charts/QCBarChart'
import BarChartComponent from '../Charts/BarChartComponent'
import PieChartComponent from '../Charts/PieChartComponent'
import DerivedCompoundsTotals from '../SampleTests/DerivedCompoundsTotals'
import ReportResultsTable from '../SampleTests/ReportResultsTable'
import QCReportResultsTable from '../SampleTests/QCReportResultsTable'
import constants from '../../lib/constants'
import getAxiosInstance from '../../axios'
import cannidAPI from '../../cannidAPI/client'
import { SampleTestServices } from '../../services/SampleTest'
import { CsvDownload } from '../../services/CsvDownloads'
import pdfDownload from '../../services/pdfDownload'
import { pathEncoderS3 } from '../../lib/pathEncoderS3'
import isQC from '../../lib/isQC'
import SampleProcessor from '../../lib/SampleProcessor'
import ChromatogramLineChart from '../Charts/ChromatogramLineChart'


const mapStateToProps = (state) => ({
	user: state.current_user,
	accessCreds: state.accessCreds
})

const mapDispatchToProps = (dispatch) => ({
	updateSampleQueues: (sampleQueues) => {
	  dispatch(updateSampleQueues(sampleQueues))
	}
})

const QC_INVALID_BEFORE_DATE = moment('2021-12-07T00:00:00Z')
const DEFAULT_ERROR_MESSAGE = 'Something went wrong on our end. Please try again later'
class SampleResults extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sample: null,
			isOutdatedQC: null,
			error: null,
			showShareModal: false,
			showClearModal: false,
			chemidTransferSuccess: false
		}

		this.mounted = false
		this.sampleProcessor = null
		this.sampleId = parseInt(this.props.match.params.id, 10)
	}

	componentDidMount() {
		this.mounted = true
		document.addEventListener('keydown', this.onKeyEvent)

		getAxiosInstance().get(`${process.env.REACT_APP_API_ROOT}/samples/${this.sampleId}`)
			.then((response) => {
				const sample = response.data
				const formattedDate = `${sample.created_at.split(' ')[0]}T${sample.created_at.split(' ')[1]}Z`
				if (this.mounted) {
					if (isQC.sample(sample) && moment(formattedDate).isSameOrBefore(QC_INVALID_BEFORE_DATE)) {
						this.setState({ isOutdatedQC: true })
					}

					this.sampleProcessor = new SampleProcessor(sample)
					this.setState({ sample })
				}
			})
			.catch(() => {
				if (this.mounted) this.setState({ error: DEFAULT_ERROR_MESSAGE })
			})
	}

	componentWillUnmount() {
		this.mounted = false
		document.removeEventListener('keydown', this.onKeyEvent)
	}

	onKeyEvent = (event) => {
		if (event.ctrlKey && (event.key === 'p' || event.key === 'P')) {
			this.onPrintReport()
		}
	}

	onAddSampleToQueue = () => {
		cannidAPI.post(`/sample_queues/`, {
			sample: this.state.sample
		})
		.then((response) => this.props.updateSampleQueues(response.data))
		.catch(() => this.setState({ error: DEFAULT_ERROR_MESSAGE }))
	}

	onShare = () => {
		this.setState({ showShareModal: true })
	}

	onDownloadCSV = () => {
		const sample = this.state.sample
		CsvDownload.downloadData(
			SampleTestServices.getSampleTestResultDownloadUrl(sample.id),
			sample.intake_form.strain_name || 'SAMPLE',
			sample.tested_at.split(' ')[0]
		).catch(() => this.setState({ error: DEFAULT_ERROR_MESSAGE }))
	}

	onDownloadJSON = () => {
		const sample = this.state.sample
		const content = window.URL.createObjectURL(new Blob([JSON.stringify(sample)]))
		const link = document.createElement('a')
		link.href = content
		link.setAttribute('download', `${sample.intake_form.strain_name || 'SAMPLE'}_${sample.id}.json`)
		document.body.appendChild(link)
		link.click()
	}

	onDownloadPDF = () => {
		const sample = this.state.sample
		pdfDownload(sample.share_key, `${sample.tested_at.split(' ')[0]}_${sample.intake_form.strain_name.replace(' ', '_') || 'SAMPLE'}_Report.pdf`)
		.catch(() => this.setState({ error: DEFAULT_ERROR_MESSAGE }))
	}

	onDownloadCOA = () => {
		const sample = this.state.sample
		pdfDownload(sample.share_key,
			`${sample.tested_at.split(' ')[0]}_${sample.intake_form.strain_name.replace(' ', '_') || 'SAMPLE'}_COA.pdf`,
			'/coa')
		.catch(() => this.setState({ error: DEFAULT_ERROR_MESSAGE }))
	}

	onDownloadChromes = () => {
		const sample = this.state.sample
		const errors = {}
		const zip = new JSZip()
		const zipFilename = `${sample.tested_at.split(' ')[0]}_${sample.intake_form.strain_name.replace(' ', '_') || 'SAMPLE'}_Chromatograms.zip`
		const urls = []

		// build array of urls to include in the zipfile
		sample.results.forEach((result, index) => {
			if (result.chromeUrl) {
				urls.push(result.chromeUrl)
			}
			else if (result.chromeUploadError) {
				errors[`inj${index + 1}`] = 'chromeUploadError'
				urls.push('error')
			}
			else {
				const chromaID = pathEncoderS3(result)
				if (chromaID) {
					const chroma = `https://${this.props.accessCreds.CHROMATOGRAPHS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${chromaID}/signal1.csv`
					urls.push(chroma)
					
				}
				else {
					errors[`inj${index + 1}`] = 'chromeKeyEncodingError'
					urls.push('error')
				}
			}
		})

		// build the zipfile and download
		let urlsProcessed = 0
		urls.forEach((url, index) => {
			const filename = `Chrome_Injection_${index + 1}.csv`
			// loading a file and add it in a zip file
			JSZipUtils.getBinaryContent(url, (binaryContentError, data) => {
				if (this.mounted === false) {
					return
				}

				if (binaryContentError) {
					this.setState({ error: `Error getting Chromatogram URL for injection ${index}. Functional error. (Reference ${binaryContentError})` })
					throw binaryContentError
				}
				else {
					if (url !== 'error') {
						zip.file(filename, data, { binary: true })
					}
					urlsProcessed += 1
				}

				if (urlsProcessed === urls.length) {
					zip.generateAsync({ type: 'blob' }).then((blob) => {
						if (this.mounted === false) {
							return
						}

						saveAs(blob, zipFilename)

						let errorMessage = null
						if (urls.includes('error')) {
							let errorReference = ''
							Object.keys(errors).forEach((inj) => { errorReference += `${inj}:${errors[inj]},` })
							errorMessage = `Notice: There were errors retrieving chromatogram file(s). (Reference ${errorReference})`
						}

						if (errorMessage) {
							this.setState({ error: errorMessage })
						}
					}).catch((generateError) => {
						this.setState({ error: `Error creating Chromatogram zipfile for download. Please try again. (Reference ${generateError})` })
					})
				}
			})
		})
	}

	onDownloadVialZip = (url, vialNumber) => {
		const sample = this.state.sample

		axios({ url, method: 'GET', responseType: 'blob' })
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					const content = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = content
					link.setAttribute('download', `${sample.tested_at.split(' ')[0]}_${sample.intake_form.strain_name.replace(' ', '_') || 'SAMPLE'}_Vial${vialNumber}.zip`)
					document.body.appendChild(link)
					link.click()
				}
				else {
					this.setState({ error: `Error downloading raw data zipfile. (Reference ${response.status} ${response.statusText})` })
				}
			})
			.catch((error) => {
				this.setState({ error: `Error downloading raw data zipfile. (Reference ${error})` })
			})
	}

	onPrintReport = (coaReport = false) => {
		let template
		if (coaReport === true) {
			template = '/coa'
		}

		getAxiosInstance().get(SampleTestServices.getPdfReportDownloadUrl(this.state.sample.share_key, template), { responseType: 'blob' })
			.then((response) => {
				if (this.mounted === false) {
					return
				}

				if (response.status === 200) {
					try {
						if (isMobile) {
							if (!navigator.userAgent.match('CriOS') && !navigator.userAgent.toLowerCase().indexOf('android') > -1) {
								print(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
							}
							else {
								window.open(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
							}
						}
						else {
							print(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
						}
					}
					catch (e) {
						this.setState({ error: DEFAULT_ERROR_MESSAGE })
					}
				}
			})
			.catch(() => {
				if (this.mounted) {
					this.setState({ error: DEFAULT_ERROR_MESSAGE })
				}
			})
	}

	onClearSample = () => {
		this.setState({ showClearModal: true })
	}

	clearSample = () => {
		this.setState({ showClearModal: false })
		SampleTestServices.clearSampleResult(this.state.sample.id)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error()
				}
				window.location.reload()
			})
			.catch(() => this.setState({ error: 'Failed to clear result for this sample. Refresh the page and try again. ("Viewer" role not permitted to clear result)' }))
	}

	chemidTransferResponse = (response) => {
		const data = response.data
		if (data.error) this.setState({ chemidTransferSuccess: false, error: `Failed to transfer sample to ChemID. Please try again or contact support. ${data.error.message}` })
		else this.setState({ chemidTransferSuccess: true, error: null })
	}

	buildBreadcrumb() {
		const sample = this.state.sample
		let content = null

		if (sample) {
			let listLinkRoute = constants.APPLICATION_ROUTE.SAMPLE.LIST.ROUTE
			let listLinkName = constants.APPLICATION_ROUTE.SAMPLE.LIST.NAME

			if (isQC.sample(sample)) {
				listLinkRoute = constants.APPLICATION_ROUTE.QC.LIST.ROUTE
				listLinkName = constants.APPLICATION_ROUTE.QC.LIST.NAME
			}

			content = (
				<>
					<Breadcrumb.Divider icon='right angle' />
					<Link to={listLinkRoute}><Breadcrumb.Section>{listLinkName}</Breadcrumb.Section></Link>
					<Breadcrumb.Divider icon='right angle' />
					<Breadcrumb.Section>{this.state.sample.intake_form ? this.state.sample.intake_form.strain_name : 'N/A'}</Breadcrumb.Section>
				</>
			)
		}

		return (
			<Breadcrumb>
				<Link to={constants.APPLICATION_ROUTE.HOME.ROUTE}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
				{content}
			</Breadcrumb>
		)
	}

	buildPartialRunMessage() {
		const sample = this.state.sample
		if (!sample || this.sampleProcessor.finished()) {
			return null
		}

		let message
		const runCount = this.sampleProcessor.runCount()
		const totalVials = this.sampleProcessor.preparedSampleCount()

		if (runCount === 0) {
			message = <Message warning size='large'>{runCount} of {totalVials} injections run. Analyze remaining vials to complete calculation.</Message>
		}
		else {
			message = (
				<Message warning size='large'>
					Results displayed below are <strong>PRELIMINARY RESULTS</strong>, calculated using {runCount} of {totalVials} prepared vials. Analyze remaining vials to complete calculation.
				</Message>
			)
		}

		return (
			<Grid.Row>
				<Grid.Column textAlign='center'>
					{message}
				</Grid.Column>
			</Grid.Row>
		)
	}

	buildCharts() {
		const { sample, isOutdatedQC } = this.state
		const sampleProcessor = this.sampleProcessor

		if (!sample) {
			return null
		}
		if (sample.sample_type === 'OVER') {
			return (
				<Grid.Row>
					<Grid.Column textAlign='center' className='sample_report'>
						<div id='runStatus'>
							Cann-ID has detected an error in this sample preparation. Please click the following link and follow the instructions to remake the sample. <a href='https://www.evernote.com/l/Aoo53S-3qJhC8LCO2g2ywILg_z5O_SvdoiI' target='_blank' rel='noreferrer'>Sample Preparation Checklist</a>
						</div>
					</Grid.Column>
				</Grid.Row>
			)
		}
		if (this.sampleProcessor.runCount() === 0) {
			return (
				<Grid.Row>
					<Grid.Column textAlign='center'>
						<Message warning size='large'>
							<Message.Header>
								Test Not Run
							</Message.Header>
							This test sample has not yet been run. Add it to machine queue and complete the test to see results.
						</Message>
					</Grid.Column>
				</Grid.Row>
			)
		}

		const sampleHasDerivedCompounds = Object.keys(sample.derived_compounds).length > 0
		let tableToShow = null
		let barChartToShow = null
	
		if (isQC.sample(sample)) {
			tableToShow = <QCReportResultsTable sample={sample} />
			barChartToShow = <QCBarChart sample={sample} />
		}
		else {
			tableToShow = <ReportResultsTable sample={sample} />
			barChartToShow = <BarChartComponent barchart={sample.barchart} elution_order={sample.elution_order} />
		}

		return (
			<Grid.Row>
				<ConditionalRender condition={!isQC.sample(sample)}>
					<Grid.Column width={16} className='chartsColumn'>
						<PieChartComponent data={sample} />
					</Grid.Column>
					<Grid.Column width={16} className='chartsColumn'>
						<ConditionalRender condition={sampleHasDerivedCompounds}>
							<DerivedCompoundsTotals derivedCompounds={sample.derived_compounds} />
						</ConditionalRender>
					</Grid.Column>
				</ConditionalRender>
				<Grid.Column width={16} className='chartsColumn'>
					{barChartToShow}
				</Grid.Column>
				<Grid.Column width={16} textAlign='center' className='sample_report chartsColumn'>
					<ChromatogramLineChart openOnLoad sample={this.state.sample}/>
				</Grid.Column>
				<Grid.Column width={16} className='chartsColumn'>
					{tableToShow}
				</Grid.Column>
			</Grid.Row>
		)
	}

	render() {
		const { sample, isOutdatedQC, error, showShareModal, showClearModal, chemidTransferSuccess } = this.state

		if (showShareModal && sample) {
			let coaURL = null
			if (sample.coa && this.props.user.actual_role.slug === constants.USER_ROLE.SUPER_ADMIN_SLUG) {
				// if coa object has a coa_url we'll use it (in respect to testing vs production s3 buckets)
				// otherwise, default to production as per legacy behavior
				coaURL = sample.coa_data.coa_url ? sample.coa_data.coa_url
					: `https://ionizationlab.s3.us-west-2.amazonaws.com/${this.state.sample.coa_key}.pdf`
			}

			return <ShareReportModal close={() => this.setState({ showShareModal: false })} sample={sample} coaUrl={coaURL} />
		}
		if (showClearModal && sample) {
			return <ClearModal onClose={() => this.setState({ showClearModal: false })} onConfirm={this.clearSample} onDownloadCOA={this.onDownloadCOA} sample={sample} />
		}

		return (
			<div>
				<section id='mainContent' className='app light'>
					<AppHeader title={<h1>Results</h1>} breadcrumb={this.buildBreadcrumb()} />
					<section className='app light'>
						<ConditionalRender condition={error}>
							<ErrorMessages errors={error} />
						</ConditionalRender>
						<ConditionalRender condition={chemidTransferSuccess}>
							<Message success>
								<Message.Header>
									Success! Sample data transferred to ChemID!
								</Message.Header>
								<a href='https://lab.chemid.com/login' target='_blank'  rel='noreferrer'>
									Log into your ChemID account
								</a> to review the transferred sample.
							</Message>
						</ConditionalRender>
						<ConditionalRender condition={sample && !isOutdatedQC}>
							<Grid centered className='sample_report'>
								{this.buildPartialRunMessage()}
								<Grid.Row>
									<Grid.Column textAlign='center'>
										<ActionsMenu
											sample={sample}
											onAddSampleToQueue={this.onAddSampleToQueue}
											onShare={this.onShare}
											onDownloadCSV={this.onDownloadCSV}
											onDownloadJSON={this.onDownloadJSON}
											onDownloadPDF={this.onDownloadPDF}
											onDownloadCOA={this.onDownloadCOA}
											onDownloadChromes={this.onDownloadChromes}
											onDownloadVialZip={this.onDownloadVialZip}
											onPrintReport={this.onPrintReport}
											onClearSample={this.onClearSample}
											chemidTransferResponse={this.chemidTransferResponse}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<SampleSummary sample={sample} finished={this.sampleProcessor?.finished()} />
									</Grid.Column>
								</Grid.Row>

								{(this.sampleProcessor
									&& this.sampleProcessor.hasResults() === false
									&& !isOutdatedQC
									&& this.sampleProcessor.runCount() > 0) &&
									<Grid.Row>
										<Grid.Column textAlign='center'>
											<Message warning size='large'>
												<Message.Header>
													No Cannabinoids Detected
												</Message.Header>
												No cannabinoids detected. Machine may be out of calibration or product may not have cannabinoids.
											</Message>
										</Grid.Column>
									</Grid.Row>
								}
								
								{this.buildCharts()}
							
							</Grid>
						</ConditionalRender>
						<ConditionalRender condition={isOutdatedQC}>
							<Message warning size='large'>
								<Message.Header>
									Legacy Test
								</Message.Header>
								Updated CannID reporting framework no longer supports legacy format for quality control results. For accessing quality control results before 2021 December 7th UTC, please contact Customer Support
							</Message>
						</ConditionalRender>

					</section>
				</section>
			</div>
		)
	}
}

SampleResults.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		go: PropTypes.func
	}).isRequired,
	user: PropTypes.shape({
		actual_role: PropTypes.shape({ slug: PropTypes.string }),
		organization: PropTypes.shape({ id: PropTypes.number })
	})
}

SampleResults.defaultProps = {
	user: null
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleResults)
