import decodeJWT from '../lib/decodeJWT'
import { CertiServices } from '../services/Certi'
import { userServices } from '../services/User'
export const UPDATE_MACHINE_STATE = 'UPDATE_MACHINE_STATE'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_CHECK = 'AUTH_CHECK'
export const CHEMID_LOGIN = 'CHEMID_LOGIN'
export const CHEMID_LOGOUT = 'CHEMID_LOGOUT'
export const CHEMID_ENTITY = 'CHEMID_ENTITY'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const GET_SELECTED_TESTS = 'GET_SELECTED_TESTS'
export const SET_TIMER = 'SET_TIMER'
export const UPDATE_SAMPLE_QUEUES = 'UPDATE_SAMPLE_QUEUES'
export const TOGGLE_SAMPLE_QUEUES = 'TOGGLE_SAMPLE_QUEUES'
export const TOGGLE_SIDEBAR_MENU = 'TOGGLE_SIDEBAR_MENU'
export const APPEND_NOTIFICATIONS_LOG = 'APPEND_NOTIFICATIONS_LOG'
export const CLEAR_NOTIFICATIONS_LOG = 'CLEAR_NOTIFICATIONS_LOG'
export const START_DRIVER_POLLING = 'START_DRIVER_POLLING'
export const STOP_DRIVER_POLLING = 'STOP_DRIVER_POLLING'
export const SET_CURRENT_CERTUS_BANK = 'SET_CURRENT_CERTUS_BANK'

export const getAccess = () => {
	return (dispatch) => {
		userServices.getAccess().then((response) => {
			const { data } = response
			dispatch({ type: 'SET_AWS_KEYS', accessCreds: data })
		})
	}
}

export const getOrgCertusTokenBank = () => {
	return (dispatch, getState) => {
		//Take apart current user, get org, run token api call for token bank
		CertiServices.getOrgCertusTokenBank(getState().current_user.organization.id)
			.then((response) => {
				let bank_info = response.data.bank_info
				if (getState().current_user.organization.id === 2 && bank_info.quantity === 0) bank_info['quantity'] = 100
				dispatch({type: SET_CURRENT_CERTUS_BANK, certusBankInfo: bank_info})
		})
	}
}

export const getUser = (token) => {
  return (dispatch) => {

    let currentUser = decodeJWT(token)
    dispatch({ type: SET_CURRENT_USER, user: currentUser })
    dispatch({ type: SET_ACCESS_TOKEN, token })
    localStorage.setItem('access_token', token)
    dispatch(getOrgCertusTokenBank())
    //put a dispatch for all creating certus actions
    //put a dispatch for all certus related call
  }
}
export const updateMachineState = (machineState) => (
  {
    type: UPDATE_MACHINE_STATE,
    machineState
  }
)

export const updateSampleQueues = (sampleQueues) => (
  {
    type: UPDATE_SAMPLE_QUEUES,
    sampleQueues
  }
)

export const startTimer = (timer) => {
  return (dispatch, getState) => {
    if(timer.duration === 0) {
      clearInterval(getState().timer.intervalId)
      return
    }
    if(getState().timer.intervalId && !timer.seconds) {
      clearInterval(getState().timer.intervalId)
      dispatch({
        type: SET_TIMER,
        timer: { seconds: 0, message: null, duration: 0, intervalId: null }
      })
    } else if(getState().timer.intervalId && ! timer.seconds) {
      dispatch({
        type: SET_TIMER,
        timer: { message: timer.message }
      })
      return
    }
    timer.intervalId = setInterval(() => {
      const seconds = getState().timer.seconds + 1
      if( timer.duration > 0 && seconds > timer.duration ) {
        clearInterval(timer.intervalId)
        dispatch({
          type: SET_TIMER,
          timer: { seconds: 0, message: null, duration: 0, intervalId: null }
        })
      } else {
        timer.seconds = seconds
        dispatch({
          type: SET_TIMER,
          timer: timer
        })
      }
    }, 1000)
  }
}

export const UPDATE_CHROM = 'UPDATE_CHROM'
export const updateChrom = (newCoords) => ({
  type: UPDATE_CHROM,
  newCoords
})
export const selectedTests = (test) => {
  return (dispatch) => {
    dispatch({ type: GET_SELECTED_TESTS, selectedTests: test })
  }
}

export const setChemidUser = (chemidUser) => {
	return (dispatch) => {
		dispatch({ type: 'SET_CHEMID_USER', chemidUser: chemidUser })
	}
}

export const setChemidEntity = (chemidEntity) => {
	return (dispatch) => {
		dispatch({ type: 'SET_CHEMID_ENTITY', chemidEntity: chemidEntity })
	}
}

export const logoutChemid = () => {
	return (dispatch) => {
		dispatch({ type: 'LOGOUT_CHEMID' })
	}
}
