import axios from '../axios'
import config from '../config'
import axiosNoToken from '../axiosNoToken'
import constants from '../lib/constants'
export const userServices = {
  getAllUsersDownloadUrl,
  getAllUsers,
  createUser,
  forgotPassword,
  linkValidity,
  resetPassword,
  updateUser,
  getUser,
  getRoles,
  deleteUser,
  changePassword,
  userActivity,
  requestResetPassword,
  isUserDeletable,
  userMigrate,
  createRemoteUser,
  signupUser,
  getTokenInfo,
  getTotalUsers,
  getAccess
};
function getAllUsersDownloadUrl() {
  return config.api_root + 'users/?download=true&no_pagination=true';
}
function getAllUsers() {
  return config.api_root + 'users/';
}
function isUserDeletable(id) {
  return axios().get(config.api_root + `users/${id}/metadata`);
}
function forgotPassword(id) {
  return axios().get(config.api_root + 'users/' + id);
}
function getUser(id) {
  return axios().get(config.api_root + 'users/' + id);
}
function createUser(requestData) {
  return axios().post(config.api_root + 'users/', requestData);
}
function updateUser(id, requestData) {
  return axios().patch(config.api_root + 'users/' + id, requestData);
}
function linkValidity(token) {
  return axiosNoToken().get(config.api_root + `users/reset-password/${token}`);
}
function userActivity(id, responseData) {
  return axios().patch(config.api_root + `users/${id}`, responseData);
}
function deleteUser(id) {
  return axios().delete(config.api_root + 'users/' + id);
}
function requestResetPassword(requestData) {
  return axiosNoToken().post(config.api_root + 'users/reset-password', requestData);
}
function resetPassword(requestData, token) {
  return axiosNoToken().patch(config.api_root + 'users/reset-password/' + token, requestData);
}
function changePassword(id, requestData) {
  return axios().patch(config.api_root + `users/${id}/change-password`, requestData);
}
function getRoles() {
  return axios().get(config.api_root + 'roles?no_pagination=true&sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id');
}
function userMigrate(id, responseJson) {
  return axios().patch(config.api_root + `users/${id}/migrate`, responseJson);
}
function createRemoteUser(requestData) {
  return axios().post(config.api_root + 'users/invite', requestData);
}
function signupUser(requestData) {
  return axiosNoToken().post(config.api_root + 'users/', requestData);
}
function getTokenInfo() {
  return axios().get(config.api_root + 'token_info')
}
function getTotalUsers() {
  return axios().get(config.api_root + 'users?no_pagination=true')
}
function getAccess() {
	return axios().get(config.api_root + 'access');
}
