const initialState = {
  currentChrom: [[0, 0]],
  access_token: localStorage.getItem('access_token'),
  selected_tests: { batch_id: '', selectedCheckBoxes: [], type: '' },
  timer: { seconds: 0},
  sampleQueues: [],
  showQueue: false,
  showSidebarMenu: false,
  queuePaused: localStorage.getItem('queuePaused') !== '0', // 0 = deliberately not paused. 1 = deliberately paused. non-existent or else = default to paused.
  notificationsLog: [],
  driverPolling: localStorage.getItem('driverPolling') === '1' ? true : false, // 0 = polling disabled. 1 = polling enabled. non-existent or else = default to not polling.
  chemidUser: JSON.parse(sessionStorage.getItem('CHEMID_USER')),
  chemidEntity: JSON.parse(sessionStorage.getItem('CHEMID_ENTITY')),
  accessCreds: {}
}
export default initialState
