import React, { Component } from 'react'
import { Accordion, Icon, Message, Checkbox, Header, Segment, Dimmer, Loader } from 'semantic-ui-react'
import $ from 'jquery'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsData from 'highcharts/modules/data'
import HighchartsExporting from 'highcharts/modules/exporting'
import iconv from 'iconv-lite'
import { connect } from 'react-redux'
import { pathEncoderS3, showChromes } from '../../lib/pathEncoderS3'
import invokeLambda from '../../lib/InvokeLambda'
import asyncForEach from '../../lib/asyncForEach'

HighchartsData(Highcharts)
HighchartsExporting(Highcharts)

class AnalyticsChromeComponent extends Component {
	constructor(props) {
		super(props)
		const samples = (props.sample)
		const show = showChromes(samples.results)
		this.state = {
			activeIndex: false,
			show,
			chart_data: false,
			chart_error: false,
			lambda: [],
			toggle: false,
			retention: false,
			lambdaUsed: false
		}
		this.mounted = false
	}

	componentDidMount() {
		if (this.props.openOnLoad) {
			this.handleClick()
			this.mounted = true
		}
	}

	componentWillUnmount() {
		this.mounted = false
	}

	renderGraph = () => {
		this.props?.sample?.results?.forEach((item, index) => {
			let chroma
			const chromeUrl = this.props.sample?.results?.[index]?.chromeUrl

			if (chromeUrl) {
				chroma = encodeURI(chromeUrl)
			}
			else if (this.props.sample?.results?.[index]?.chromeUploadError) {
				console.log('error for', index)
				$(`#chroma_csv .a${index}`).text('ERROR!')
				this.setState({ chart_error: true })
			}
			else {
				const chroma_Id = pathEncoderS3(item)
				if (chroma_Id) {
					chroma = `https://${this.props.accessCreds.CHROMATOGRAPHS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${chroma_Id}/signal1.csv`
				}
				else {
					$(`#chroma_csv .a${index}`).text('ERROR!')
					this.setState({ chart_error: true })
				}
			}

			if (chroma) {
				fetch(chroma)
					.then((res) => {
						if (res.status >= 200 && res.status <= 299) {
							return res
						}

						console.log('fetch bad resp status', res)
						throw Error(res.statusText)
					})
					.then((res) => res.arrayBuffer())
					.then((arrayBuffer) => {
						const buff = Buffer.from(arrayBuffer)
						// test if newlines exist to determine encoding utf8 or utf16
						const utf16 = iconv.decode(buff, 'ucs2').toString()
						if (utf16.split('\r\n').length === 1) {
							const utf8 = iconv.decode(buff, 'utf8').toString()
							return utf8
						}

						return utf16
					})
					.then((text) => {
						$(`#chroma_csv .a${index}`).text(`Retention,Intensity\n${text}`)
						this.setState({ chart_data: true })
					})
					.catch((err) => {
						console.log('fetch catch err', err)
						$(`#chroma_csv .a${index}`).text('ERROR!')
						this.setState({ chart_error: true })
					})
			}
		})
	}

	handleClick = () => {
		this.setState({ activeIndex: !this.state.activeIndex })
		this.renderGraph(this.props)
		this.props.sample.results[0].results.map((x) => {
			if (x.peak_retention_time != 'N/A') {
				this.setState({ retention: true })
			}
		})
	}

	// calling lambda and invoking the api call using the s3 address and compound data as the event handler for aws
	async lambdaStateBuilder(arr1, arr2) {
		const newArr1 = [arr1]
		const newArr2 = [arr2]
		const placeholder = []

		await asyncForEach(newArr1, async (url, urlIndex) => {
			// originally, error occured when using a chrome with a space character in the filename/url
			// swapped out the space character for the browser friendly replacement but a different error appeared.
			// this is a handling which may need further addressing if other faulty characters are discovered
			let cleanUrl = url.replaceAll(' ', '%20')
			const lambdaEvent = {
				url: cleanUrl,
				cds_results: newArr2[urlIndex]
			}
			const lamb = await invokeLambda({
				accessKeyId: this.props.accessCreds.LAMBDA_AK,
				secretAccessKey: this.props.accessCreds.LAMBDA_SK,
				location: this.props.accessCreds.LAMBDA_REGION,
				funcName: this.props.accessCreds.LAMBDA_FUNC,
				event: JSON.stringify(lambdaEvent)
			}).then((res, err) => {
				if (res.StatusCode == 200) {
					const response = JSON.parse(res.Payload).errorMessage
					if (!response) this.setState({ lambdaUsed: true })
					else console.error(JSON.parse(res.Payload))
					placeholder.push(res.Payload)
				}
				else {
					return console.error(err)
				}
			})
			return lamb
		})

		if (this.mounted && this.state.lambda.length == 0) {
			return this.setState({ lambda: placeholder })
		}
	}

	render() {
		const activeIndex = this.state.activeIndex
		const chart_Data = this.state.chart_data
		const results = this.props.sample.results[0]
		this.lambdaStateBuilder(results.chromeUrl, results.results)// load lambda data
		let view = ''
		let chart = ''

		if (chart_Data || this.state.chart_error) {
			// array of 41 undefined positions which will be mapped via tickPositions
			const ticks = Array.from(Array(parseInt(1200 / 30 + 1)))
			const acquiredPreparedSample = this.props.sample.prepared_samples.filter((prep) => prep.acquire === true)
			const $report = $('#report')
			const plotlines = []
			const cdata = this.props.sample.results[0].results
			cdata.forEach((x) => x.peak_retention_time)
			const rtime = []
			cdata.map((x) => {
				const rt = x.peak_retention_time || x.rt_min || null
				if (rt && parseFloat(rt)) {
					rtime.push(JSON.parse((parseFloat(rt * 60).toFixed(2))))
				}
				else {
					console.error('error: No comparison retention time is available.')
				}
			})
			const analytes = cdata.map((value) => value.compound)
			// vars & data  from lambda that we are going to utilize
			let startPeak
			let stopPeak
			let ar
			let lambda
			let four
			let eventData = []
			const start = []
			const stop = []
			const area = []
			const fourRT = []
			if (this.state.toggle) {
				lambda = JSON.parse(this.state.lambda)[0]
				startPeak = (JSON.parse(lambda?.results.Start_Peak_x))
				ar = (JSON.parse(lambda?.results.Peak_Area))
				four = (JSON.parse(lambda?.results.Retention_Time))
				stopPeak = (JSON.parse(lambda?.results.Stop_Peak_x))
				if (rtime.length > 0) {
					rtime.forEach((value) => {
						value
						const closest = startPeak.reduce((a, b) => (Math.abs(b - value) < Math.abs(a - value) ? b : a))
						const place = startPeak.indexOf(closest)
						start.push(closest)
						stop.push(stopPeak[place])
						area.push(ar[place])
						fourRT.push(four[place])
					})
					eventData = analytes.map((value, index) => ({ rt: fourRT[index], start: start[index], stop: stop[index], name: value, area: area[index] }))
				}
			}

			chart = this.props?.sample?.results?.map((item, index) => {
				if (acquiredPreparedSample[index] && document.querySelector(`#chroma_csv .a${index}`).innerHTML) {
					let titleText = this.props.sample.intake_form.strain_name
					if (acquiredPreparedSample[index].label) {
						titleText = `${acquiredPreparedSample[index].label}`
					}
					else if (!this.props.sample.import) {
						titleText = `${`${_.capitalize(acquiredPreparedSample[index].vial_type.split('_')[0])} Vial`}`
					}

					for (let i = 0; i < rtime.length; i++) {
						const pline = {
							color: 'grey',
							width: 1,
							value: rtime[i],
							label: {
								text: `System Data: ${analytes[i]}, ${(rtime[i] / 60).toFixed(3)} min`,
								align: 'top'
							}

						}
					 plotlines.push(pline)

						const fourLines = {
							color: 'yellow',
							width: 1,
							value: fourRT[i],
							label: {
								text: ``,
								align: 'top'
							},
							events: {
								click() {
									$report.html('click')
								},
								mouseover() {
									$report.html('mouseover')
								},
								mouseout() {
									$report.html('mouseout')
								}
							}
						}
						this.state.toggle ? plotlines.push(fourLines) : ''
					}

					const options = {
						credits: {
							enabled: false
						},
						chart: {
							backgroundColor: 'transparent',
							zoomType: 'xy',
							type: 'line'
						},
						title: {
							text: titleText
						},
						subtitle: {
							text: 'Raw chroma data'
						},
						yAxis: [{
							labels: {
								format: '{value}'
							},
							title: {
								text: 'Intensity'
							}
						}
						],
						xAxis: [{
							crosshair: { data: plotlines, dashStyle: 'shortdot', width: 2 },
							plotLines: [...plotlines],

							tickPositions: ticks.map((e, i) => (item.chromeRtUnits === 'Minutes' ? 0.5 * i : 30 * i)),
							labels: {
								formatter() {
									return item.chromeRtUnits === 'Minutes' ? this.value : this.value / 60
								}
							},
							title: {
								text: 'Time, Minutes'
							}
						}
						],
						plotOptions: {
							series: {
								stacking: 'overlap',
								marker: {
									enabled: false
								}
							}
						},
						data: {
							csv: document.querySelector(`#chroma_csv .a${index}`).innerHTML
						},
						tooltip: {
							formatter() {
								const rt = item.chromeRtUnits === 'Minutes' ? this.x : this.x / 60
								const nm = this.point.series.name
								const int = this.point.y
								let lambdaData = ''

								eventData.forEach((x) => {
									if (this.x > x.start && this.x < x.stop) {
										const r = 'red'
										const g = 'green'
										const p = 'gold'
										lambdaData = `
										<span>Analyte: <b>${x.name}<hr></b></span><br/>
										<br/><b>F4 Data:</b>
										<br/><span><span style="color:${g}">●</span>Peak Start: <b>${(x.start / 60).toFixed(3)}</b> min</span><br/>
										<span><span style="color:${r}">●</span>Peak Stop: <b>${(x.stop / 60).toFixed(3)}</b> min</span><br/>
										<span><span style="color:${p}">●</span>Found Peak: <b>${(x.rt / 60).toFixed(3)}</b> min</span><br/>
										<span>Area: <b>${x.area.toFixed(3)}</b> units</span><br/>
										`
									}
								})
								const str = `
											<div>
												${lambdaData}<br/>
												<span>Time: <b>${rt.toFixed(3)}</b> min</span><br/>
												<span>${nm}: <b>${int.toFixed(3)}</b><br/></span>
												<br/>
											</div>	
											`
								return str
							}
						},
						exporting: {
							enabled: false
						}
					}
					if (document.querySelector(`#chroma_csv .a${index}`).innerHTML === 'ERROR!') {
						const content = item.chromeUploadError ? `Error retrieving Chromatogram: "${item.chromeUploadError}"` : 'Error retrieving Chromatogram'
						return (
							<div key={index}>
								<p className='highcharts-title' style={{ color: '#333333', fontSize: '18px', fill: '#333333' }}>{titleText}</p>
								<Message error style={{ maxWidth: '80%', margin: '0 auto' }}>{content}</Message>
							</div>
						)
					}
					return (
						<HighchartsReact
							key={index}
							highcharts={Highcharts}
							options={options}
						/>
					)
				}
			})
		}

		if (this.state.show) {
			view = (
				<Accordion className='accordian'>
					<pre id='chroma_csv' style={{ display: 'none' }}>
						<pre className='a0' />
						<pre className='a1' />
						<pre className='a2' />
						<pre className='a3' />
						<pre className='a4' />
						<pre className='a5' />
					</pre>
					<Accordion.Title
						className={activeIndex ? 'accordian-title' : 'accordian-inactive'}
						active={activeIndex}
						index={0}
						onClick={this.handleClick}
					>
						<Icon name='dropdown' />
						Chromatogram
					</Accordion.Title>

					<Accordion.Content active={activeIndex}>

						{!this.state.toggle && this.state.retention && (
							<>
								{this.state.lambda.length == 0
									? (
										<Segment basic style={{ height: 65 }}>
											<Dimmer active inverted>
												<Loader inverted inline>Loading F4 Data</Loader>
											</Dimmer>
										</Segment>
									)
									: this.state.lambdaUsed
										? (
											<>
												<Header style={{marginBottom: 8}}>{this.state.toggle ? 'F4 Data' : 'System Data' }</Header>
												<Checkbox
													toggle
													checked={this.state.toggle}
													onClick={(e) => this.setState({ toggle: !this.state.toggle })}
												/>
											</>
										)
										: <Message info>Data cannot be parsed for  F4 analysis.</Message>
								}
							</>
						)}
						{this.state.toggle && this.state.retention && (
							<>
								<Header>{this.state.toggle ? 'F4 Data' : 'System Data' }</Header>
								{this.state.lambda.length == 0 ? 'F4 Data is Loading...' : (
									<Checkbox
										toggle
										checked={this.state.toggle}
										onClick={(e) => this.setState({ toggle: !this.state.toggle })}
									/>
								) }

							</>
						) }
						{!this.state.retention && (
							<Message style={{marginLeft: '3em', marginRight: '3em'}}><span style={{ color: 'red' }}>F4 data processing failed.</span><br />Please upload valid results with associated retention times for F4 analysis.</Message>
						)}

						{chart}
					</Accordion.Content>
				</Accordion>
			)
		}
		return view
	}
}

const mapStateToProps = (state) => {
	return {
		accessCreds: state.accessCreds
	}
}

export default connect(mapStateToProps)(AnalyticsChromeComponent)
