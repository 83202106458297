import React, { Component } from 'react'
import {
	Button, Checkbox, Modal, Header, Table, Grid, Segment, Label, List, Message
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { uploadFile } from 'react-s3'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'
import randomHexColor from 'random-hex-color'

import cannidAPI from '../../cannidAPI/client'
import trimWhiteSpace from '../../lib/trim'

class ImportCsvMapperType1 extends Component {
	constructor(props) {
		super(props)
		this.s3Config = {
			bucketName: props.accessCreds.CHROMATOGRAPHS_BUCKET,
			region: process.env.REACT_APP_AWS_REGION,
			accessKeyId: props.accessCreds.CHROMATOGRAPHS_AK,
			secretAccessKey: props.accessCreds.CHROMATOGRAPHS_SK
		}
		this.clearForm = {
			default: false,
			confirmOpen: false,
			confirmed: false,
			// notice - logic demands that the keys in the state 'rows', 'metadata', 'analytes' state objects all have unique keys
			metadata: {
				'Data file': undefined,
				'Sample name': undefined,
				Description: undefined,
				'Lims ID': undefined,
				'Acq. operator': undefined,
				Instrument: undefined,
				'Injection date': undefined,
				'Acq. method': undefined,
				'Analysis method': undefined,
				'Last changed': undefined,
				'Calib. data modified': undefined,
				'Sample amount': undefined,
				Multiplier: undefined,
				Dilution: undefined,
				Location: undefined,
				'Injection volume': undefined,
				'Sample type': undefined,
				'Signal Name': undefined,
				'LOQ': undefined
			},
			analytes: {
				Name: undefined,
				'Concentration %': undefined,
				'Retention Time': undefined,
				'Peak Start': undefined,
				'Peak End': undefined,
				Resolution: undefined,
				Area: undefined,
				'Amount ug/mL': undefined,
				'Amount mg/g': undefined
			},
			
			rows: {
				'Analyte Header Row': undefined,
				'First Metadata Row': undefined
			},
			clickedMapper: undefined,
			clickedMapperType: undefined,
			mappedColors: {},
			sourceFilename: '',
			sourceFile: '',
			mappingErrors: {}
		}
		this.state = { ...this.clearForm }
		this.requiredAnalyteKeys = ['Name', 'Concentration %']
	}

	getErrors = () => {
		const errors = {}
		if (!this.props.templateType) {
			errors.templateType = 'Must select a Template Type.'
		}
		const re = /^[\w\d\s-_]+$/
		if (!re.test(this.props.templateName)) {
			errors.templateName = 'Template Name can only contain letters, numbers, spaces, dashes, and underscores.'
		}
		if (!trimWhiteSpace(this.props.templateName)
			|| trimWhiteSpace(this.props.templateName.replace(/\s+/g, '')) === ''
			|| !trimWhiteSpace(this.props.templateName).length
			|| trimWhiteSpace(this.props.templateName).length > 50) {
			errors.templateName = 'Must include Template Name. 50 characters max length.'
		}

		let missingConc = []
		this.requiredAnalyteKeys.forEach((analyte) => {
			console.log(analyte)
			if (!this.state.analytes[analyte]) missingConc.push(analyte)
		})
		if (missingConc.length > 0) {
			errors['requiredConc'] = `Analyte "Name" and "Concentration %" required. Missing field map for analyte header cells: ${missingConc.join(', ')}`
		}

		let missingRows = []
		Object.keys(this.state.rows).forEach((row) => {
			if (!this.state.rows[row]) missingRows.push(row)
		})
		if (missingRows.length > 0) {
			errors['requiredRows'] = `All header rows are required mapping. Missing map for rows: ${missingRows.join(', ')}`
		}
		
		return errors
	}

	getConfirmation = () => {
		const errors = this.getErrors()
		if (!Object.keys(errors).length) {
			this.setState({ confirmOpen: true, confirmed: false, mappingErrors: errors })
		}
		else {
			this.setState({ mappingErrors: errors })
			this.props.updateFormErrors(errors)
			window.scrollTo(0, 0) // scroll to top where form error is displayed
		}
	}

	createTemplate = () => {
		this.setState({ confirmOpen: false, confirmed: true }, () => this.uploadSource())
	}

	uploadSource = () => {
		document.body.classList.add('loading-indicator')
		const resultConfig = { ...this.s3Config, dirName: `import_templates/${this.props.user.organization.id}/${uuid()}` }

		uploadFile(this.props.fileRef, resultConfig)
			.then((data) => {
				this.setState((prevState) => ({
					sourceFilename: this.props.fileRef.name,
					sourceFile: data.location
				}), () => this.templateFromState())
			})
			.catch((err) => {
				console.error('specific source upload error', err)
				this.functionalError(`Error uploading source CSV, please try again. ${err}`)
				document.body.classList.remove('loading-indicator')
				this.setState({ confirmOpen: false, confirmed: false })
			})
	}

	templateFromState = () => {
		const params = {
			type: this.props.templateType,
			name: this.props.templateName,
			metadata: this.state.metadata,
			analytes: this.state.analytes,
			rows: this.state.rows,
			parsed: this.props.parsedCsv,
			sourceFilename: this.state.sourceFilename,
			sourceFile: this.state.sourceFile,
			default: this.state.default
		}

		cannidAPI.post('/import_templates', params).then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			document.body.classList.remove('loading-indicator')
			this.props.history.push('/samples/import')
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. Creation Failed. Please try again.`
			this.functionalError(message)
			window.scrollTo(0, 0) // scroll to top where api error is displayed
			document.body.classList.remove('loading-indicator')
		})
	}

	clickedMapper = (key, type) => {
		// if currently set, then clear.
		if (this.state[type][key]) {
			// only 2 types (metadata, analytes) so we hard code join them in case a cell is shared by both 'types'
			const sharedCell = [
				...Object.values(this.state.metadata),
				...Object.values(this.state.analytes),
				...Object.values(this.state.rows)
			].filter((v) => v === this.state[type][key]).length > 1
			this.setState((prevState) => ({
				...prevState,
				clickedMapper: undefined,
				clickedMapperType: undefined,
				[type]: {
					...prevState[type],
					[key]: undefined
				},
				mappedColors: {
					...prevState.mappedColors,
					[prevState[type][key]]: sharedCell ? prevState.mappedColors[prevState[type][key]] : undefined
				}
			}))
		}
		// otherwise, enable map setting tool
		else {
			this.setState((prevState) => ({
				...prevState,
				clickedMapper: (prevState.clickedMapper === key) ? undefined : key,
				clickedMapperType: prevState.clickedMapperType === type ? undefined : type,
				[type]: {
					...prevState[type],
					[key]: undefined
				},
				mappedColors: {
					...prevState.mappedColors,
					[prevState[type][key]]: undefined
				}
			}))
		}
	}

	clickedCell = (rowIndex, cellKey) => {
		if (this.state.clickedMapper
			&& this.state.clickedMapperType
			&& !Object.keys(this.state.rows).includes(this.state.clickedMapper)) {
			this.setState((prevState) => ({
				...prevState,
				clickedMapper: undefined,
				clickedMapperType: undefined,
				[prevState.clickedMapperType]: {
					...prevState[prevState.clickedMapperType],
					[prevState.clickedMapper]: `${rowIndex},${cellKey}`
				},
				mappedColors: {
					...prevState.mappedColors,
					[`${rowIndex},${cellKey}`]: randomHexColor()
				}
			}))
		}
	}

	clickedRow = (rowIndex) => {
		if (this.state.clickedMapper
			&& this.state.clickedMapperType
			&& Object.keys(this.state.rows).includes(this.state.clickedMapper)) {
			this.setState((prevState) => ({
				...prevState,
				clickedMapper: undefined,
				clickedMapperType: undefined,
				[prevState.clickedMapperType]: {
					...prevState[prevState.clickedMapperType],
					[prevState.clickedMapper]: `${rowIndex}`
				},
				mappedColors: {
					...prevState.mappedColors,
					[`${rowIndex}`]: randomHexColor()
				}
			}))
		}
	}

	csvDisplay = () => {
		let display

		const metadataSegment = (
			<Segment className='mappingControl' key='metadataControl'>
				<List divided selection>
					<Header as='h5'>
						<strong>Metadata</strong>
						{' '}
						- Select Value Cells
					</Header>
					{Object.keys(this.state.metadata).map((meta) => {
						const mappedColor = this.state.mappedColors[this.state.metadata[meta]]
							? {
								border: `2px solid ${this.state.mappedColors[this.state.metadata[meta]]}`,
								fontWeight: 'bold',
								color: 'black'
							}
							: { border: 'initial' }
						return (
							<List.Item
								key={meta}
								onClick={() => { this.clickedMapper(meta, 'metadata') }}
								className={this.state.clickedMapper === meta ? 'activeControl' : 'idleControl'}
							>
								<Label color='blue' horizontal className='controlLabel'>{meta}</Label>
								<span
									className='controlValue'
									style={mappedColor}
								>
									{
										this.state.metadata[meta] ? this.state.metadata[meta].replace(',', ' x ') : 'Not Set'
									}
								</span>
							</List.Item>
						)
					})}
				</List>
			</Segment>
		)

		const analyteSegment = (
			<Segment className='mappingControl' key='analyteControl'>
				<List divided selection>
					<Header as='h5'>
						<strong>Analytes</strong>
						{' '}
						- Select Header Cells
					</Header>
					{Object.keys(this.state.analytes).map((lyte) => {
						const mappedColor = this.state.mappedColors[this.state.analytes[lyte]]
							? {
								border: `2px solid ${this.state.mappedColors[this.state.analytes[lyte]]}`,
								fontWeight: 'bold',
								color: 'black'
							}
							: { border: 'initial' }
						return (
							<List.Item
								key={lyte}
								onClick={() => { this.clickedMapper(lyte, 'analytes') }}
								className={this.state.clickedMapper === lyte ? 'activeControl' : 'idleControl'}
							>
								<Label color='blue' horizontal className='controlLabel'>
									{lyte}
									{this.requiredAnalyteKeys.includes(lyte) && <span style={{ color: '#db2828' }}>&nbsp;*</span>}
								</Label>
								<span
									className='controlValue'
									style={mappedColor}
								>
									{
										this.state.analytes[lyte] ? this.state.analytes[lyte].replace(',', ' x ') : 'Not Set'
									}
								</span>
							</List.Item>
						)
					})}
				</List>
			</Segment>
		)

		const rowsSegment = (
			<Segment className='mappingControl' key='rowsControl'>
				<List divided selection>
					<Header as='h5'>
						<strong>Headers</strong>
						{' '}
						- Select Rows
					</Header>
					{Object.keys(this.state.rows).map((row) => {
						const mappedColor = this.state.mappedColors[this.state.rows[row]]
							? {
								border: `2px solid ${this.state.mappedColors[this.state.rows[row]]}`,
								fontWeight: 'bold',
								color: 'black'
							}
							: { border: 'initial' }
						return (
							<List.Item
								key={row}
								onClick={() => { this.clickedMapper(row, 'rows') }}
								className={this.state.clickedMapper === row ? 'activeControl' : 'idleControl'}
							>
								<Label color='blue' horizontal className='controlLabel'>
									{row}
									<span style={{ color: '#db2828' }}>&nbsp;*</span>
								</Label>
								<span
									className='controlValue'
									style={mappedColor}
								>
									{
										this.state.rows[row] ? this.state.rows[row].replace(',', ' x ') : 'Not Set'
									}
								</span>
							</List.Item>
						)
					})}
				</List>
			</Segment>
		)

		const mappingSegments = [rowsSegment, analyteSegment, metadataSegment]

		display = (
			<Grid columns={2}>
				<Grid.Column width={4} className='mappingControlColumn'>
					<Header as='h3'>Map</Header>
					{mappingSegments.map((segment) => segment)}
				</Grid.Column>
				<Grid.Column width={12} className='mappingCsvColumn'>
					<Header as='h3'>CSV</Header>
					<div>
						<Table celled compact size='small' className='csvTable'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell />
									{Object.keys(this.props.parsedCsv[0]).map((field) => { // eslint-disable-line
										return (<Table.HeaderCell key={field}>{field}</Table.HeaderCell>)
									})}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{this.props.parsedCsv.map((row, rowIndex) => (
									<Table.Row
										key={`row${rowIndex}`} // eslint-disable-line
										className={(
											this.state.clickedMapper
												&& Object.keys(this.state.rows).includes(this.state.clickedMapper)
										) ? 'mappingRow mapperActive' : 'mappingRow'}
										onClick={() => this.clickedRow(rowIndex)}
										style={
											this.state.mappedColors[`${rowIndex}`]
												? { border: `2px solid ${this.state.mappedColors[rowIndex]}` }
												: { border: 'initial' }
										}
									>
										<Table.Cell className='rowNumber'>{rowIndex}</Table.Cell>
										{Object.keys(row).map((cellKey) => {
											const mappedColor = this.state.mappedColors[`${rowIndex},${cellKey}`]
												? { background: this.state.mappedColors[`${rowIndex},${cellKey}`] }
												: { background: 'initial' }
											return (
												<Table.Cell
													key={cellKey}
													className={(
														this.state.clickedMapper
															&& !Object.keys(this.state.rows).includes(this.state.clickedMapper)
													) ? 'mappingCell mapperActive' : 'mappingCell'}
													onClick={() => this.clickedCell(rowIndex, cellKey)}
													style={mappedColor}
												>
													{row[cellKey]}
												</Table.Cell>
											)
										})}
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</div>
				</Grid.Column>
			</Grid>
		)
		return display
	}

	render() {
		return (
			<>
				{this.state.mappingErrors.requiredConc && <Message error>{this.state.mappingErrors.requiredConc}</Message>}
				{this.state.mappingErrors.requiredRows && <Message error>{this.state.mappingErrors.requiredRows}</Message>}

				<div className='displayContainer'>{this.csvDisplay()}</div>

				<div className='mapperButtons'>
					<Header as='h5' textAlign='right'>
						<span style={{ color: '#db2828' }}>*</span>
						{' '}
						Required
					</Header>
					<div>
						<Checkbox
							toggle
							checked={this.state.default}
							onClick={() => this.setState((prevState) => ({ default: !prevState.default, errors: {} }))}
							label='Set as Default'
							style={{ marginBottom: '1em' }}
						/>
					</div>
					<Button
						className='blue'
						type='submit'
						onClick={this.getConfirmation}
					>
						Save Mapped Template
					</Button>
					<Button
						className='red'
						onClick={() => {
							this.setState({ ...this.clearForm }, this.props.reset())
						}}
					>
						Start Over
					</Button>
				</div>

				<Modal
					className='mappingConfirmation'
					size='large'
					open={this.state.confirmOpen}
					onClose={() => this.setState({ confirmOpen: false, confirmed: false })}
					closeIcon
				>
					<Modal.Header>Confirm CSV Template Map</Modal.Header>
					<Modal.Content scrolling>
						<Checkbox
							label='I have reviewed the mapped values and confirm it is accurate to the best of my knowledge.'
							checked={this.state.confirmed}
							onChange={() => this.setState((prevState) => ({ confirmed: !prevState.confirmed }))}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button
							className='cancelButton'
							onClick={() => this.setState({ confirmOpen: false, confirmed: false })}
						>
							No
						</Button>
						<Button
							className='confirmButton'
							icon='checkmark'
							labelPosition='right'
							content='Yes'
							disabled={!this.state.confirmed}
							onClick={this.createTemplate}
						/>
					</Modal.Actions>
				</Modal>
			</>
		)
	}
}

ImportCsvMapperType1.propTypes = {
	history: PropTypes.objectOf(PropTypes.shape({
		push: PropTypes.func.isRequired
	})).isRequired,
	user: PropTypes.objectOf(PropTypes.shape({
		organization: PropTypes.objectOf(PropTypes.shape({
			id: PropTypes.number
		}))
	})).isRequired
}.isRequired

const mapStateToProps = (state) => ({
	user: state.current_user,
	accessCreds: state.accessCreds
})

export default connect(
	mapStateToProps
)(withRouter(ImportCsvMapperType1))
