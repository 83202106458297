import React, { Component } from 'react'
import {
	Breadcrumb, Grid, Form, Button, Modal, Loader, Icon, Label, Table, Tab, Popup, Dropdown, Header, Checkbox
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { uploadFile } from 'react-s3'
import { isMobile } from 'react-device-detect'
import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import cannidAPI from '../../cannidAPI/client'
import FileDropzone from '../FileDropzone'
import asyncForEach from '../../lib/asyncForEach'
import { getOrgCertusTokenBank } from '../../actions'

class CertificateOfOriginCertusCreate extends Component {
	constructor(props) {
		super(props)
		this.s3Config = {
			bucketName: props.accessCreds.CERTUS_BUCKET,
			accessKeyId: props.accessCreds.CERTUS_AK,
			secretAccessKey: props.accessCreds.CERTUS_SK,
			region: process.env.REACT_APP_AWS_REGION
		}

		this.attachmentOptions = [
			{
				text: 'Approver ID (.pdf|.jpg|.jpeg|.png)',
				value: 'Approver ID',
				ext: 'pdf|jpg|jpeg|png'
			},
			{
				text: 'Approver Signature (.pdf|.jpg|.jpeg|.png)',
				value: 'Approver Signature',
				ext: 'pdf|jpg|jpeg|png'
			},
			{
				text: 'Certificate Regulatory Approval (.pdf)',
				value: 'Certificate Regulatory Approval',
				ext: 'pdf'
			},
			{
				text: 'Certificate Signature (.pdf|.jpg|.jpeg|.png)',
				value: 'Certificate Signature',
				ext: 'pdf|jpg|jpeg|png'
			},
			{
				text: 'Originator Signature (.pdf|.jpg|.jpeg|.png)',
				value: 'Originator Signature',
				ext: 'pdf|jpg|jpeg|png'
			},
			{
				text: 'Verification Of Origin (.xls|.xlsx|.csv|.pdf|.jpg|.jpeg|.png)',
				value: 'Verification Of Origin',
				ext: 'xls|xlsx|csv|pdf|jpg|jpeg|png'
			},
			{
				text: 'Supporting Image (.jpg|.jpeg|.png)',
				value: 'Supporting Image',
				ext: 'jpg|jpeg|png'
			},
			{
				text: 'Other',
				value: 'Other',
				ext: '*'
			}
		]

		this.state = {
			modalSuccess: false,
			confirmOpen: false,
			loading: false,
			issuerIsOriginator: false,
			attachments: [],
			form_fields: {
				originator_name: '',
				originator_affiliation: '',
				originator_address: '',
				originator_license_number: '',
				certificate_name: '',
				declaration_of_origin: '',
				date_assigned: new Date().toISOString().split('T')[0],
				approver_name: ''
			},
			org: {},
			newId: undefined,
			errors: {},
			apiErrors: {},
			pageTitle: 'Create VERISEAL Certificate of Origin',
			tracer: undefined
		}
		this.mounted = false
	}

	componentDidMount() {
		this.getTracer()
	}

	componentWillUnmount() {
		this.mounted = false
	}

	getTracer = () => {
		this.mounted = true
		cannidAPI.get(`/certi/certi_tracer`).then((response) => {
			if (this.mounted) {
				this.setState(prevState => {
					const form_fields = prevState.form_fields
					form_fields.approver_name = this.props.user.name
					return ({
						form_fields,
						tracer: response.data.tracer,
						org: response.data.org
					})
				})
			}
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. Failed to retrieve new TRACER.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	issuerIsOriginator = (c) => {
		this.setState(prevState => {
			if (c.checked) {
				return ({
					issuerIsOriginator: true,
					form_fields: {
						...prevState.form_fields,
						originator_name: this.props.user.name,
						originator_affiliation: this.state.org.name,
						originator_address: this.state.org.address,
						originator_license_number: this.state.org.license
					}
				})
			}
			else {
				return ({
					issuerIsOriginator: false,
					form_fields: {
						...prevState.form_fields,
						originator_name: '',
						originator_affiliation: '',
						originator_address: '',
						originator_license_number: ''
					}
				})
			}
		})
	}

	updateForm = (e) => {
		this.setState((prevState) => {
			const form_fields = prevState.form_fields
			form_fields[e.target.name] = e.target.value
			return ({ form_fields })
		})
	}

	addAttachment(attachment) {
		attachment['attachment_type'] = 'Other'
		Object.defineProperty(attachment, 'name', {
			writable: true,
			value: attachment['name'].replace(/ /g, '_')
		})
		this.setState(prevState => ({ attachments: [...prevState.attachments, attachment] }))
	}

	removeAttachment(attachmentIndex) {
		this.setState((prevState) => {
			const updated = prevState.attachments.filter((a, i) => i !== attachmentIndex)
			return ({ attachments: updated })
		})
	}

	updateAttachment = (index, data) => {
		this.setState({ errors: {}, apiErrors: {} })
		const type = data.value
		const chosenType = this.attachmentOptions.filter((o) => o.value === type)[0]
		const filename = this.state.attachments[index].name

		if (chosenType.value === 'Other' || filename.match(new RegExp(`.*\.(${chosenType.ext}|${chosenType.ext.toUpperCase()})$`))) {
			this.setState((prevState) => {
				const updated = prevState.attachments.map((a, i) => {
					if (i === index) a['attachment_type'] = type
					return a
				})
				return ({ attachments: updated })
			})
		}
		else {
			const message = `Attachment type ${type} must be of file extension .${chosenType.ext}`
			this.setState({
				errors: {
					attachments: { [index.toString()]: message }
				},
				apiErrors: message
			})
			window.scrollTo(0, 0)
		}
	}

	getErrors() {
		const errors = {}
		Object.keys(this.state.form_fields).forEach((field) => {
			if (!this.state.form_fields[field]) errors[field] = 'This field is required.'
		})
		return errors
	}

	getConfirmation = () => {
		this.setState({ errors: {}, apiErrors: {} })
		if (!Object.keys(this.getErrors()).length) {
		 	this.setState({ confirmOpen: true })
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	handleAttachments = async () => {
		document.body.classList.add('loading-indicator')
		const attachmentFormFields = []
		// iterate all the attachment files and upload to s3
		const attachmentUploads = await asyncForEach(this.state.attachments, async (atty, attyIndex) => {
				const resultConfig = { ...this.s3Config, dirName: `${this.state.tracer}/attachments/${attyIndex}/${uuid()}` }

				return await uploadFile(atty, resultConfig).then((data) => {
					attachmentFormFields.splice(attyIndex, 0, {
						certusKey: `(${attyIndex + 1}) ${atty.attachment_type}`,
						attachmentType: atty.attachment_type,
						name: atty.name,
						fileType: atty.type,
						size: atty.size,
						url: data.location
					})
				}).catch((err) => {
					console.error('specific attachment upload error', err)
					const message = `${err}. Failed uploading attachment ${attyIndex + 1}: "${atty.name}". Please try again.`
					throw new Error(message)
				})
		}).catch((err) => {
			console.error('general attachment upload error!', err)
			document.body.classList.remove('loading-indicator')
			this.setState({
				modalSuccess: false,
				confirmOpen: false,
				loading: false,
				apiErrors: err
			})
			window.scrollTo(0, 0)
			return err
		})

		await Promise.all([attachmentUploads]).then(() => {
			const data = {
				form_fields: {...this.state.form_fields, attachments: attachmentFormFields},
				tracer: this.state.tracer
			}

			cannidAPI.post('/certificate_of_origin/', data).then((res) => {
				if (res.status >= 200 && res.status < 300) {
					document.body.classList.remove('loading-indicator')
					this.setState({ confirmOpen: true, loading: false, modalSuccess: true, apiErrors: {}, newId: res.data.id })
					this.props.getOrgCertusTokenBank()
				}
			}).catch((err) => {
				console.error('general attachment upload error!', err)
				document.body.classList.remove('loading-indicator')
				this.setState({
					modalSuccess: false,
					confirmOpen: false,
					loading: false,
					apiErrors: `${err}. Failed to create VERISEAL Certificate of Origin. Please try again.`
				})
				window.scrollTo(0, 0)
				this.props.getOrgCertusTokenBank()
				return err
			})
		})
	}

	render() {

		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/certus'>
					<Breadcrumb.Section>
						<div style={{ display: 'flex' }}>
							CERTUS
							<Icon name='registered outline' size='tiny' />
						</div>
					</Breadcrumb.Section>
				</Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>{this.state.pageTitle}</Breadcrumb.Section>
			</Breadcrumb>
		)

		const panes = [
			{ menuItem: isMobile ? { icon: 'attach', key: 'attachments' } : `Attachments (${this.state.attachments.length})`,
				render: () => <Tab.Pane style={{ overflow: 'initial' }}>{attachmentsDisplay}</Tab.Pane> }
		]

		const attachmentsDisplay = (
			<div style={this.state.attachments.length > 0 ? { minHeight: 350 } : { minHeight: '5vh' }}>
				{this.state.attachments.length > 0 && (
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell />
								<Table.HeaderCell>Attachment Type</Table.HeaderCell>
								<Table.HeaderCell>Filename</Table.HeaderCell>
								<Table.HeaderCell>Format</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{this.state.attachments.map((attachment, attachmentIndex) => (
								<Table.Row key={`${attachment.name}${attachmentIndex}`}>
									<Table.Cell>
										<Popup
											content='Remove' size='tiny' trigger={(
												<Button
													className='red dynamicTableActionButton'
													type='submit'
													icon='x'
													size='tiny'
													onClick={(e) => this.removeAttachment(attachmentIndex)}
												/>
											)}
										/>
									</Table.Cell>
									<Table.Cell>
										<Dropdown
											style={{ width: '100%' }}
											selection
											value={attachment.attachment_type}
											options={this.attachmentOptions}
											onChange={(e, data) => this.updateAttachment(attachmentIndex, data)}
											error={(this.state.errors.attachments
							 					&& Object.keys(this.state.errors.attachments).includes(attachmentIndex.toString())
							 				)}
										/>
									</Table.Cell>
									<Table.Cell>{attachment.name}</Table.Cell>
									<Table.Cell>{attachment.type}</Table.Cell>
								</Table.Row>
							 ))}
						</Table.Body>
					</Table>
				)}
				{this.state.attachments.length < 4
					? <FileDropzone handleFile={(file) => this.addAttachment(file)} />
					: <p style={{color: 'red'}}>Maximum 4 attachments permitted.</p>}
			</div>
		)

		const modal = this.state.modalSuccess ? (
			<Modal
				className='certusConfirmation'
				size='large'
				open={this.state.confirmOpen}
				onClose={() => this.setState(
					{ confirmOpen: false, loading: false },
					this.props.history.push(`/certus/certificate/${this.state.newId}`)
				)}
				closeIcon
			>
				<Modal.Header>CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> Success!</Modal.Header>
				<Modal.Content scrolling>
					<div>
						<p>
							VERISEAL Certificate of Origin successfully written to the 
							CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> KSI Blockchain.
							<br/><strong>Notice: It may take up to a minute for the QR code scan functionality to propogate.</strong>
						</p>
					</div>
				</Modal.Content>

				<Modal.Actions>
					<Button
						className='confirmButton'
						icon='checkmark'
						labelPosition='right'
						content='OK'
						onClick={() => this.setState(
							{ confirmOpen: false, loading: false },
							this.props.history.push(`/certus/certificate/${this.state.newId}`)
						)}
					/>
				</Modal.Actions>

			</Modal>
		) : (
			<Modal
				className='certusConfirmation'
				size='large'
				open={this.state.confirmOpen}
				onClose={() => this.setState({ confirmOpen: false, loading: false })}
				closeIcon
			>
				<Modal.Header>Create VERISEAL Certificate of Origin</Modal.Header>
				<Modal.Content scrolling>
					<div>
						<p>
							Are you sure you want to write this VERISEAL Certificate of Origin to the immutable 
							CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> KSI Blockchain?
						</p>
					</div>
				</Modal.Content>
				{this.state.confirmOpen == true && (
					<Modal.Actions>
						<Button className='cancelButton' onClick={() => this.setState({ confirmOpen: false, loading: false })}>No</Button>
						<Button
							className='confirmButton'
							icon='checkmark'
							labelPosition='right'
							content='Yes'
							onClick={() => this.setState({ confirmOpen: false, loading: true }, () => this.handleAttachments())}
						/>
					</Modal.Actions>
				)}
			</Modal>
		)

		const display = this.state.loading ? (
			<Loader active={this.state.loading} size='big' inline='centered'>
				<small>
					Writing VERISEAL Certificate of Origin to the CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> 
					KSI Blockchain. This may take several seconds...
				</small>
			</Loader>
		) : (
			<section className='createCertusForm'>
				<Form className='transportationForm'>
					<Grid columns={3} divided stackable>
						<Grid.Row>
							<Grid.Column width={5}>
								<Popup
									content='Issuing Organization is the organization creating and issuing the VERISEAL Certificate 
										of Origin'
									trigger={<Label attached='top left'>Issuing Organization</Label>} />
								<Table basic='very'>
									<Table.Body>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Organization Name:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.name}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Address:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.address}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Phone Number:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.contact_no}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Email:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.email}</Table.Cell>
										</Table.Row>
										
									</Table.Body>
								</Table>
							</Grid.Column>
							<Grid.Column textAlign='left' width={6}>
								<Popup
									content='Release information declares details about the product origin and issuance of the 
										VERISEAL Certificate of Origin'
									trigger={<Label attached='top left'>Release Information</Label>} />
								<div className='mani-top-label'>
									<Form.Input
										label='Certificate Name'
										value={this.state.form_fields.certificate_name}
										name='certificate_name'
										onChange={this.updateForm}
										type='text'
										className='required-field'
										placeholder='Certificate Name'
										error={this.state.errors.certificate_name}
										maxLength={50}
									/>
								</div>
								<Form.Input
									label='Declaration of Origin'
									value={this.state.form_fields.declaration_of_origin}
									name='declaration_of_origin'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Declaration of Origin'
									error={this.state.errors.declaration_of_origin}
								/>
								<Form.Input
									label='Date Assigned'
									value={this.state.form_fields.date_assigned}
									name='date_assigned'
									onChange={this.updateForm}
									type='date'
									className='required-field'
									placeholder='Date Assigned'
									error={this.state.errors.date_assigned}
								/>
								<Form.Input
									label='Approver Name'
									value={this.state.form_fields.approver_name}
									name='approver_name'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Approver Name'
									error={this.state.errors.approver_name}
								/>
							</Grid.Column>
							<Grid.Column textAlign='left' width={5}>
								<Popup
									content='Originator is the declared originator of the product and will be receiving the issued 
										VERISEAL Certificate of Origin'
									trigger={<Label attached='top left'>Originator</Label>} />
								<Checkbox
									style={{paddingTop: '10px'}}
									label='Originator is same as Issuing Organization'
									onChange={(e, data) => this.issuerIsOriginator(data)}
									checked={this.state.issuerIsOriginator} />
								<div className='mani-top-label'>
									<Form.Input
										label='Originator Name'
										value={this.state.form_fields.originator_name}
										name='originator_name'
										onChange={this.updateForm}
										type='text'
										className='required-field'
										placeholder='Originator Name'
										error={this.state.errors.originator_name}
										maxLength={50}
									/>
								</div>
								<Form.Input
									label='Originator Affiliation'
									value={this.state.form_fields.originator_affiliation}
									name='originator_affiliation'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Originator Affiliation'
									error={this.state.errors.originator_affiliation}
								/>
								<Form.Input
									label='Originator Address'
									value={this.state.form_fields.originator_address}
									name='originator_address'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Originator Address'
									error={this.state.errors.originator_address}
								/>
								<Form.Input
									label='Regulatory License Number'
									value={this.state.form_fields.originator_license_number}
									name='originator_license_number'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Regulatory License Number'
									error={this.state.errors.originator_license_number}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Header as='h5' textAlign='right'>
						<span style={{ color: '#db2828' }}>*</span>
						{' '}
						Required
					</Header>

					<Popup
						content='Upload document attachments supporting the declaration of origin as required'
						trigger={<Label>Supporting Information</Label>} />
					<Tab panes={panes} style={{ marginTop: '1rem', marginBottom: '1rem' }} />

					<Button
						type='submit'
						inverted
						color='green'
						onClick={this.getConfirmation}
						loading={this.state.loading}
					>
						{this.state.pageTitle}
					</Button>
				</Form>
			</section>
		)

		return (
			<section id='mainContent' className='app light certusCreate'>
				<ErrorMessages errors={this.state.apiErrors} />
				<AppHeader title={<h1>{this.state.pageTitle}</h1>} breadcrumb={breadcrumb} />

				<p>
					Certify the origins of a VERISEAL Certificate of Analysis or VERISEAL Chemical Report 
					by creating a VERISEAL Certificate of Origin (COO). The VERISEAL COO is a declaration of the sample source and initiates 
					a digital chain of material and product evolution by securing COO information on the CERTUS
					<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> blockchain.
				</p>

				{display }

				{modal}
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user,
	accessCreds: state.accessCreds
})

const mapDispatchToProps = (dispatch) => ({
	getOrgCertusTokenBank: () => { dispatch(getOrgCertusTokenBank()) }
})

export default connect(
	mapStateToProps, mapDispatchToProps
)(withRouter(CertificateOfOriginCertusCreate))
