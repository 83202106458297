import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
	Button, Icon, Accordion, Menu, Modal
} from 'semantic-ui-react'
import PropTypes from 'prop-types'

class ImportControlPanel extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalOpen: false,
			accordionOneActive: false,
			accordionTwoActive: false
		}
	}

	downloadTemplate = () => {
		const link = document.createElement('a')
		link.href = `https://${this.props.accessCreds.CHROMATOGRAPHS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/import_templates/2/2102ff99-2c56-4ca2-a7fc-4e02132f78a4/open_labs_cds_template.csv`
		link.setAttribute('download', 'cannid_default_import_template.csv')
		document.body.appendChild(link)
		link.click()
	}

	render() {
		return (
			<section className='app light'>
				<Menu className='sampleActionsMenu' compact stackable>
					{this.props.includeNewMap &&
						<Menu.Item
							onClick={(e) => { e.stopPropagation(); this.props.history.push('/samples/import/map') }}
						>
							<span><Icon name='map' /></span>
							{' '}
							Map New CSV Template
						</Menu.Item>
					}
					{this.props.includeTemplates &&
						<Menu.Item
							onClick={(e) => { e.stopPropagation(); this.props.history.push('/samples/import/templates') }}
						>
							<span><Icon name='edit' /></span>
							{' '}
							Templates
						</Menu.Item>
					}
					<Menu.Item
						onClick={(e) => { e.stopPropagation(); this.setState({ modalOpen: true }) }}
					>
						<span><Icon name='info circle' /></span>
						{' '}
						Instructions
					</Menu.Item>
				</Menu>

				<Modal
					className='importConfirmation'
					size='large'
					open={this.state.modalOpen}
					onClose={() => this.setState({ modalOpen: false })}
					closeIcon
				>
					<Modal.Header>Instructions</Modal.Header>
					<Modal.Content scrolling>
						<Accordion className='accordian' style={{ marginTop: '1em', marginBottom: '1em' }}>
							<Accordion.Title
								className={this.state.accordionOneActive ? 'accordian-title' : 'accordian-inactive'}
								active={this.state.accordionOneActive}
								index={0}
								onClick={() => this.setState((prevState) => ({ accordionOneActive: !prevState.accordionOneActive }))}
								style={{ textAlign: 'center' }}
							>
								<Icon name='dropdown' />
								Instructions for Importing Results
							</Accordion.Title>
							<Accordion.Content active={this.state.accordionOneActive}>
								<ol style={{ paddingInlineStart: '25px', paddingInlineEnd: '8px', margin: '0' }}>
									<li>
										Get started using the Cann-ID Default Template or Map New CSV Template using a custom CSV result output file
										<div style={{ width: 'fit-content' }}>
											<Button className='blue'
												onClick={(e) => { e.stopPropagation(); this.downloadTemplate() }}
												style={{ marginTop: '.5em', marginBottom: '.25em', width: '100%' }}>
												Download Cann-ID Default Template
											</Button>
											<br />
											<Button className='blue'
												onClick={(e) => {
													e.stopPropagation()
													this.props.includeNewMap
													? this.props.history.push('/samples/import/map')
													: this.setState({ modalOpen: false })
												}}
												style={{ marginTop: '.25em', marginBottom: '.5em', width: '100%' }}>
												Map New CSV Template
											</Button>
										</div>
									</li>
									<li>
										Upload results by dropping the CSV result file on the upload drop zone, or click on the upload
										drop zone and navigate to the desired CSV result file
									</li>
									<li>Add ancillary supporting data as desired</li>
									<li>Review the parsed data and make edits as needed</li>
									<li>
										After verifying the information is correct, click <strong>Create Sample</strong> to upload
										data and create new sample
									</li>
								</ol>
							</Accordion.Content>
						</Accordion>
						<Accordion className='accordian' style={{ marginTop: '1em', marginBottom: '1em' }}>
							<Accordion.Title
								className={this.state.accordionTwoActive ? 'accordian-title' : 'accordian-inactive'}
								active={this.state.accordionTwoActive}
								index={0}
								onClick={() => this.setState((prevState) => ({ accordionTwoActive: !prevState.accordionTwoActive }))}
								style={{ textAlign: 'center' }}
							>
								<Icon name='dropdown' />
								Instructions for Mapping New Template
							</Accordion.Title>
							<Accordion.Content active={this.state.accordionTwoActive}>
								<ol style={{ paddingInlineStart: '25px', paddingInlineEnd: '8px', margin: '0' }}>
									<li>
										Upload template CSV file by dropping the CSV template file on the upload drop zone,
										or click on the upload drop zone and navigate to the desired CSV template file
										<br />
										<i>
											Note: Template CSV files should contain all of the analytes and information you wish to map
											to Cann-ID for that template
										</i>
									</li>
									<li>
										Select Template Type: Single Result or Batch Result
										<ul>
											<li>Single result corresponds to one sample per CSV file</li>
											<li>Batch result refers to more than one sample per CSV file</li>
										</ul>
									</li>
									<li>Provide short descriptive name for the new CSV template map</li>
									<li>
										Map the Cann-ID fields shown on the left to the corresponding CSV data fields shown on the
										right by clicking the Cann-ID field and then clicking the corresponding CSV data field
									</li>
									<li>After verifying the information is correct, click <strong>Save Mapped Template</strong> when finished</li>
								</ol>
							</Accordion.Content>
						</Accordion>
					</Modal.Content>
					<Modal.Actions>
						<Button
							className='confirmButton'
							content='Close'
							onClick={() => this.setState({ modalOpen: false })}
						/>
					</Modal.Actions>
				</Modal>
			</section>
		)
	}
}

ImportControlPanel.propTypes = {
	history: PropTypes.objectOf(PropTypes.shape({
		push: PropTypes.func.isRequired
	})).isRequired
}.isRequired

const mapStateToProps = (state) => {
	return {
		accessCreds: state.accessCreds
	}
}

export default withRouter(connect(mapStateToProps)(ImportControlPanel))
