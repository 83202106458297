import React, { Component } from 'react'
import { Breadcrumb, Form, Button, Icon, Checkbox, Modal } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { uploadFile } from 'react-s3'
import PropTypes from 'prop-types'
import moment from 'moment'

import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import ImportDropzone from './ImportDropzone'
import ImportResultsTable from './ImportResultsTable'
import ImportControlPanel from './ImportControlPanel'
import ImportResultsIntakeFields from './ImportResultsIntakeFields'
import cannidAPI from '../../cannidAPI/client'
import trimWhiteSpace from '../../lib/trim'
import deleteS3Object from '../../lib/deleteS3Object'
import renameS3Object from '../../lib/renameS3Object'
import asyncForEach from '../../lib/asyncForEach'
import dateFormatterUtcString from '../../lib/dateFormatterUtcString'

import { SampleTestServices } from '../../services/SampleTest'

class ImportSample extends Component {
	constructor(props) {
		super(props)
		this.s3Config = {
			bucketName: props.accessCreds.CHROMATOGRAPHS_BUCKET,
			region: process.env.REACT_APP_AWS_REGION,
			accessKeyId: props.accessCreds.CHROMATOGRAPHS_AK,
			secretAccessKey: props.accessCreds.CHROMATOGRAPHS_SK
		}
		this.attachmentOptions = [
			{
				text: 'Certificate of Analysis (.pdf)',
				value: 'Certificate of Analysis',
				ext: 'pdf'
			},
			{
				text: 'Data Zipfile (.zip)',
				value: 'Data Zipfile',
				ext: 'zip'
			},
			{
				text: 'Report PDF (.pdf)',
				value: 'Report PDF',
				ext: 'pdf'
			},
			{
				text: 'Additional Chromatogram (.csv)',
				value: 'Additional Chromatogram',
				ext: 'csv'
			},
			{
				text: 'Sample Image (.gif|.jpg|.jpeg|.png|.webp)',
				value: 'Sample Image',
				ext: 'gif|jpg|jpeg|png|webp'
			},
			{
				text: 'Other',
				value: 'Other',
				ext: '*'
			}
		]
		this.clearIntakeForm = {
			sample_weight: '',
			batch_number: '',
			strain_name: '',
			import_notes: '',
			loq: '0.0000001',
			intake_number: uuid()
		}
		this.unCleared = {
			singleFileUploadTemplates: [1, 2], // list of IDs for import_template_types which only receive a single import file 
			templateType: undefined,
			templateTypeOptions: [],
			template: undefined,
			templateRef: {},
			templateOptions: [],
			sampleTypes: [],
			location: []
		}
		this.clearForm = {
			intakeForm: [{ ...this.clearIntakeForm }],
			results: [],
			parsedCsvs: [],
			preparedSamples: [],
			confirmOpen: false,
			confirmed: false,
			fullTerms: false,
			method: {},
			methodOptions: [],
			orderedAnalytes: [],
			sampleTypeId: [],
			parsedBatch: '',
			fileRef: [],
			attachments: []
		}
		this.clearErrors = {
			errors: {},
			apiErrors: {}
		}
		this.state = { ...this.unCleared, ...this.clearForm, ...this.clearErrors }
		// TO DO: need to handle the circumstance of someone loading up the form and then closing the tab
		// or navigating away - the chromes need to be deleted from s3.
	}

	componentDidMount() {
		this.getSampleTypes()
		this.getImportTemplates()
		navigator.geolocation.getCurrentPosition((position) => {
			this.setState({
				location: [position.coords.latitude, position.coords.longitude]
			})
		})
	}

	functionalError = (error) => {
		this.setState({ apiErrors: error })
	}

	// On Load build state functions
	getSampleTypes = () => {
		SampleTestServices.getAllSampleTypes().then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			return response
		})
			.then((response) => {
				const items = response.data.data.map((item) => {
					const var1 = { key: item.id, text: item.name, value: item.id, slug: item.slug, rank: item.rank, itemwtf: item }
					return var1
				}).filter((item) => item.text !== 'Calibration' && item.text !== 'Standard') // filter is temporary to not permit calibration types since they don't display in sample results lists
				items.sort((a, b) => (a.rank - b.rank))
				this.setState({ sampleTypes: items })
			})
			.catch((err) => {
				console.error('sample type retrieval error!', err)
				const message = `${err}. Could not retrieve material types. Reload page.`
				this.functionalError(message)
				this.setState({ ...this.clearForm })
			})
	}

	getImportTemplates = () => {
		cannidAPI.get('/import_templates').then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			return response.data
		})
			.then((templates) => {
			// set initial values
				const template = templates[0].id
				const templateType = 0

				// build up dropdowns
				const uniqueTypeOptions = []
				const typeOptions = [{
					key: 'selectTemplateType',
					text: '-- Select Template Type to Continue --',
					value: 0
				}]
				const templateRef = {}
				const templateOptions = templates.map((temp) => {
					if (!uniqueTypeOptions.includes(temp.import_template_type.id)) {
						uniqueTypeOptions.push(temp.import_template_type.id)
						typeOptions.push({
							key: temp.import_template_type.name,
							text: temp.import_template_type.name,
							value: temp.import_template_type.id
						})
					}

					templateRef[temp.id] = temp
					let text = temp.default ? <span>{temp.name} {<span style={{color:'rgba(0,0,0,.4)'}}>(Default)</span>}</span> : temp.name
					return {
						key: temp.id,
						text: text,
						value: temp.id,
						template_type_id: temp.import_template_type.id
					}
				})

				this.setState({
					template,
					templateRef,
					templateOptions,
					templateTypeOptions: typeOptions,
					templateType
				})
			})
			.catch((err) => {
				console.error('error!', err)
				const message = `${err}. Could not retrieve import templates. Reload page.`
				this.functionalError(message)
				this.setState({ ...this.clearForm })
			})
	}

	// Form control functions
	updateIntakeFormField = (e, index) => {
		const intakeForm = this.state.intakeForm[index]
		intakeForm[e.target.name] = e.target.value
		const errors = { [index]: {} }
		if (intakeForm.import_notes.split('\n').length > 6) {
			errors[index].import_notes = 'Maximum of 6 lines'
			this.setState({ errors })
		}
		else if (intakeForm.import_notes.length > 330) {
			errors[index].import_notes = 'Maximum of 330 characters'
			this.setState({ errors })
		}
		else {
			this.setState((prevState) => {
				let prevIntake = [ ...prevState.intakeForm ]
				prevIntake[index] = intakeForm

				let newErrors = { ...prevState.errors }
				delete newErrors[index]
				return ({
					intakeForm: prevIntake, errors: newErrors
				})
			})
		}
	}

	updateSampleType = (value, index) => {
		this.setState((prevState) => {
			const newErrors = { ...prevState.errors }
			if (newErrors[index] && newErrors[index].material_type) delete newErrors[index].material_type
			const prevSampleTypeId = [ ...prevState.sampleTypeId ]
			prevSampleTypeId[index] = value
	
			return ({
				sampleTypeId: prevSampleTypeId,
				errors: newErrors
			})
		})
	}

	updateFormTemplateSelect = (e, { name, value }) => {
		// if it's the templateType dropdown that is changing, we auto set the current template to a permitted option
		let selectedTemplateType
		let selectedTemplate
		let templateTypeOptions = [ ...this.state.templateTypeOptions ]
		switch (name) {
			case 'templateType':
				selectedTemplateType = value
				selectedTemplate = this.state.templateOptions.filter((t) => t.template_type_id === value)[0].value
				templateTypeOptions = templateTypeOptions.filter((o) => o.value !== 0) // remove the empty selector type after initial choice
				break
			case 'template':
				selectedTemplateType = this.state.templateType
				selectedTemplate = value
				break
			default:
				selectedTemplateType = this.state.templateType
				selectedTemplate = this.state.template
		}
		this.setState({
			templateType: selectedTemplateType,
			template: selectedTemplate,
			templateTypeOptions: templateTypeOptions,
			intakeForm: [{ ...this.clearIntakeForm }],
			attachments: [],
			sampleTypeId: [],
			preparedSamples: [],
			results: [],
			...this.clearErrors
		}, () => this.parseCsvs())
	}

	generateBatchNumber = (index, initial = false) => {
		// create random batch # in support of universal uniqueness
		// includes user org, date, time, and random number
		let org = 'Cann.ID'
		if (this.props.user) {
			org = this.props.user.organization.name.replace(' ', '').slice(0, 5).trim()
		}

		const mmt = moment(new Date())
		const date = mmt.format('YYYYMMDD')
		const hrMin = mmt.format('kkmm')
		const generated = `${org}-${date}/${hrMin}-${Math.floor((Math.random() * 99999) + 1)}`

		this.setState((prevState) => {
			let updatedIntake = [ ...prevState.intakeForm ]
			updatedIntake[index] = {
				...updatedIntake[index],
				batch_number: generated
			}

			return ({
				intakeForm: updatedIntake,
				parsedBatch: initial ? generated : prevState.parsedBatch
			})
		})
	}

	resetBatchNumber = (index) => {
		this.setState((prevState) => {
			let updatedIntake = [ ...prevState.intakeForm ]
			updatedIntake[index] = {
				...updatedIntake[index],
				batch_number: prevState.parsedBatch
			}

			return ({ intakeForm: updatedIntake })
		})
	}

	setMethod = (event, { value }) => {
		this.state.methodOptions.forEach((method) => {
			if (method.id === value) { this.setState({ method }) }
		})
	}

	setMethodFromOrderedAnalytes = (orderedAnalytes) => {
		// use valid analyte list to check database for matching test method
		cannidAPI.post('/methods/lookup', { ordered_analytes: JSON.stringify(orderedAnalytes) }).then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			const methodOptions = response.data

			// create an option to generate a new method
			let org = ''
			if (this.props.user) {
				org = `${this.props.user.organization.name.replace(' ', '').slice(0, 5).trim()}-`
			}
			const mmt = moment(new Date())
			const date = mmt.format('YYYYMMDD')
			const hrMin = mmt.format('kkmm')
			const methodName = `${org}Import-${date}/${hrMin}-${Math.floor((Math.random() * 999) + 1)}`
			methodOptions.push({ id: 0, name: methodName, default: false })

			const newState = { method: methodOptions[0], methodOptions }
			this.setState(newState)
		})
		.catch((err) => {
			console.error('error!', err)
			const message = `${err}. Import Failed. Could not verify report method existence.`
			this.functionalError(message)
			this.setState({ ...this.clearForm })
		})
	}

	// Handling uploaded data by saving to state and then re-parsing all
	addParsed = (parsed, filename) => {
		this.generateBatchNumber(this.state.parsedCsvs.length, !this.state.parsedBatch)
		this.setState((prevState) => ({ ...this.clearErrors,
			parsedCsvs: [...prevState.parsedCsvs, parsed],
			fileRef: [...prevState.fileRef, filename] }), () => {
				if (this.state.templateType !== 0) this.parseCsvs()
			})
	}

	parseCsvs = () => {
		// set aside old results in case there are legacy chromes that need to be renamed
		const prevResults = this.state.results
		const csvAry = this.state.parsedCsvs
		const mapper = this.state.templateRef[this.state.template]

		// if batch, handle separately
		if (this.state.templateType === 2) {
			this.parseBatch(csvAry, mapper, prevResults)
		}
		// catchall parse as single injection results
		else {
			this.parseSingleResults(csvAry, mapper, prevResults)
		}
	}

	parseBatch = (csvAry, mapper, prevResults) => {
		// start off by clearing state so that we can blanket overwrite.
		// warning for future dev -- not multiple file friendly!
		this.setState({
			results: [],
			orderedAnalytes: [],
			sampleTypeId: [],
			preparedSamples: [],
			attachments: []
		})

		
		const orderedAnalytes = Object.keys(mapper.analytes)
		const preparedSamples = []
		const attachments = []
		const intakeForms = []
		let sampleTypeId = []
		let parsedResults = []

		csvAry.map((csv, resultIndex) => {
			// set aside our rows
			const sampleListHeaderRow = parseInt(mapper.rows['Sample List Header Row'], 10)

			// build samples & metadata
			let samples = csv.slice(sampleListHeaderRow + 1, csv.length)
			const originalHeaderValues = Object.values(mapper.original[sampleListHeaderRow])

			const sampleResults = samples.map((sample)=> {
				const sampleValues = Object.values(sample)
				const metadata = { 'Imported Filename': this.state.fileRef[resultIndex].name }

				originalHeaderValues.forEach((headerName, columnIndex) => {
					if (!Object.keys(mapper.analytes).includes(headerName)) metadata[headerName] = sampleValues[columnIndex]
				})

				let lowestConcentration = 99999999
				const formatted =  Object.keys(mapper.analytes).map((analyte) => {
					const perc = sample[mapper.analytes[analyte]['Concentration %'].split(",")[1]]
						? sample[mapper.analytes[analyte]['Concentration %'].split(',')[1]].toString() : '0'
					if (perc < lowestConcentration && perc > 0) lowestConcentration = perc
					return {
						name: analyte,
						compound: analyte,
						concentration_perc: perc,
						amount: perc ? parseFloat(perc) : 0,
						area: 'N/A',
						peak_area: 'N/A',
						rt_min: 'N/A',
						peak_retention_time: 'N/A'
					}
				})

				// now that we have the proper metadata compiled, handle acceptable dilution and other vial deets
				let dilution = parseFloat(metadata.Dilution) ? parseFloat(metadata.Dilution) : 1
				if (!dilution || dilution === '' || dilution <= 0) {
					dilution = 1
				}
				preparedSamples.push({ dilution_factor: dilution, label: '', acquire: true, vial_type: 'green_1ml' })
				attachments.push([])

				let intakeForm = { ...this.clearIntakeForm, intake_number: uuid() }
				intakeForm['batch_number'] = mapper.metadata['Lims ID']
					? sample[mapper.metadata['Lims ID'].split(',')[1].toString()]
					: this.state.parsedBatch
				if (mapper.metadata['Sample name']) {
					intakeForm['strain_name'] = sample[mapper.metadata['Sample name'].split(',')[1].toString()]
				}
				if (mapper.metadata['Sample amount']) {
					intakeForm['sample_weight'] = sample[mapper.metadata['Sample amount'].split(',')[1].toString()]
				}
				if (mapper.metadata.Description) {
					intakeForm['import_notes'] = sample[mapper.metadata.Description.split(',')[1].toString()]
				}
				intakeForm['loq'] = (mapper.metadata['LOQ'] && sample[mapper.metadata['LOQ'].split(',')[1].toString()])
					? sample[mapper.metadata['LOQ'].split(',')[1].toString()].toString()
					: lowestConcentration.toString()
				intakeForms.push(intakeForm)

				let assumedSampleTypeId = undefined
				this.state.sampleTypes.forEach((sampleType) => {
					if (metadata['Sample type'] && metadata['Sample type'].trim() === sampleType.text) {
						sampleTypeId.push(sampleType.key)
					}
				})
				sampleTypeId.push(assumedSampleTypeId)

				return { uuid: uuid(), metadata, results: formatted,
					invalid: [],
					importedTemplate: {
						id: this.state.template,
						name: this.state.templateRef[this.state.template].name
					}
				}
			})
			parsedResults = [...parsedResults, ...sampleResults]
		})

		// if chrome was uploaded to s3 then rename
		prevResults.forEach((prevRes, prevIdx) => {
			if (prevRes.chromeUrl) {
				const oldKey = prevRes.chromeUrl.split('/').splice(3).join('/')
				const newKey = oldKey.replace(prevRes.uuid, parsedResults[prevIdx].uuid)
				renameS3Object({
					bucket: this.props.accessCreds.CHROMATOGRAPHS_BUCKET,
					accessKeyId: this.props.accessCreds.CHROMATOGRAPHS_AK,
					secretAccessKey: this.props.accessCreds.CHROMATOGRAPHS_SK,
					region: process.env.REACT_APP_AWS_REGION
				}, oldKey, newKey)
				parsedResults[prevIdx] = {
					...parsedResults[prevIdx],
					chromeUrl: prevRes.chromeUrl.replace(prevRes.uuid, parsedResults[prevIdx].uuid),
					chromeRtUnits: prevRes.chromeRtUnits
				}
			}
		})

		this.setState({
			intakeForm: intakeForms,
			results: parsedResults,
			orderedAnalytes,
			sampleTypeId,
			preparedSamples,
			attachments
		})

		this.setMethodFromOrderedAnalytes(orderedAnalytes)
	}

	parseSingleResults = (csvAry, mapper, prevResults) => {
		let sampleTypeId = []
		let orderedAnalytes = []
		const attachments = []
		const preparedSamples = []
		const intakeForms = []
		const parsedResults = csvAry.map((csv, resultIndex) => {
			// set aside our rows for handling metadata on top or bottom
			const analyteHeaderRow = parseInt(mapper.rows['Analyte Header Row'], 10)
			const firstMetadataRow = parseInt(mapper.rows['First Metadata Row'], 10)
			// need to test the 'or equal to' to ensure behaves properly. probably should reject if is the case
			// if they're equal then that would be a different template type (layout)
			const metadataOnTop = analyteHeaderRow >= firstMetadataRow ? true : false

			// build injection analytes & metadata
			let analytes = []
			let compareRow
			if (metadataOnTop) {
				// add 1 at starting index to skip header in slice
				analytes = csv.slice(parseInt(mapper.rows['Analyte Header Row'], 10) + 1, csv.length)
			}
			else {
				// metadata is on bottom. need to investigate and identify if that first metadata row shifted because of
				// more or less analytes in list than template had
				const originalFirst = mapper.original[firstMetadataRow]
				let compareCount = 0
				compareRow = firstMetadataRow
				// iterate all rows in the imported csv comparing to the originally templated rows
				csv.forEach((csvRow, rowIndex) => {
					let rowMatches = 0
					Object.keys(csvRow).forEach((fieldKey) => {
						// if this row's column cell has a matching value to the original's column cell we mark as a match
						if (originalFirst && originalFirst[fieldKey] && csvRow[fieldKey] === originalFirst[fieldKey]) { rowMatches ++ }
					})
					// row with the most matched cells wins! matches assume that field keys and other identifiers in row are consistent
					// in outputs
					if (rowMatches > compareCount) {
						compareCount = rowMatches
						compareRow = rowIndex
					}
				})
				// add 1 at starting index to skip header in slice
				analytes = csv.slice(analyteHeaderRow + 1, compareRow)
			}

			const metadata = { 'Imported Filename': this.state.fileRef[resultIndex].name }
			Object.keys(mapper.metadata).forEach((key) => {
				const [row, field] = mapper.metadata[key].split(',')
				let fixedRow = row
				// if metadata on bottom AND first metadata row has shifted, then we handle. otherwise we can use default
				if (!metadataOnTop && compareRow && compareRow !== firstMetadataRow) {
					const shift = compareRow - firstMetadataRow
					fixedRow = parseInt(row, 10) + shift
				}

				if (!csv.at(fixedRow)) {
					metadata[key] = 'N/A - Template Error'
					this.functionalError('Missing metadata row! Bad template selection. Verify the selected "Template Type", "Template", and CSV format.')
				}
				else {
					metadata[key] = csv.at(fixedRow)[field]
				}
			})

			// now that we have the proper metadata compiled, handle acceptable dilution and other vial deets
			let dilution = parseFloat(metadata.Dilution) ? parseFloat(metadata.Dilution) : 1
			if (!dilution || dilution === '' || dilution <= 0) {
				dilution = 1
			}
			if (!preparedSamples[resultIndex]) {
				preparedSamples.push({ dilution_factor: dilution, label: '', acquire: true, vial_type: 'green_1ml' })
			}

			let lowestConcentration = (analytes.length > 0
				&& mapper.analytes['Concentration %']
				&& analytes[0][mapper.analytes['Concentration %'].split(',')[1]]
				&& parseFloat(analytes[0][mapper.analytes['Concentration %'].split(',')[1]]))
				? parseFloat(analytes[0][mapper.analytes['Concentration %'].split(',')[1]])
				: 99999999
			const formatted = []
			const invalid = []
			// for each result uploaded, reset the ordered analyte list. this means the last result uploaded
			// will be used to lookup potential report method matches
			orderedAnalytes = []

			analytes.forEach((lyte) => {
				// normalize data types
				const lyteAmount = (mapper.analytes['Concentration %']
					&& lyte[mapper.analytes['Concentration %'].split(',')[1]]
					&& parseFloat(lyte[mapper.analytes['Concentration %'].split(',')[1]]))
					? parseFloat(lyte[mapper.analytes['Concentration %'].split(',')[1]]) : 0
				if (lyteAmount < lowestConcentration && lyteAmount > 0) lowestConcentration = lyteAmount
				const lyteName = (mapper.analytes.Name
					&& lyte[mapper.analytes.Name.split(',')[1]]
					&& lyte[mapper.analytes.Name.split(',')[1]].toString().trim())
					? lyte[mapper.analytes.Name.split(',')[1]].toString().trim() : 'N/A'
				const lyteArea = (mapper.analytes.Area
					&& lyte[mapper.analytes.Area.split(',')[1]]
					&& parseFloat(lyte[mapper.analytes.Area.split(',')[1]]))
					? parseFloat(lyte[mapper.analytes.Area.split(',')[1]]) : 'N/A'
					// lyte[mapper.analytes['Area'].split(',')[1]].trim()

				const result = {
					// standardized key/values
					amount: lyteAmount,
					compound: lyteName,
					peak_area: lyteArea,
					peak_retention_time: mapper.analytes['Retention Time'] ? lyte[mapper.analytes['Retention Time'].split(',')[1]] : 'N/A', // peak vs min?
					// everything else
					name: lyteName,
					amount_mg_g: mapper.analytes['Amount mg/g'] ? lyte[mapper.analytes['Amount mg/g'].split(',')[1]] : 'N/A',
					amount_ug_ml: mapper.analytes['Amount ug/mL'] ? lyte[mapper.analytes['Amount ug/mL'].split(',')[1]] : 'N/A',
					area: mapper.analytes.Area ? lyte[mapper.analytes.Area.split(',')[1]] : 'N/A',
					concentration_perc: mapper.analytes['Concentration %'] ? lyte[mapper.analytes['Concentration %'].split(',')[1]] : 'N/A',

					peak_end: mapper.analytes['Peak End'] ? lyte[mapper.analytes['Peak End'].split(',')[1]] : 'N/A',
					peak_start: mapper.analytes['Peak Start'] ? lyte[mapper.analytes['Peak Start'].split(',')[1]] : 'N/A',
					resolution: mapper.analytes.Resolution ? lyte[mapper.analytes.Resolution.split(',')[1]] : 'N/A',
					rt_min: mapper.analytes['Retention Time'] ? lyte[mapper.analytes['Retention Time'].split(',')[1]] : 'N/A' // peak vs min?
				}
				// divide up result based on validity
				// only use valid analytes for ordered analytes lookup. this means that we'll only ever be referencing
				// the last import under multiple injections. need to build out a collection of all name lists and use the
				// longest/most complex one. maybe do some level of ordering comparison between among them
				if (lyteName && lyteName !== 'N/A' && lyteAmount >= 0) { formatted.push(result); orderedAnalytes.push(lyteName) }
				else if (Object.values(lyte).every((x) => x.trim() === '')) { console.warn('Empty CSV analyte row', lyte) }
				else { invalid.push(result) }
			})

			let intakeForm = { ...this.clearIntakeForm, intake_number: uuid() }
			intakeForm['batch_number'] = metadata['Lims ID'] ? metadata['Lims ID'] : this.state.parsedBatch
			if (metadata['Sample name']) {
				intakeForm['strain_name'] = metadata['Sample name']
			}
			if (metadata['Sample amount']) {
				intakeForm['sample_weight'] = metadata['Sample amount']
			}
			if (metadata.Description) {
				intakeForm['import_notes'] = metadata.Description
			}
			intakeForm['loq'] = (metadata['LOQ'] && metadata['LOQ'].toString())
					? metadata['LOQ'].toString() : lowestConcentration.toString()
			intakeForms.push(intakeForm)

			let assumedSampleTypeId = undefined
			this.state.sampleTypes.forEach((sampleType) => {
				if (metadata['Sample type'] && metadata['Sample type'].trim() === sampleType.text) {
					assumedSampleTypeId = sampleType.key
				}
			})
			sampleTypeId.push(assumedSampleTypeId)

			attachments.push([])

			if (formatted.length <= 0) {
				// if no valid analytes, fire error
				this.functionalError('Could not parse any valid analytes from selected file. Verify the selected "Template Type", "Template", and CSV format.')
			}

			return { uuid: uuid(), metadata, results: formatted, invalid,
				importedTemplate: {
					id: this.state.template,
					name: this.state.templateRef[this.state.template].name
				}
			}
		})

		// if chrome was uploaded to s3 then rename
		prevResults.forEach((prevRes, prevIdx) => {
			if (prevRes.chromeUrl) {
				const oldKey = prevRes.chromeUrl.split('/').splice(3).join('/')
				const newKey = oldKey.replace(prevRes.uuid, parsedResults[prevIdx].uuid)
				renameS3Object({
					bucket: this.props.accessCreds.CHROMATOGRAPHS_BUCKET,
					accessKeyId: this.props.accessCreds.CHROMATOGRAPHS_AK,
					secretAccessKey: this.props.accessCreds.CHROMATOGRAPHS_SK,
					region: process.env.REACT_APP_AWS_REGION
				}, oldKey, newKey)
				parsedResults[prevIdx] = {
					...parsedResults[prevIdx],
					chromeUrl: prevRes.chromeUrl.replace(prevRes.uuid, parsedResults[prevIdx].uuid),
					chromeRtUnits: prevRes.chromeRtUnits
				}
			}
		})

		this.setState({
			intakeForm: intakeForms,
			results: parsedResults,
			orderedAnalytes,
			sampleTypeId,
			preparedSamples,
			attachments
		})

		this.setMethodFromOrderedAnalytes(orderedAnalytes)
	}

	updateResult = (resultUuid, info) => {
		const newResults = []
		this.state.results.forEach((result) => {
			let newResult
			if (result.uuid === resultUuid) {
				newResult = { ...result, ...info }
			}
			else {
				newResult = { ...result }
			}
			newResults.push(newResult)
		})
		this.setState({ results: newResults })
	}

	updatePreparedSample = (index, info) => {
		const newPreparedSamples = [...this.state.preparedSamples]
		newPreparedSamples[index] = { ...newPreparedSamples[index], ...info }
		this.setState({ ...this.clearErrors, preparedSamples: newPreparedSamples })
	}

	// Form Submission and Data upload functions
	getErrors = () => {
		const errors = {}
		this.state.intakeForm.forEach((intake, index) => {
			const intakeErr = {}
			if (!intake.batch_number) {
				intakeErr.batch_number = 'Must include batch number.'
			}
			if (!trimWhiteSpace(intake.strain_name)
				|| trimWhiteSpace(intake.strain_name.replace(/\s+/g, '')) === ''
				|| !trimWhiteSpace(intake.strain_name).length
				|| trimWhiteSpace(intake.strain_name).length > 50) {
				intakeErr.strain_name = 'Must include strain name. 50 characters max length.'
			}
			const re = /^[\w\d\s-_]+$/
			if (!re.test(intake.strain_name)) {
				intakeErr.strain_name = 'Strain/Sample Name can only contain letters, numbers, spaces, dashes, and underscores.'
			}

			if (!intake.loq) {
				intakeErr.loq = 'Must include LOQ.'
			}
			else if (!intake.loq.match(/^[+]?(\d*\.)?\d+$/)) {
				intakeErr.loq = 'Only positive numbers allowed for LOQ.'
			}
			else if (parseFloat(intake.loq) === 0) {
				intakeErr.loq = 'LOQ cannot be zero.'
			}

			if (!this.state.sampleTypeId[index]) {
				intakeErr.material_type = 'Must include Material Type.'
			}

			if (intake.sample_weight !== '' && !intake.sample_weight.match(/^[+]?(\d*\.)?\d+$/)) {
				intakeErr.sample_weight = 'Only positive numbers allowed for sample weight.'
			}
			else if (parseFloat(intake.sample_weight) === 0) {
				intakeErr.sample_weight = 'Sample weight cannot be zero.'
			}
			// sample weight required?
			// else if (!intake.sample_weight) {
			// 	intakeErr.sample_weight = 'Sample weight must be populated (positive number).'
			// }

			if (intake.import_notes.split('\n').length > 6) {
				intakeErr.import_notes = 'Maximum of 6 lines'
			}
			else if (intake.import_notes.length > 330) {
				intakeErr.import_notes = 'Maximum of 330 characters'
			}

			const dl = parseFloat(this.state.preparedSamples[index].dilution_factor)
			if (!dl || dl === '' || dl <= 0) {
				intakeErr.dilution_factor = true
			}

			if (Object.keys(intakeErr).length > 0) errors[index] = intakeErr
		})
		return errors
	}

	getConfirmation = () => {
		if (!Object.keys(this.getErrors()).length) {
			this.setState({ ...this.clearErrors, confirmOpen: true, confirmed: false })
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	uploadFilesAndCreateSample = async () => {
		document.body.classList.add('loading-indicator')
		let updatedResults = [...this.state.results]
		let updatedIntakes = [...this.state.intakeForm]

		// iterate all the source result files and upload to s3. or use the same single one for all samples in bulk upload
		const rawFileUploads = this.state.templateType === 1
			? await asyncForEach(this.state.fileRef, async (file, fileIndex) => {
				const resultConfig = { ...this.s3Config, dirName: `${this.state.results[fileIndex].uuid}/import/source` }
				
				return await uploadFile(file, resultConfig).then((data) => {
					const updatedResult = { ...updatedResults[fileIndex], importedSource: data.location }
					updatedResults[fileIndex] = updatedResult
				}).catch((err) => {
					console.error('specific source upload error', err)
					const message = `Failed uploading source file for vial ${fileIndex + 1}, please try again. Request ${err}.`
					this.functionalError(message)
					document.body.classList.remove('loading-indicator')
					this.setState({ confirmOpen: false, confirmed: false })
					throw new Error(message)
				})
			})
			.catch((err) => {
				console.error('general source file upload error!', err)
				this.functionalError(`Issue uploading source files. ${err}`)
				document.body.classList.remove('loading-indicator')
				this.setState({ confirmOpen: false, confirmed: false })
				return err
			})
			: await asyncForEach(this.state.results, async (res, resIndex) => {
				const resultConfig = { ...this.s3Config, dirName: `${this.state.results[resIndex].uuid}/import/source` }

				return await uploadFile(this.state.fileRef[0], resultConfig).then((data) => {
					const updatedResult = { ...updatedResults[resIndex], importedSource: data.location }
					updatedResults[resIndex] = updatedResult
				}).catch((err) => {
					console.error('specific source upload error', err)
					const message = `Failed uploading source file for sample ${resIndex + 1}, please try again. Request ${err}.`
					this.functionalError(message)
					document.body.classList.remove('loading-indicator')
					this.setState({ confirmOpen: false, confirmed: false })
					throw new Error(message)
				})
			})
			.catch((err) => {
				console.error('general source file upload error!', err)
				this.functionalError(`Issue uploading source files. ${err}`)
				document.body.classList.remove('loading-indicator')
				this.setState({ confirmOpen: false, confirmed: false })
				return err
			})

		// iterate all the attachment files and upload to s3
		const attachmentUploads = await asyncForEach(this.state.attachments, async (resultAttachments, resultIndex) => {
			return await asyncForEach(resultAttachments, async (attachment, attachmentIndex) => {
				const resultConfig = { ...this.s3Config, dirName: `${this.state.results[resultIndex].uuid}/import/attachments/${attachmentIndex}` }

				return await uploadFile(attachment.file, resultConfig).then((data) => {
					const attachAry = updatedResults[resultIndex].attachments ? updatedResults[resultIndex].attachments : []
					const updatedResult = { ...updatedResults[resultIndex],
						attachments: [...attachAry, {
							attachmentType: attachment.type,
							name: attachment.file.name,
							fileType: attachment.file.type,
							size: attachment.file.size,
							url: data.location
						}]
					}
					updatedResults[resultIndex] = updatedResult
					if (attachment.type === 'Sample Image') updatedIntakes[resultIndex]['sample_image_url'] = data.location
				}).catch((err) => {
					console.error('specific attachment upload error', err)
					const message = `Failed uploading attachment for vial ${resultIndex + 1}, please try again. Request ${err}.`
					this.functionalError(message)
					document.body.classList.remove('loading-indicator')
					this.setState({ confirmOpen: false, confirmed: false })
					throw new Error(message)
				})
			})
		}).catch((err) => {
			console.error('general attachment upload error!', err)
			this.functionalError(`Issue uploading attachments. ${err}`)
			document.body.classList.remove('loading-indicator')
			this.setState({ confirmOpen: false, confirmed: false })
			return err
		})

		await Promise.all([rawFileUploads, attachmentUploads]).then((response) => {
			// for each upload process, response will undefined if successful, but have the error if failed
			// so remove successful and only proceed if errors exist in the array
			if (response.filter(e => e !== undefined).length === 0) {
				this.setState({ results: updatedResults, intakeForm: updatedIntakes, confirmOpen: false, confirmed: true }, this.postMethod)
			}
			else {
				window.scrollTo(0, 0)
				// loading-indicator and state cleared at each async catch so this re-iteration of them is probably excessive
				// but impotent so better safe than sorry
				document.body.classList.remove('loading-indicator')
				this.setState({ confirmOpen: false, confirmed: false })
			}
		})
	}

	postMethod = () => {
		const methodJson = {
			ordered_analytes: this.state.orderedAnalytes,
			method: this.state.method,
			derived_compounds: []
			// if method is being created, derived compounds not attached through import so we just use empty array.
			// would need to be applied by editing report method afterwards (which currently is disabled in report method edit form)
		}

		cannidAPI.post('/methods', methodJson).then((methodResponse) => {
			if (this.state.templateType === 1) {
				const intakeForm = { ...this.state.intakeForm[0],
					sample_weight: parseFloat(this.state.intakeForm[0].sample_weight).toString(),
					confirmed: this.state.confirmed }
	
				// hard coded as sample type Flower
				const sampleJson = {
					sample: {
						intake_form: intakeForm,
						results: this.state.results,
						prepared_samples: this.state.preparedSamples,
						sample_type_id: this.state.sampleTypeId[0],
						import: true,
						latitude: this.state.location[0],
						longitude: this.state.location[1],
						test_method_id: methodResponse.data.id
					}
				}

				this.sampleFromState(sampleJson).then(() => {
					document.body.classList.remove('loading-indicator')
					this.props.history.push('/test-result/list')
				})
			}
			else {
				asyncForEach(this.state.results, async (res, resIndex) => {
					const intakeForm = { ...this.state.intakeForm[resIndex],
						sample_weight: parseFloat(this.state.intakeForm[resIndex].sample_weight).toString(),
						confirmed: this.state.confirmed }
		
					// hard coded as sample type Flower
					const sampleJson = {
						sample: {
							intake_form: intakeForm,
							results: [res],
							prepared_samples: [this.state.preparedSamples[resIndex]],
							sample_type_id: this.state.sampleTypeId[resIndex],
							import: true,
							latitude: this.state.location[0],
							longitude: this.state.location[1],
							test_method_id: methodResponse.data.id
						}
					}

					return this.sampleFromState(sampleJson)
				}).then(() => {
					document.body.classList.remove('loading-indicator')
					this.props.history.push('/test-result/list')
				})
			}
		})
		.catch((err) => {
			console.error('method post error!', err)
			document.body.classList.remove('loading-indicator')
			const message = `${err}. Import Failed. Could not establish report method.`
			this.functionalError(message)
			this.setState({ ...this.clearForm })
		})
	}

	sampleFromState = async (sampleJson) => {
		let intake = sampleJson.sample.intake_form
		let notes = trimWhiteSpace(intake.import_notes) ? `${trimWhiteSpace(intake.import_notes)} [${dateFormatterUtcString()}]` : ''
		sampleJson.sample.intake_form.import_notes = notes
		return cannidAPI.post('/samples', sampleJson)
			.catch((err) => {
				console.error('sample post error!', err)
				document.body.classList.remove('loading-indicator')
				const message = `${err}. Import Failed. Verify the selected "Template Type", "Template", and CSV format.`
				this.functionalError(message)
				this.setState({ ...this.clearForm })
			})
	}

	removeResult = (resultIndex) => {
		// remove uploaded chrome first
		if (this.state.results[resultIndex].chromeUrl) {
			const key = this.state.results[resultIndex].chromeUrl.split('/').splice(3).join('/')
			deleteS3Object({
				bucket: this.props.accessCreds.CHROMATOGRAPHS_BUCKET,
				accessKeyId: this.props.accessCreds.CHROMATOGRAPHS_AK,
				secretAccessKey: this.props.accessCreds.CHROMATOGRAPHS_SK,
				region: process.env.REACT_APP_AWS_REGION
			}, key)
		}

		// update the state
		if (this.state.results.length <= 1) {
			this.setState({ ...this.clearForm, ...this.clearErrors })
		}
		else {
			this.setState((prevState) => ({
				...this.clearErrors,
				fileRef: this.state.singleFileUploadTemplates.includes(this.state.templateType)
					? prevState.fileRef : prevState.fileRef.filter((f, i) => i !== resultIndex),
				parsedCsvs: this.state.singleFileUploadTemplates.includes(this.state.templateType)
					? prevState.parsedCsvs : prevState.parsedCsvs.filter((c, i) => i !== resultIndex),
				preparedSamples: prevState.preparedSamples.filter((s, i) => i !== resultIndex),
				results: prevState.results.filter((r, i) => i !== resultIndex),
				attachments: prevState.attachments.filter((a, i) => i !== resultIndex),
				intakeForm: prevState.intakeForm.filter((a, i) => i !== resultIndex),
				sampleTypeId: prevState.sampleTypeId.filter((a, i) => i !== resultIndex)
			}))
		}
	}

	addAttachment = (resultIndex, attachment) => {
		const attachmentFormatted = {
			file: attachment,
			type: 'Other'
		}
		this.setState((prevState) => {
			const sampleAttachList = prevState.attachments[resultIndex] ? [...prevState.attachments[resultIndex]] : []
			sampleAttachList.push(attachmentFormatted)
			const updatedAttachments = [...prevState.attachments]
			updatedAttachments[resultIndex] = sampleAttachList
			return ({ attachments: updatedAttachments })
		})
	}

	removeAttachment = (resultIndex, attachmentIndex) => {
		this.setState((prevState) => {
			const updatedAttachments = [...prevState.attachments]
			updatedAttachments[resultIndex] = updatedAttachments[resultIndex].filter((a, i) => i !== attachmentIndex)
			return ({ attachments: updatedAttachments })
		})
	}

	updateAttachment = (resultIndex, attachmentIndex, type, filename) => {
		this.setState({ ...this.clearErrors })
		const chosenType = this.attachmentOptions.filter((o) => o.value === type)[0]
		if (chosenType.value === 'Other' || filename.match(new RegExp(`.*\.(${chosenType.ext}|${chosenType.ext.toUpperCase()})$`))) {
			this.setState((prevState) => {
				const updatedAttachments = [...prevState.attachments]
				const attachmentList = updatedAttachments[resultIndex]
				attachmentList[attachmentIndex] = {
					...attachmentList[attachmentIndex],
					type: type
				}
				updatedAttachments[resultIndex] = attachmentList
				return ({
					attachments: updatedAttachments
				})
			})
		}
		else {
			this.functionalError(`Attachment type ${type} must be of file extension .${chosenType.ext}`)
			this.setState({ errors: { attachments: { [resultIndex]: attachmentIndex } } })
			window.scrollTo(0, 0)
		}
	}

	startOver = () => {
		this.state.results.forEach((res) => {
			if (res.chromeUrl) {
				const key = res.chromeUrl.split('/').splice(3).join('/')
				deleteS3Object({
					bucket: this.props.accessCreds.CHROMATOGRAPHS_BUCKET,
					accessKeyId: this.props.accessCreds.CHROMATOGRAPHS_AK,
					secretAccessKey: this.props.accessCreds.CHROMATOGRAPHS_SK,
					region: process.env.REACT_APP_AWS_REGION
				}, key)
			}
		})
		this.setState({ ...this.clearForm, ...this.clearErrors })
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/test-result/list'><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>Import New Results</Breadcrumb.Section>
			</Breadcrumb>
		)

		const templateTypeSelector = <Form.Select
				search
				selection
				label='Template Type'
				value={this.state.templateType}
				name='templateType'
				onChange={this.updateFormTemplateSelect}
				className='required-field inputter'
				placeholder='Template Type'
				error={this.state.errors.templateType}
				options={this.state.templateTypeOptions}
			/>

		let FormFields = (
			<Form style={{ maxWidth: '50%', margin: '0 auto' }}>
				{templateTypeSelector}
			</Form>
		)

		if (this.state.results.length) {
			FormFields =
				<Form>
					<Form.Group widths={5}>
						{templateTypeSelector}
						<Form.Select
							search
							selection
							label='Template'
							value={this.state.template}
							name='template'
							onChange={this.updateFormTemplateSelect}
							className='required-field inputter'
							placeholder='Template'
							error={this.state.errors.template}
							options={this.state.templateOptions.filter((t) => t.template_type_id === this.state.templateType)}
						/>
						<Form.Select
							search
							selection
							label='Report Method'
							value={this.state.method.id}
							onChange={this.setMethod}
							className='required-field inputter'
							placeholder='Report Method'
							options={this.state.methodOptions.map((option) => {
								const description = option.id !== 0 ? '' : '(Create)'
								return {
									key: option.id,
									text: option.name,
									value: option.id,
									description
								}
							})}
						/>
					</Form.Group>
					{(this.state.templateType === 1) &&
						<ImportResultsIntakeFields
							errors={this.state.errors}
							index={0}

							sampleTypes={this.state.sampleTypes}
							sampleTypeId={this.state.sampleTypeId[0]}
							updateSampleType={(value, index) => this.updateSampleType(value, index)}
							intakeForm={this.state.intakeForm[0]}
							updateIntakeFormField={(e, index) => this.updateIntakeFormField(e, index)}
							parsedBatch={this.state.parsedBatch}
							generateBatchNumber={(index) => this.generateBatchNumber(index)}
							resetBatchNumber={(index) => this.resetBatchNumber(index)}
						/>
					}
				</Form>
		}

		const modalContent = (
			<div>
				<Checkbox
					label='I have previewed the data being uploaded and confirm it is accurate to the best of my knowledge.'
					checked={this.state.confirmed}
					onChange={() => this.setState({ confirmed: !this.state.confirmed })}
				/>
				<a
					className='import-full-terms'
					onClick={() => this.setState({ fullTerms: !this.state.fullTerms })}
				>
					<Icon name='file alternate outline' />Full Terms
				</a>
				{this.state.fullTerms
					&& (
						<p>
							I understand the data being uploaded is sourced from outside the standard Cann.ID ecosystem and the report being generated will indicate this.
							I understand that the Cann.ID Data Importer is uploading data contained in a .CSV format that could have been altered without my knowledge.
							I also understand that the records highlighted in orange are in a format that CannID does not recognize or is missing required data and WILL NOT
							be included in the web report. Are you sure you want to proceed? Contact Cann.ID
							at <a href='mailto:tech@ionizationlabs.com' style={{ cursor: 'pointer' }}>tech@ionizationlabs.com</a> if you have questions about the Cann.ID Data Importer.
						</p>
					)}
			</div>
		)

		return (
			<section id='mainContent' className='app light importSamplePage'>
				<AppHeader title={<h1>Import New Results</h1>} breadcrumb={breadcrumb} />
				<ErrorMessages errors={this.state.apiErrors} />
				<ImportControlPanel includeNewMap includeTemplates />

				{(this.state.parsedCsvs.length > 0 && this.state.results.length === 0) &&
					<div className='intakeForm'>
						{FormFields}
					</div>
				}

				{this.state.results.length > 0
					&& (
						<section className='app light'>
							<div className='intakeForm'>
								{FormFields}
							</div>
							<ImportResultsTable
								results={this.state.results}
								preparedSamples={this.state.preparedSamples}
								parsedCsvs={this.state.parsedCsvs}
								attachments={this.state.attachments}
								attachmentOptions={this.attachmentOptions}
								updateResult={this.updateResult}
								updatePreparedSample={this.updatePreparedSample}
								functionalError={this.functionalError}
								removeResult={this.removeResult}
								addAttachment={this.addAttachment}
								removeAttachment={this.removeAttachment}
								updateAttachment={this.updateAttachment}
								clearErrors={() => this.setState({ ...this.clearErrors })}
								singleFile={this.state.singleFileUploadTemplates.includes(this.state.templateType)}

								errors={this.state.errors}
								sampleTypes={this.state.sampleTypes}
								sampleTypeId={this.state.sampleTypeId}
								updateSampleType={(value, index) => this.updateSampleType(value, index)}
								intakeForm={this.state.intakeForm}
								updateIntakeFormField={(e, index) => this.updateIntakeFormField(e, index)}
								parsedBatch={this.state.parsedBatch}
								generateBatchNumber={(index) => this.generateBatchNumber(index)}
								resetBatchNumber={(index) => this.resetBatchNumber(index)}
								batchTemplate={this.state.templateType === 2}
							/>
						</section>
					)}

				{((this.state.templateType === 0 && !this.state.parsedCsvs.length)
					|| (!this.state.singleFileUploadTemplates.includes(this.state.templateType) && this.state.templateType !== 0)
					|| (this.state.singleFileUploadTemplates.includes(this.state.templateType) && !this.state.results.length))
					&& (
						<ImportDropzone
							addParsed={this.addParsed}
							functionalError={this.functionalError}
						/>
					)}

				{this.state.results.length > 0
					&& (
						<section className='app light'>
							<Button
								className='blue'
								type='submit'
								onClick={this.getConfirmation}
							>
								Create Sample
							</Button>
							<Button
								className='red'
								onClick={() => this.startOver()}
							>
								Start Over
							</Button>
						</section>
					)}

				<Modal
					className='importConfirmation'
					size='large'
					open={this.state.confirmOpen}
					onClose={() => this.setState({ confirmOpen: false, confirmed: false })} closeIcon
				>
					<Modal.Header>Import New Results</Modal.Header>
					<Modal.Content scrolling>
						{modalContent}
					</Modal.Content>
					<Modal.Actions>
						<Button
							className='cancelButton'
							onClick={() => this.setState({ confirmOpen: false, confirmed: false, fullTerms: false })}
						>
							No
						</Button>
						<Button
							className='confirmButton'
							icon='checkmark'
							labelPosition='right'
							content='Yes'
							disabled={!this.state.confirmed}
							onClick={this.uploadFilesAndCreateSample}
						/>
					</Modal.Actions>
				</Modal>
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user,
	accessCreds: state.accessCreds
})

ImportSample.propTypes = {
	history: PropTypes.objectOf(PropTypes.shape({
		push: PropTypes.func.isRequired
	})).isRequired
}.isRequired

export default connect(
	mapStateToProps
)(withRouter(ImportSample))
