import React, { Component } from 'react'
import { Breadcrumb, Grid, Form, Dropdown,TextArea, Button, Icon, Step, Select, Label, Input, Message } from 'semantic-ui-react'
import AppHeader from './AppHeader'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import constants from '../lib/constants'
import { connect } from 'react-redux'
import ImageDropzone from '../lib/ImageDropzone'
import ImageUploadAWS from '../lib/ImageUploadAWS'
import ImageCopyAWS from '../lib/ImageCopyAWS'
import cannidAPI from '../cannidAPI/client'

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user,
	accessCreds: state.accessCreds
  }
}
const ionLabs = 2
const maxLength = constants.FIELD_LENGTH.MAX_140
class CoaData extends Component {
  constructor(props){
    super(props)

    this.upload = React.createRef()
    this.state = {
      sample: undefined,
      analyteOptions: undefined,
      selectedOne: 'THC',
      selectedTwo: 'CBD',
      options: undefined,
      image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
      analystName: "",
      reviewerName: "",
      uploadFlag: false,
      imageError: undefined,
      coaAmount: '-- Not Selected --',
	  coaAmountOther: '',
      unitWeight: 0,
      CoaDes: undefined,
      CoaNotes:"",
      preFilledLogo: false,
      imageKey: undefined,
      coaPresent: false,
      preparedFor: undefined,
      consumers: [],
      consumerId: undefined,
      labIdNumber: "",
      success: undefined,
      errorCoa: undefined,
      emptyData: undefined,
      errorConsumers: undefined,
      errors:{}
    }
    this.awsLink = `https://${props.accessCreds.COA_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`
    this.sampleId = parseInt(this.props.match.params.id, 10)
    this.awsConf = {
      bucket: props.accessCreds.COA_BUCKET,
      accessKeyId: props.accessCreds.COA_AK,
      secretAccessKey: props.accessCreds.COA_SK
    }
    this.sampleId = parseInt(this.props.match.params.id, 10)
    this.resultsLink = `/sample/${this.sampleId}/results`
  }

  	componentDidMount(){
		const sampleRequest = new Request(process.env.REACT_APP_API_ROOT + '/samples/' + this.sampleId, {
		method: 'GET',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem('access_token')
		})
		})
		fetch(sampleRequest)
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
			throw new Error(response && response.statusText)
			}
			return response.json()
		})
		.then((sample) => {
			const compounds = Object.assign(sample.compounds, sample.derived_compounds)
			let compoundsArray = Object.keys(compounds)
			compoundsArray.sort()
			let analyteSelect = compoundsArray.map(x => {return {key: x, text: x, value: x }})
			const filteredOptions = this.amountsOptions.map(a => a.value)
			let formattedAmount = (sample.coa && sample.coa_data.coa_unit) ? sample.coa_data.coa_unit : '-- Not Selected --'
			let coaAmountOther = ''
			if (formattedAmount.indexOf('/' > -1)) {
				formattedAmount = formattedAmount.split('/').pop()
			}
			if (!filteredOptions.includes(formattedAmount)) {
				coaAmountOther = formattedAmount
				formattedAmount = 'other'
			}
			let consumerName = sample.coa ?
				sample.coa_data.consumer ? sample.coa_data.consumer.name : undefined : undefined

			let imagePrep = constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND
			if (sample.coa && sample.coa_data.sample_image_url) imagePrep = this.awsLink + "/" + sample.coa_data.sample_image_url
			else if (sample.intake_form && sample.intake_form.sample_image_url) imagePrep = sample.intake_form.sample_image_url

			let imageKeyPrep = constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND
			if (sample.coa && sample.coa_data.sample_image_url) imageKeyPrep = sample.coa_data.sample_image_url
			else if (sample.intake_form && sample.intake_form.sample_image_url) imageKeyPrep = sample.intake_form.sample_image_url

			let reviewer = sample.coa ? sample.coa_data.reviewer_id : undefined
			let currentUser = this.props.current_user ? this.props.current_user.name : ""

			let submissionId = ''
			if (sample.coa) submissionId = sample.coa_data.il_id
			else if (sample.intake_form && sample.intake_form.submission_identification) submissionId = sample.intake_form.submission_identification

			this.setState((prevState, props) => {
				return {
					sample: sample,
					analyteOptions: analyteSelect,
					analystName: sample.tested_by.name,
					reviewerName: reviewer ? reviewer : currentUser,
					selectedOne: sample.coa ? sample.coa_data.coa_analyte_1 : 'THC',
					selectedTwo: sample.coa ? sample.coa_data.coa_analyte_2 : 'CBD',
					image: imagePrep,
					imageKey: imageKeyPrep,
					coaAmount: formattedAmount,
					coaAmountOther: coaAmountOther,
					unitWeight: (sample.coa && sample.coa_data.coa_unit_weight) ? sample.coa_data.coa_unit_weight : 0,
					CoaDes: sample.coa ? sample.coa_data.coa_sample_description : "",
					CoaNotes: sample.coa ? sample.coa_data.coa_notes : "",
					preparedFor: consumerName,
					certus: sample.certus['sample_certus'] ? true : false,
					labIdNumber: submissionId,
					consumerId: (sample.coa_data && sample.coa_data.consumer) ? sample.coa_data.consumer.id : ""
				}
			})
		})
		.catch((e)=>{
			console.error('Error!', e)
			this.setState({errorCoa: "Failed to retrieve sample data, please refresh to try again."})
		})
  }
  componentDidUpdate(prevProps, prevState){
     const orgId = this.props.current_user.organization.id
    if(this.props.current_user && this.state.consumers.length == 0 && orgId === ionLabs){
      const consumers = new Request(process.env.REACT_APP_API_ROOT + '/consumers', {
        method: 'GET',
        headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        })
      })
      fetch(consumers)
      .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response && response.statusText)
      }
      return response.json()
      })
      .then((consumers) => {
        let consumerOptions = consumers.map( x => {return {key:x.id, text:x.name, value: x.id}}).sort((a, b) => {return a.text.localeCompare(b.text)})
        if (this.state.consumers.length === 0){
          this.setState({consumers: consumerOptions})
        }
      })
    }
  }

    uploadImage = async (image) => {
		try {
			let reader = new FileReader()
			if (image[0].size < constants.ACCOUNT.MAX_FILE_UPLOAD_SIZE) {
				if (image[0].name.match(/.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
					const awsUrl = await ImageUploadAWS(this.awsConf, image[0], this.sampleId)
					if (awsUrl) {
						reader.onload = (e) => {
							this.setState({
							image: [e.target.result],
							uploadFlag: true,
							imageError: undefined,
							imageKey: awsUrl
							})
						}
						reader.readAsDataURL(image[0])
					} else {
						this.setState({ imageError: `Image upload error. Please try again.` })
					}
				}
				else {
					this.setState({
						imageError: 'Error reading selected file. Acceptable image file types: .jpg|.jpeg|.png',
						uploadFlag: false
					})
				}
			} else {
				this.setState({ imageError: constants.ACCOUNT.IMAGE_LIMIT, uploadFlag: false })
			}
		} catch (e) {
			console.error('error in uploading image:', e)
		}
    }

    updateForm = (e) => {
      let target = e.target
    this.setState({ [target.name]: target.value }, () => { this.fieldValid(target.name) })
    }
    fieldValid = (field) => {
      if (this.state[field] !== '') {
        this.setState({
        [`${field}Error`]: false
        })
      }
    }
    checkAnalytes = () => {
      let error = false
      if(!this.state.selectedOne && !this.state.selectedTwo)
         error = true
      return error
    }
    checkTotal = () => {
      let error
      const uw = parseFloat(this.state.unitWeight)
      if (!this.state.unitWeight){
        error = 'Unit Weight cannot be empty'
      }
      if(uw <= 0 ){
        error = 'Unit Weight must be greater than 0'
      }
      return error
    }
    checkCoaDesc = () =>{
      let error
      if(!this.state.CoaDes)
        error = 'Please provide a COA Description'
      return error
    }
    checkPicture = () =>{
      let error
      if(this.state.image == constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND)
        error = true
      return error
    }
    checkLabIdnumber = () =>{
      let error = false
      if(!this.state.labIdNumber || this.state.labIdNumber.trim() === "")
        error = true
      return error
    }
    validateForm = () => {
      let error = {}
      let errorsPresent
      if (this.checkAnalytes()){
        error["selectedAnalyte"] = this.checkAnalytes()
      }

      if(this.state.coaAmount != '-- Not Selected --'){
        if (this.checkTotal()){
			error["unitWeight"] = this.checkTotal()
        }
		if (this.state.coaAmount === 'other' && (!this.state.coaAmountOther || this.state.coaAmountOther.trim() === "")) {
			error['coaAmountOther'] = 'If the selected COA Amount is "Other", Other COA Amount cannot be empty'
		}
      }

      if (this.checkCoaDesc()){
        error["CoaDes"] = this.checkCoaDesc()
      }
      if (this.checkPicture()){
        error["checkPicture"] = this.checkPicture()
      }
      if (this.checkLabIdnumber()){
        error["checkLabIdnumber"] = this.checkLabIdnumber()
      }
      this.setState({errors: error})

      errorsPresent = (Object.keys(error).length != 0)
      this.setState({emptyData: errorsPresent})
      return errorsPresent
    }

    removeImage = () => {
		if (this.state.preFilledLogo) {
			this.setState({ preFilledLogo: false })
		}
		this.setState({
				image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
				imageError: undefined,
				imageKey: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
				uploadFlag: false })
		this.upload.current.value = "" //Wild West way to clear the dom of the image
    }

    handleSubmit = async () => {
		const formErrors =  this.validateForm()
		let coaAmount = this.state.coaAmount
		if (coaAmount === '-- Not Selected --') {
			coaAmount = null
		}
		else if (coaAmount === 'other') {
			coaAmount = this.state.coaAmountOther
		}

		if(!formErrors){
			if (this.state.imageKey.includes('http')) {
				const copied = await ImageCopyAWS(this.awsConf, this.state.imageKey, this.sampleId).then((awsUrl) => {
					if (!awsUrl.error) {
						return this.setState({
							image: this.awsLink + "/" + awsUrl,
							uploadFlag: true,
							imageError: undefined,
							imageKey: awsUrl
						}, () => { return awsUrl; })
					}
					else {
						throw new Error(awsUrl.error)
					}
				})
				.catch((err) => {
					this.setState({errorCoa: "COA failed to update. Image upload error. Please try again.", success: undefined}),
					console.error('error!', err)
					return { error: err }
				})
				if (copied && copied.error) return
			}

			const analystId = this.state.sample ? this.state.sample.tested_by.id : ""
			const reviewerId = this.props.current_user ? this.props.current_user.id : ""
			let requestJson = {}
			requestJson = {
				coa_analyte_1: this.state.selectedOne,
				coa_analyte_2: this.state.selectedTwo,
				coa_unit: coaAmount,
				coa_unit_weight: this.state.coaAmount === '-- Not Selected --' ? null : this.state.unitWeight,
				coa_notes: this.state.CoaNotes,
				coa_sample_description: this.state.CoaDes,
				analyst_id: analystId,
				reviewer_id: reviewerId,
				sample_image_url: this.state.imageKey,
				consumer_id: this.state.consumerId,
				labIdNumber: this.state.labIdNumber,
				sample_image_url_full: this.awsLink + '/' + this.state.imageKey
			}
			const route = '/samples/' + this.sampleId + '/coa'
			cannidAPI.patch(route , requestJson).then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText)
				}
				this.setState({ success: "COA updated successfully", errorCoa: undefined, emptyData: undefined })
				// setTimeout(() => this.setState({ success: undefined }), 5000)
				this.props.history.push(this.resultsLink)
			})
			.catch((err) => {
				this.setState({errorCoa: "COA failed to update", success: undefined}),
				console.error('error!', err)}
			)
		} else {
			this.setState({success: undefined})
		}
    }
    clearStatusMessages = () =>{
      this.setState({ success: undefined,
                      errorCoa: undefined,
                      emptyData: undefined })
    }
	amountsOptions = [
		{
			key: '-- Not Selected --',
			text: '-- Not Selected --',
			value: '-- Not Selected --'
		},
		{
			key: 'bottle',
			text: 'Bottle',
			value: 'bottle'
		},
		{
			key: 'candy',
			text: 'Candy',
			value: 'candy'
		},
		{
			key: 'capsule',
			text: 'Capsule',
			value: 'capsule'
		},
		{
			key: 'edible',
			text: 'Edible',
			value: 'edible'
		},
		{
			key: 'gram',
			text: 'Gram',
			value: 'gram'
		},
		{
			key: 'gummy',
			text: 'Gummy',
			value: 'gummy'
		},
		{
			key: 'jar',
			text: 'Jar',
			value: 'jar'
		},
		{
			key: 'oz',
			text: 'oz',
			value: 'oz'
		},
		{
			key: 'piece',
			text: 'Piece',
			value: 'piece'
		},
		{
			key: 'softgel',
			text: 'Softgel',
			value: 'softgel'
		},
		{
			key: 'unit',
			text: 'Unit',
			value: 'unit'
		},
		{
			key: 'other',
			text: 'Other',
			value: 'other'
		}
	]
  render(){
    let breadcrumb
    const options = this.state.analyteOptions ? this.state.analyteOptions : []

    if (this.state.sample) {
      breadcrumb = (
        <Breadcrumb>
          <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
          <Breadcrumb.Divider icon="right angle" />
          <Link to={formatRoute(constants.APPLICATION_ROUTE.SAMPLE.LIST.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.SAMPLE.LIST.NAME}</Breadcrumb.Section></Link>
          <Breadcrumb.Divider icon="right angle" />
          <Link to={this.resultsLink}><Breadcrumb.Section>{this.state.sample.intake_form ? this.state.sample.intake_form.strain_name : 'Sample'}</Breadcrumb.Section></Link>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section>COA Data</Breadcrumb.Section>
        </Breadcrumb>
      )
    }

    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{constants.APPLICATION_ROUTE.SAMPLE.POPULATE_COA.NAME}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <div className="form screen">
				<Grid columns={2} centered stackable>
					<Grid.Row>
						<Grid.Column width={6}>
							<Form>
								<Form.Field>
									<label>Submission Identification &#x23;:</label>
									<Input
										disabled={this.state.certus}
										value={this.state.labIdNumber}
										placeholder='ILCTS12345'
										onChange={(event, data) => { this.setState({labIdNumber: data.value})}}
										error={this.state.errors.checkLabIdnumber}
										/>
									{this.state.errors.checkLabIdnumber && <Label basic prompt pointing='above'>Submission Identification # cannot be empty</Label>}
								</Form.Field>
								<Form.Field>
									<label>Selected:</label>
									<Select
										disabled={this.state.certus}
										placeholder={this.state.selectedOne ? this.state.selectedOne : 'THC'}
										options={options.filter( sel => sel.key != this.state.selectedTwo)}
										onChange={(event, data) => { this.setState({selectedOne: data.value})}}
										error={this.state.errors.selectedAnalyte}
										/>
									{this.state.errors.selectedAnalyte && <Label basic prompt pointing='above'>Must select an analyte</Label>}
								</Form.Field>
								<Form.Field>
									<label>Selected:</label>
									<Select
									disabled={this.state.certus}
									placeholder={this.state.selectedTwo ? this.state.selectedTwo : 'CBD'}
									options={options.filter( sel => sel.key != this.state.selectedOne)}
									onChange={(event, data) => { this.setState({selectedTwo: data.value})}}
									/>
								</Form.Field>
								<Form.Field>
									<label>Coa Amount: MG /</label>
									<Select
										disabled={this.state.certus}
										placeholder={this.state.coaAmount}
										options={this.amountsOptions}
										value={this.state.coaAmount}
										onChange={(event, data) => { this.setState({coaAmount: data.value})}}
										name="coaamount"
										error={this.state.errors.amountsOptions}
									/>
								</Form.Field>
								{this.state.coaAmount === 'other' &&
									<Form.Field>
										<label>Other Coa Amount:</label>
										<Input
											disabled={this.state.certus}
											placeholder='Container, Treat, Lollipop, etc.'
											value={this.state.coaAmountOther}
											onChange={(event, data) => { this.setState({coaAmountOther: data.value})}}
											name="coaAmountOther"
											error={this.state.errors.coaAmountOther}
										/> 
										{this.state.errors.coaAmountOther && <Label basic prompt pointing='above'>{this.state.errors.coaAmountOther}</Label>}
									</Form.Field> 
								}
								{this.state.coaAmount != '-- Not Selected --' &&
									<Form.Field>
										<label>Total Unit Weight (Grams):</label>
										<Input
											disabled={this.state.certus}
											placeholder='Grams'
											value={this.state.unitWeight}
											onChange={(event, data) => { this.setState({unitWeight: data.value})}}
											type="number"
											name="unitWeight"
											error={this.state.errors.unitWeight ? true : false}
									/> 
										{this.state.errors.unitWeight && <Label basic prompt pointing='above'>{this.state.errors.unitWeight}</Label>}
									</Form.Field> 
								}
								<Form.Field>
									<label>Prepared For:</label>
									<Dropdown
										search
										selection
										clearable
										disabled={this.state.certus}
										options={this.state.consumers}
										placeholder={this.state.preparedFor ? this.state.preparedFor : 'Client Name'}
										onChange={(event, data) => { this.setState({consumerId: data.value, preparedFor: event.target.innerText})}}
									/>
								</Form.Field>
							</Form>
						</Grid.Column>
						<Grid.Column width={6} verticalAlign='middle'>
							<Grid centered className='coaImage'>
								<ImageDropzone image={this.state.image} disabled={(this.state.uploadFlag || this.state.certus)}
									uploadImage={(files) => this.uploadImage(files)}
									imageError={(error) => this.setState({ imageError: error })}/>
							</Grid>
							<Grid centered style={{ marginTop: 0 }}>
								<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
								<input
									disabled={this.state.certus}
									type="file"
									accept="image/*"
									ref={this.upload}
									id="myAccountLogoForm"
									onChange={() =>{ this.uploadImage(this.upload.current.files)}}
								/>
								{this.state.imageError && <p className="error">{this.state.imageError}</p>}
								<Button icon labelPosition='left' primary={true} negative={false} size='small' disabled={(this.state.uploadFlag || this.state.certus) }
									onClick={() => this.upload.current.click()} style={{margin: '0 .25em'}}>
									<Icon name={'arrow alternate circle up'} />{'Upload'}
								</Button>
								<Button icon labelPosition='left' primary={false} negative={true} size='small' disabled={(!this.state.uploadFlag || this.state.certus)}
									onClick={() => this.removeImage()} style={{margin: '0 .25em'}}>
									<Icon name={'trash'} />{'Remove'}
								</Button>
								</Grid.Column>
								{this.state.errors.checkPicture && <Form><Form.Field><Label basic prompt pointing='above'>Please upload a sample photo</Label></Form.Field></Form>}
							</Grid>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={12}>
							<Form>
								<Form.TextArea
								disabled={this.state.certus}
								maxLength={maxLength}
								rows={2}
								placeholder='COA Sample Description (Required)'
								name='CoaDes'
								value={this.state.CoaDes}
								onChange={this.updateForm}
								error={this.state.errors.CoaDes} />
								</Form>
							<Grid.Row>
								{this.state.CoaDes ? <p className="charCounter">{this.state.CoaDes.length}/{maxLength} </p> : <p className="charCounter"> 0/{maxLength} </p>}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={12}>
							<Form>
							<Form.TextArea
								disabled={this.state.certus}
								maxLength={maxLength}
								rows={2}
								placeholder='COA Notes (leave blank if N/A)'
								name='CoaNotes'
								value={this.state.CoaNotes}
								onChange={this.updateForm}
								error={this.state.errors.CoaNotes} />
							</Form>
							<Grid.Row >
							{this.state.CoaNotes ? <p className="charCounter">{this.state.CoaNotes.length}/{maxLength}</p> : <p className="charCounter"> 0/{maxLength}</p>}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
					<Step.Group>
						<Step>
						<Icon name='thumbs up outline' size="mini"/>
							<Step.Content>
							<Step.Title>Reviewer Name</Step.Title>
							<Step.Description>{this.state.reviewerName}</Step.Description>
						</Step.Content>
						</Step>
						<Step>
						<Icon name='flask' size="mini"/>
							<Step.Content>
							<Step.Title>Analyst Name</Step.Title>
							<Step.Description>{this.state.analystName}</Step.Description>
						</Step.Content>
						</Step>
						{this.state.preparedFor && <Step>
						<Icon name='file alternate outline' size="mini"/>
							<Step.Content>
							<Step.Title>Prepared For</Step.Title>
							<Step.Description>{this.state.preparedFor}</Step.Description>
						</Step.Content>
						</Step>}
						{this.state.certus && <Step>
						<Icon name='lock' size="mini"/>
							<Step.Content>
							<Step.Title>Certus Present</Step.Title>
							<Step.Description>Editing not allowed</Step.Description>
						</Step.Content>
						</Step>}
					</Step.Group>
					</Grid.Row>
					<Grid.Row verticalAlign="top">
					<Grid.Column textAlign="center">
						{this.state.success && <Message positive >
						<p>{this.state.success}</p>
						</Message>}
						{this.state.errorCoa && <Message negative >
						<p>{this.state.errorCoa}</p>
						</Message>}
						{this.state.emptyData && <Message negative >
						<p>Please complete the required form fields</p>
						</Message>}
					</Grid.Column>
					</Grid.Row>
					<Grid.Row verticalAlign="top">
					<Grid.Column textAlign="center">
						{!this.state.certus && <Button icon labelPosition='left' primary size='small'
							onClick={() => {
							this.handleSubmit()
							}}>
							<Icon name='check' />Save
						</Button>}
					</Grid.Column>
					</Grid.Row>
				</Grid>
            </div>
          </section>
        </section>
      </div>)
  }
}
export default connect(mapStateToProps)(CoaData)

