import React, { Component } from 'react'
import { Route, Link, Switch } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import './Styles'
import './styles/Global.scss'
import './PrepMethods.css'
import './PrepMethodEdit.scss'
import './styles/Certus.scss'
import './styles/ChangelogPage.scss'
import './styles/ImportSample.scss'
import './styles/ImportCsvMapper.scss'
import './styles/QCResultsTable.scss'
import './styles/ReportMethods.scss'
import './styles/Headers.scss'
import './styles/Sidebar.scss'
import './styles/SampleResults.scss'
import './styles/Autosampler.scss'
import { startTimer } from './actions'
import Home from './components/Home'
import Settings from './components/Settings'
import SupportPage from './components/SupportPage/SupportPage'
import Export from './components/Export'
import SampleResults from './components/SampleResults/SampleResults'
import Login from './components/Login'
import ChemstationImport from './components/ChemstationImport'
import RawInjection from './components/RawInjection'
import ilLogo from './images/cannid-white-logo.png'
import { connect } from 'react-redux'
import { updateMachineState, getUser, updateSampleQueues, getAccess } from './actions'
import SharedQrDetails from './components/QrDetails/SharedQrDetails'
import CreateSampleTest from './components/CreateSampleTest/CreateSampleTest'
import ImportSample from './components/Import/ImportSample'
import ImportCsvMapper from './components/Import/ImportCsvMapper'
import ImportTemplates from './components/Import/ImportTemplates'
import ImportTemplateEdit from './components/Import/ImportTemplateEdit'
import ReportMethods from './components/ReportMethods'
import EditReportMethod from './components/EditReportMethod'
import CreateReportMethod from './components/CreateReportMethod'
import ListFormFields from './components/FormFields/ListFormFields'
import SampleTests from './components/SampleTests/SampleTests'
import CertusList from './components/Certus/CertusList'
import ProjectCertusCreate from './components/Certus/ProjectCertusCreate'
import ProjectCertus from './components/Certus/ProjectCertus'
import TransportationCertusCreate from './components/Certus/TransportationCertusCreate'
import TransportationCertus from './components/Certus/TransportationCertus'
import CertificateOfOriginCertusCreate from './components/Certus/CertificateOfOriginCertusCreate'
import CertificateOfOriginCertus from './components/Certus/CertificateOfOriginCertus'
import CreateFormField from './components/FormFields/CreateFormField'
import CreateUser from './components/Users/CreateUser'
import ResetPassword from './components/ResetPassword'
import ForgotPassowrd from './components/ForgotPassword'
import ChangePassword from './components/ChangePassword'
import UsersList from './components/Users/usersList'
import InviteUser from './components/Users/InviteUser'
import QCResults from './components/QCResults/QCResults'
import constants from './lib/constants'
import ViewDashboard from './components/GraphicalDashboard/ViewDashboard'
import AppHeader from './components/Headers/AppHeader'
import PrepMethodsPage from './components/PrepMethodsPage'
import CreateGraph from './components/GraphicalDashboard/CreateGraph'
import Autosampler from './components/Autosampler'
import CertusAppRedirect from './components/CertusAppRedirect'
import CoaData from './components/CoaData'
import {
  Image,
  Menu,
  Sidebar,
  Icon,
  Checkbox  
} from 'semantic-ui-react'
import ListFormTemplates from './components/FormTemplates/ListFormTemplates'
import CreateFormTemplate from './components/FormTemplates/CreateFormTemplate'
import PageNotFound from './components/PageNotFound'
import AclRoute from './components/ACL/AclRoute'
import Footer from './components/Footer'
import Account from './components/Account'
import PageNotAuthorized from './components/PageNotAuthorized'
import cannidAPI from './cannidAPI/client'
import csdriverAPI from './csdriverAPI/client'
import permissionCheck from './lib/permissionCheck'
import { of, from } from 'rxjs'
import { delay, tap, mergeMap, repeat } from 'rxjs/operators'


const mapStateToProps = (state) => {
  return {
    selected_tests: state.selected_tests,
    current_user: state.current_user,
    timer: state.timer,
    timerSeconds: state.timer.seconds,
    showQueue: state.showQueue,
    showSidebarMenu: state.showSidebarMenu,
    driverPolling: state.driverPolling,
    queuePaused: state.queuePaused,
    certusBankInfo: state.bank_info
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTimer: (timer) => {
    dispatch(startTimer(timer))
  },
  updateMachineState: (machineState) => {
    if (machineState.machine && machineState.machine.length > 4) {
      localStorage.setItem('machine', machineState.machine)
    }
    dispatch(updateMachineState(machineState))
  },
  updateResults: (results) => {
    dispatch({ type: 'UPDATE_RESULTS', results })
  },
  getUser: (token) => { dispatch(getUser(token)) },
  getAccess: () => { dispatch(getAccess()) },
  updateSampleQueues: (sampleQueues) => {
    dispatch(updateSampleQueues(sampleQueues))
  },
  toggleSampleQueue: () => {
    dispatch({ type: 'TOGGLE_SAMPLE_QUEUES' })
  },
  toggleSidebarMenu: () => {
    dispatch({type: 'TOGGLE_SIDEBAR_MENU'})
  },
  startDriverPolling: () => {
    dispatch({type: 'START_DRIVER_POLLING'})
  },
  stopDriverPolling: () => {
    dispatch({type: 'STOP_DRIVER_POLLING'})
  },
  pauseQueue: () => { dispatch({ type: 'PAUSE_QUEUE' }) }
})
class Routes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pollCount: 0,
      pollStatus: this.props.driverPolling ? 'Connecting...' : 'Disconnected'
    }
    this.machineStateObs = of({}).pipe(
      mergeMap(_ => this.fetchMachineState()),
      tap(this.logMachineState),
      delay(5000),
      repeat()
    )
  }

  fetchQueues = () => {
    return cannidAPI.get('/sample_queues').then((res) => {
      this.props.updateSampleQueues(res.data)
    }).catch((e) => { console.log('could not get queues'); })
  }

  fetchMachineState = () => {
    return from(csdriverAPI.get('/').then((res) => {
      return res.data
    }).catch((e) => { console.log('could not get queues'); }))
  }

  logMachineState = (machineState) => {
    if (machineState && Object.keys(machineState).length > 0) {
      this.props.updateMachineState(machineState)
    } else {
      // if poll count reaches 6 (6 x 5sec polls = 30 sec total), assume they're not
      // running a machine & stop polling
      console.log('poll count:', this.state.pollCount, 'of 6')
      if (this.state.pollCount >= 6) {
        this.setState({pollCount: 0, pollStatus: 'Disconnected'})
        this.props.stopDriverPolling()
        this.machineStateSub.unsubscribe()
      } else {
        this.setState({pollCount:  this.state.pollCount + 1})
      }
    }
  }

  componentDidMount() {
    if (localStorage.getItem('access_token') && !this.props.current_user) {
      this.props.getUser(localStorage.getItem('access_token'))
    }

    if (this.props.driverPolling &&
        this.props.location.pathname !== '/login') {
      this.machineStateSub = this.machineStateObs.subscribe()
    } else {
      this.setState({pollCount: 0, pollStatus: 'Disconnected'})
      this.props.stopDriverPolling()
    }
	this.props.getAccess()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.showQueue)
        this.props.toggleSampleQueue()
    }
    if (this.props.driverPolling && this.props.location.pathname === '/login') {
      this.setState({pollCount: 0, pollStatus: 'Disconnected'})
      this.props.stopDriverPolling()
    }
  }

  componentWillUnmount() {
    if (this.machineStateSub) {
      this.machineStateSub.unsubscribe()
    }
  }

  toggleDriverPolling = (turnoff) => {
    if (this.props.driverPolling || turnoff) {
      this.setState({pollCount: 0, pollStatus: 'Disconnected'})
      this.props.stopDriverPolling()
      if (this.machineStateSub) this.machineStateSub.unsubscribe()
      this.props.updateMachineState({})
    } else {
      this.setState({pollCount: 0, pollStatus: 'Connecting...'})
      this.props.startDriverPolling()
      this.machineStateSub = this.machineStateObs.subscribe()
    }
  }

  buildSideBar() {
    const { location, showSidebarMenu, toggleSidebarMenu } = this.props;
    let prepMethodMenuItem = null;
    let certusMenuItem = null;
    let sidebarHitTarget = null;

    
  
    if (localStorage.getItem('betaFeatures') === 'true') {
      prepMethodMenuItem = <Menu.Item as={Link} to='/prep-methods' onClick={toggleSidebarMenu}>Prep Methods</Menu.Item>;
    }
    if (permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL)) {
      certusMenuItem = <Menu.Item as={Link} to='/certus' onClick={toggleSidebarMenu} style={{ display: 'flex' }}>CERTUS<Icon style={{ marginLeft: 0 }} name='registered outline' size='tiny' /></Menu.Item>;
    }
    if (showSidebarMenu) {
      sidebarHitTarget = <div id='sidebarCloserHittarget' onClick={toggleSidebarMenu} />;
    }

    return (
      <>
        <Sidebar id='sidebarMenu' as={Menu} animation='overlay' inverted vertical visible={showSidebarMenu}>
            <Menu.Item as={Link} to='/'>
              <Image src={ilLogo} size='small' className='navLogo' />
              <small>v{process.env.REACT_APP_VERSION || 'ersionless'}</small>
            </Menu.Item>
			{!isMobile &&
				<Menu.Item>
					{/* <p style={{textAlign: 'center', color: 'rgba(255, 255, 255, 0.9)', margin: 0}}>Instrument</p> */}
					<Checkbox className='driverPollToggle'
						toggle
						checked={this.props.driverPolling}
						label={this.state.pollStatus}
						onChange={() => {this.toggleDriverPolling()}}/>
				</Menu.Item>
      		}

            <Menu.Item as={Link} to='/' active={location.pathname === '/'} onClick={toggleSidebarMenu}>Home</Menu.Item>
			<Menu.Item as={Link} to='/samples/report-methods' onClick={toggleSidebarMenu}>Report Methods</Menu.Item>
			{prepMethodMenuItem}
      		<Menu.Item as={Link} to='/test-result/create' onClick={toggleSidebarMenu}>Create New Test</Menu.Item>
			<Menu.Item as={Link} to='/samples/import' onClick={toggleSidebarMenu}>Import New Results</Menu.Item>
			<Menu.Item as={Link} to='/test-result/list' onClick={toggleSidebarMenu}>Sample Results</Menu.Item>
      		<Menu.Item as={Link} to='/qc/list' onClick={toggleSidebarMenu}>QC Results</Menu.Item>
			{certusMenuItem}
            <Menu.Item as={Link} to='/user/list' onClick={toggleSidebarMenu}>Users</Menu.Item>
            <Menu.Item as={Link} to='/support' onClick={toggleSidebarMenu}>Support</Menu.Item>
            <Menu.Item as={Link} to='/settings' onClick={toggleSidebarMenu}>Settings</Menu.Item>
          </Sidebar>
          {sidebarHitTarget}
        </>
    );
  }

  buildCertusRoutes() {
    const certusTitle = constants.ACL.CERTUS.TITLE;
    const certusActions = constants.ACL.CERTUS.ACTIONS;

    return [
      <AclRoute exact key='/certus' path='/certus' auth={true} entity={certusTitle} action={certusActions.VERISEAL} component={(props) => <CertusList {...props} />} />,
      <AclRoute exact key='/certus/project/create' path='/certus/project/create' auth={true}  entity={certusTitle} action={certusActions.PROJECT} component={(props) => <ProjectCertusCreate {...props} />} />,
      <AclRoute exact key='/certus/project/:id' path='/certus/project/:id' auth={true} entity={certusTitle} action={certusActions.VERISEAL} component={(props) => <ProjectCertus {...props} />} />,
      <AclRoute exact key='/certus/transportation/create' path='/certus/transportation/create' auth={true}  entity={certusTitle} action={certusActions.TRANSPORTATION} component={(props) => <TransportationCertusCreate {...props} />} />,
      <AclRoute exact key='/certus/transportation/:id' path='/certus/transportation/:id' auth={true} entity={certusTitle} action={certusActions.VERISEAL} component={(props) => <TransportationCertus {...props} />} />,
      <AclRoute exact key='/certus/certificate/create' path='/certus/certificate/create' auth={true}  entity={certusTitle} action={certusActions.CERTIFICATE} component={(props) => <CertificateOfOriginCertusCreate {...props} />} />,
      <AclRoute exact key='/certus/certificate/:id' path='/certus/certificate/:id' auth={true} entity={certusTitle} action={certusActions.CERTIFICATE} component={(props) => <CertificateOfOriginCertus {...props} />} />,
    ];
  }

  buildSampleRoutes() {
    const sampleConst = constants.APPLICATION_ROUTE.SAMPLE;
	const qcConst = constants.APPLICATION_ROUTE.QC;
    const aclConst = constants.ACL.SAMPLE_RESOURCE;

    return [
    //   <AclRoute key={sampleConst.REPORT.ROUTE} exact path={sampleConst.REPORT.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.SHOW_REPORT} component={(props) => <Sample {...props} />} />,
		<AclRoute key={sampleConst.REPORT.ROUTE} exact path={sampleConst.REPORT.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.SHOW_REPORT} component={(props) => <SampleResults {...props} />} />,
		<AclRoute key={sampleConst.LIST.ROUTE} exact path={sampleConst.LIST.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.LIST} component={(props) => <SampleTests {...props} />} />,
		<AclRoute key={sampleConst.IMPORT_CSV.ROUTE} exact path={sampleConst.IMPORT_CSV.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.CREATE} component={(props) => <ImportSample {...props} />} />,
		<AclRoute key={sampleConst.IMPORT_CSV_MAPPER.ROUTE} exact path={sampleConst.IMPORT_CSV_MAPPER.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.CREATE} component={(props) => <ImportCsvMapper {...props} />} />,
		<AclRoute key={sampleConst.IMPORT_TEMPLATES.ROUTE} exact path={sampleConst.IMPORT_TEMPLATES.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.EDIT} component={(props) => <ImportTemplates {...props} />} />,
		<AclRoute key={sampleConst.IMPORT_TEMPLATE_EDIT.ROUTE} exact path={sampleConst.IMPORT_TEMPLATE_EDIT.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.EDIT} component={(props) => <ImportTemplateEdit {...props} />} />,
		<AclRoute key={sampleConst.POPULATE_COA.ROUTE} exact path={sampleConst.POPULATE_COA.ROUTE} auth={true} entity={sampleConst.POPULATE_COA.TITLE} action={sampleConst.POPULATE_COA.ACTIONS.UPDATE_COA} component={(props) => <CoaData {...props} />} />,
		<AclRoute key={qcConst.LIST.ROUTE} exact path={qcConst.LIST.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.LIST} component={(props) => <QCResults {...props} />} />,
		<AclRoute key={sampleConst.CREATE.ROUTE} exact path={sampleConst.CREATE.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.CREATE} component={(props) => <CreateSampleTest {...props} />} />,
		<AclRoute key={sampleConst.EDIT.ROUTE} exact path={sampleConst.EDIT.ROUTE} auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.EDIT} component={(props) => <CreateSampleTest {...props} />} />,
		<AclRoute key={'/sample(s?)/:id/import'} exact path='/sample(s?)/:id/import' auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.CREATE} component={(props) => <ChemstationImport {...props} />} />,
		<AclRoute key={'/sample(s?)/:id/vial/:index'} exact path='/sample(s?)/:id/vial/:index' auth={true} entity={aclConst.TITLE} action={aclConst.ACTIONS.LIST} component={(props) => <RawInjection {...props} />} />,
    ];
  }

  buildReportRoutes() {
    const reportMethodConst = constants.APPLICATION_ROUTE.REPORT_METHOD;
    const reportResourceConst = constants.ACL.REPORT_METHOD_RESOURCE;

    return [
      <AclRoute key={reportMethodConst.LIST.ROUTE} exact path={reportMethodConst.LIST.ROUTE} auth={true} entity={reportResourceConst.TITLE} action={reportResourceConst.ACTIONS.LIST} component={(props) => <ReportMethods {...props} />} />,
      <AclRoute key={reportMethodConst.EDIT.ROUTE} exact path={reportMethodConst.EDIT.ROUTE} auth={true} entity={reportResourceConst.TITLE} action={reportResourceConst.ACTIONS.EDIT} component={(props) => <EditReportMethod {...props} />} />,
      <AclRoute key={reportMethodConst.CREATE.ROUTE} exact path={reportMethodConst.CREATE.ROUTE} auth={true} entity={reportResourceConst.TITLE} action={reportResourceConst.ACTIONS.CREATE} component={(props) => <CreateReportMethod {...props} />} />
    ];
  }

  buildBatchRoutes() {
    return [
      <AclRoute key={constants.APPLICATION_ROUTE.BATCH_TEST.LIST.ROUTE} exact path={constants.APPLICATION_ROUTE.BATCH_TEST.LIST.ROUTE} auth={true} entity={constants.ACL.BATCH_RESOURCE.TITLE} action={constants.ACL.BATCH_RESOURCE.ACTIONS.LIST} component={(props) => <BatchTests {...props} />} />,
      <AclRoute key={constants.APPLICATION_ROUTE.BATCH.EDIT.ROUTE} exact path={constants.APPLICATION_ROUTE.BATCH.EDIT.ROUTE} auth={true} entity={constants.ACL.BATCH_RESOURCE.TITLE} action={constants.ACL.BATCH_RESOURCE.ACTIONS.EDIT} component={(props) => <CreateBatchForm {...props} />} />
    ];
  }

  buildAnalyticalRoutes() {
    const analyticReportConst = constants.APPLICATION_ROUTE.ANALYTICAL_REPORT;
    const graphResourceConst = constants.ACL.GRAPH_DASHBOARD_RESOURCE;

    return [
      <AclRoute key={analyticReportConst.LIST.ROUTE} exact path={analyticReportConst.LIST.ROUTE} auth={true} entity={graphResourceConst.TITLE} action={graphResourceConst.ACTIONS.LIST} component={(props) => <ViewDashboard {...props} />} />,
      <AclRoute key={analyticReportConst.CREATE.ROUTE} exact path={analyticReportConst.CREATE.ROUTE} auth={true} entity={graphResourceConst.TITLE} action={graphResourceConst.ACTIONS.CREATE} component={(props) => <CreateGraph {...props} />} />,
      <AclRoute key={analyticReportConst.EDIT.ROUTE} exact path={analyticReportConst.EDIT.ROUTE} auth={true} entity={graphResourceConst.TITLE} action={graphResourceConst.ACTIONS.CREATE} component={(props) => <CreateGraph {...props} />} />,
      <AclRoute key={analyticReportConst.VIEW.ROUTE} exact path={analyticReportConst.VIEW.ROUTE} auth={true} entity={graphResourceConst.TITLE} action={graphResourceConst.ACTIONS.LIST} component={(props) => <ViewDashboard {...props} />} />,
    ];
  }

  buildFormRoutes() {
    const formTemplateConst = constants.APPLICATION_ROUTE.FORM_TEMPLATE;
    const formResourceConst = constants.ACL.FORM_TEMPLATE_RESOURCE;

    return [
      <AclRoute key={formTemplateConst.LIST.ROUTE} exact path={formTemplateConst.LIST.ROUTE} auth={true} entity={formResourceConst.TITLE} action={formResourceConst.ACTIONS.LIST} component={(props) => <ListFormTemplates {...props} />} />,
      <AclRoute key={formTemplateConst.CREATE.ROUTE} exact path={formTemplateConst.CREATE.ROUTE} auth={true} entity={formResourceConst.TITLE} action={formResourceConst.ACTIONS.CREATE} component={(props) => <CreateFormTemplate {...props} />} />,
      <AclRoute key={formTemplateConst.EDIT.ROUTE} exact path={formTemplateConst.EDIT.ROUTE} auth={true} entity={formResourceConst.TITLE} action={formResourceConst.ACTIONS.EDIT} component={(props) => <CreateFormTemplate {...props} />} />
    ];
  }

  buildFormFieldRoutes() {
    const fieldResourceConst = constants.ACL.FORM_FIELD_RESOURCE;

    return [
      <AclRoute key={'/form-fields'} exact path='/form-fields' entity={fieldResourceConst.TITLE} auth={true} action={fieldResourceConst.ACTIONS.LIST} component={(props) => <ListFormFields {...props} />} />,
      <AclRoute key={'/form-fields/create'} exact path='/form-fields/create' entity={fieldResourceConst.TITLE} auth={true} action={fieldResourceConst.ACTIONS.CREATE} component={(props) => <CreateFormField {...props} />} />,
      <AclRoute key={'/form-fields/:id/edit'} exact path='/form-fields/:id/edit' entity={fieldResourceConst.TITLE} auth={true} action={fieldResourceConst.ACTIONS.EDIT} component={(props) => <CreateFormField {...props} />} />
    ];
  }

  buildUserRoutes() {
    const userConst = constants.APPLICATION_ROUTE.USER;
    const userResourceConst = constants.ACL.USER_RESOURCE;

    return [
      <AclRoute key={userConst.LIST.ROUTE} exact path={userConst.LIST.ROUTE} auth={true} entity={userResourceConst.TITLE} action={userResourceConst.ACTIONS.LIST} component={(props) => <UsersList id={this.props.current_user && this.props.current_user.id} history={this.props.history} />} />,
      <AclRoute key={userConst.CREATE.ROUTE} exact path={userConst.CREATE.ROUTE} auth={true} entity={userResourceConst.TITLE} action={userResourceConst.ACTIONS.CREATE} component={(props) => <CreateUser {...props} />} />,
      <AclRoute key={userConst.CHANGE_PASSWORD.ROUTE} exact path={userConst.CHANGE_PASSWORD.ROUTE} auth={true} entity={userResourceConst.TITLE} action={userResourceConst.ACTIONS.CHANGE_PASSWORD} component={() => <ChangePassword id={this.props.current_user && this.props.current_user.id} history={this.props.history} />} />,
      <AclRoute key={userConst.EDIT.ROUTE} exact path={userConst.EDIT.ROUTE} auth={true} entity={userResourceConst.TITLE} action={userResourceConst.ACTIONS.EDIT} component={(props) => <CreateUser {...props} />} />,
      <AclRoute key={userConst.SIGN_UP.ROUTE} exact path={userConst.SIGN_UP.ROUTE} auth={false} component={(props) => <InviteUser {...props} />} />
    ];
  }

  buildRedirectRoutes() {
    return [
      <Route key={constants.APPLICATION_ROUTE.CERTUS_QR_REDIRECTION.ROUTE} exact path={constants.APPLICATION_ROUTE.CERTUS_QR_REDIRECTION.ROUTE} component={(props) => <CertusAppRedirect {...props} />} />,
      <Route key={constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE} exact path={constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE} component={PageNotAuthorized} />,
      <Route key={constants.APPLICATION_ROUTE.QR_REDIRECTION.ROUTE} exact path={constants.APPLICATION_ROUTE.QR_REDIRECTION.ROUTE} component={SharedQrDetails} />
    ];
  }

  buildMiscApplicationRoutes() {
    const supportRouteConfig = constants.APPLICATION_ROUTE.SUPPORT
    const supportPagePaths = [supportRouteConfig.TICKET.ROUTE, supportRouteConfig.ORDERING.ROUTE, supportRouteConfig.CHANGE_LOG.ROUTE];

    return [
      <Route key={constants.APPLICATION_ROUTE.SETTINGS.ROUTE} exact path={constants.APPLICATION_ROUTE.SETTINGS.ROUTE} auth={true} component={(props) => <Settings {...props} />} />,
      <AclRoute key={constants.APPLICATION_ROUTE.ACCOUNT.ROUTE} exact path={constants.APPLICATION_ROUTE.ACCOUNT.ROUTE} auth={true} entity={constants.ACL.USER_RESOURCE.TITLE} action={constants.ACL.USER_RESOURCE.ACTIONS.MY_ACCOUNT} component={(props) => <Account {...props} />} />,
      <AclRoute key={constants.APPLICATION_ROUTE.PAGE_NOT_FOUND.ROUTE} exact path={constants.APPLICATION_ROUTE.PAGE_NOT_FOUND.ROUTE} auth={false} component={(props) => <PageNotFound {...props} />} />,
      <AclRoute key={constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE} exact path={constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE} auth={false} component={(props) => <ForgotPassowrd {...props} />} />,
      <AclRoute key={constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE} exact path={constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE} auth={false} component={(props) => <ResetPassword {...props} />} />,

      <Route key={'/login'} exact path='/login' auth={false} component={(props) => <Login {...props} />} />,
      <Route key={'/export'} exact path='/export' component={Export} />,
      <Route key={'/prep-methods'} exact path='/prep-methods' auth={true} component={(props) => <PrepMethodsPage {...props} />} />,
      <Route key={'/support'} exact path={supportPagePaths} auth={true} component={(props) => <SupportPage {...props} />} />
    ];
  }


  render() {
    const pathname = this.props.location.pathname;
    let authorizedRoutes = null;

    if (pathname !== constants.APPLICATION_ROUTE.PAGE_NOT_FOUND.ROUTE && pathname !== constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE) {
      authorizedRoutes = (
        <div id='routesRoot'>
          {this.buildSideBar()}
          {localStorage.getItem('access_token') && <AppHeader />}
          {(localStorage.getItem('access_token') && !isMobile) && <Route path='/' auth={true} component={(props) => <Autosampler fetchQueues={this.fetchQueues} {...props} setDriverConnected={() => this.setState({pollCount: 0, pollStatus: 'Connected'})} pollStatus={this.state.pollStatus} disconnectDriver={() => this.toggleDriverPolling(true)} />} />}
          <Switch>
            <Route exact path={constants.APPLICATION_ROUTE.HOME.ROUTE} auth={true} render={(props) => <Home fetchQueues={this.fetchQueues} {...props} />} />
            {this.buildCertusRoutes()}
            {this.buildSampleRoutes()}
            {this.buildReportRoutes()}
            {this.buildBatchRoutes()}
            {this.buildAnalyticalRoutes()}
            {this.buildFormRoutes()}
            {this.buildFormFieldRoutes()}
            {this.buildUserRoutes()}
            {this.buildMiscApplicationRoutes()}
            {this.buildRedirectRoutes()}
            <Route component={PageNotFound} />
          </Switch>
          <Footer />
        </div>
      );
    }
    else {
      authorizedRoutes = (
        <div>
          <Switch>
            <Route exact path={constants.APPLICATION_ROUTE.PAGE_NOT_FOUND.ROUTE} component={PageNotFound} />
            <Route exact path={constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE} component={PageNotAuthorized} />
          </Switch>
        </div>
      );
    }



    return (
      <React.Fragment>
        <div id='modal' style={{ display: 'none' }}></div>
        {authorizedRoutes}
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
