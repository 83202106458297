import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { uploadFile } from 'react-s3'
import { Segment, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
	return {
		accessCreds: state.accessCreds
	}
}

function ChromeUploader(props) {
  // use result uuid to ensure unique key prefix for upload preventing s3 overwrite
  const { accessCreds } = props
  const config = {
    bucketName: accessCreds.CHROMATOGRAPHS_BUCKET,
    region: process.env.REACT_APP_AWS_REGION,
    dirName: `${props.result.uuid}/import`,
    accessKeyId: accessCreds.CHROMATOGRAPHS_AK,
    secretAccessKey: accessCreds.CHROMATOGRAPHS_SK
  }

  const [error, setError] = useState(null)

  const onDrop = useCallback(acceptedFiles => {
    console.log('dropped file:', acceptedFiles)
    // new drop, clear the errors
    props.functionalError({})
    setError(null)

    if (acceptedFiles[0] && acceptedFiles[0].name.match(/.*\.(csv|CSV)$/)) {
      const reader = new FileReader()
      reader.onabort = () => props.functionalError("Error: chromatogram file reading was aborted.")
      reader.onerror = (e) => props.functionalError(`Error reading the chromatogram file. ${e}`)
      reader.onload = () => {
        // file contents! :)
        const text = reader.result
        let reportSplit = text.split(/\r\n/)
        if (reportSplit.length <= 1) {
          reportSplit = text.split(/\n/)
        }
        if (reportSplit[0].split(',').length > 2) {
          setError("Too many columns in chromatogram file. Expected 2.")
          props.functionalError("Too many columns in chromatogram file. Expected 2.")
          return
        }

        let maxRt = 0
        reportSplit.forEach((line, index) => {
          // logging breakdown verification of values in fields for potential future error handling.
          // currently just notes if these values are bad. may want to prevent upload if they will 
          // end up breaking other features (i.e. chart displays). currently accept them anyway.
          if (!parseFloat(line.split(',')[0]) || !parseFloat(line.split(',')[1])) {
            console.log(`line ${index}, bad value in "${line}", decimal converts to "${parseFloat(line.split(',')[0])},${parseFloat(line.split(',')[1])}"`)
          } else if (parseFloat(line.split(',')[0]) && parseFloat(line.split(',')[0]) > maxRt) {
            maxRt = parseFloat(line.split(',')[0])
          }
        })

        // use maxRt RT value to presume the units. method duration > 180 min (3 hrs) doesn't seem
        // realistic so assume seconds. simultaneously, method duration < 180 sec (3 min) isn't
        // realistic so assume minutes.
        const units = maxRt > 180 ? 'Seconds' : 'Minutes'
        uploadFile(acceptedFiles[0], config).then((data) => {
          props.updateResult({chromeUrl: data.location, chromeRtUnits: units})
          props.functionalError({})
        }).catch((err) => {
          console.error('we hit an error', err)
          setError("Error uploading chromatogram CSV")
          props.functionalError(`Error uploading chromatogram CSV. ${err}`)
        })

      }
      reader.readAsText(acceptedFiles[0])
    } else {
      setError("File type must be CSV")
      props.functionalError("File type must be CSV")
    }
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})

  return (
    <div className='dropZone' {...getRootProps()} style={{padding: '0', margin: '20px 10px'}}>
		<Segment placeholder inverted color='grey'>
			<Header icon size='small' style={{margin: '0'}}>
				<img src='https://cdn.chemid.com/images/dragon_drop/dragon_drop_head_chemid_479x479.png' alt='ChemID Dragon Drop'
					className='dragonDrop'
					style={{marginBottom: '1em'}} />
				<br />
				{!isDragActive ? <span>Upload or <i>Dragon Drop</i> the injection chromatogram CSV here</span> : 'Drop to upload!'}
				{error && <div className='zone inactive error'>{error}</div>}
			</Header>
			<input {...getInputProps()} />
		</Segment>
    </div>
  )
}

export default connect(mapStateToProps)(ChromeUploader)
