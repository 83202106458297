import React, { Component } from 'react'
import { Breadcrumb, Button, Form, Grid, Segment, Modal, Message, Popup, Card, Header, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import AppHeader from '../AppHeader'
import PreparedSamplesInput from '../PreparedSamplesInput'
import ToastMessage from '../Message'
import { get } from 'lodash'
import moment from 'moment'
import $ from 'jquery'
import { SampleTestServices } from '../../services/SampleTest'
import constants from '../../lib/constants'
import trimWhiteSpace from '../../lib/trim'
import dateFormatterUtcString from '../../lib/dateFormatterUtcString'
import ErrorMessages from '../Errors/ErrorMessages'
import { formatRoute } from 'react-router-named-routes'
import decodeJWT from '../../lib/decodeJWT'
import intakeNumber from '../../lib/intakeNumber'
import ImageUploadAWS from '../../lib/ImageUploadAWS'
import ImageDropzone from '../../lib/ImageDropzone'
import { connect } from 'react-redux'
import cannidAPI from '../../cannidAPI/client'

const mapStateToProps = (state) => {
  return {
    sampleQueues: state.sampleQueues,
    machineState: state.machineState,
	user: state.current_user,
	accessCreds: state.accessCreds
  }
}

function buildQCPrepedSample(label) {
	return { acquire: true, dilution_factor: 1, label, vial_type: 'red_1ml' }
}
const QC_SAMPLE_TYPE_PREPED_SAMPLES_MAP = {}
const QC_SAMPLE_TYPE_SLUGS = [constants.SAMPLE_TYPE.STANDARD, constants.SAMPLE_TYPE.CALIBRATION]

QC_SAMPLE_TYPE_PREPED_SAMPLES_MAP[constants.SAMPLE_TYPE.STANDARD] = [buildQCPrepedSample('10\nug/ml')]
QC_SAMPLE_TYPE_PREPED_SAMPLES_MAP[constants.SAMPLE_TYPE.CALIBRATION] = [
	buildQCPrepedSample('1\nug/ml'),
	buildQCPrepedSample('5\nug/ml'),
	buildQCPrepedSample('10\nug/ml'),
	buildQCPrepedSample('25\nug/ml'),
	buildQCPrepedSample('50\nug/ml'),
]

class CreateSampleTest extends Component {
  constructor(props, context) {
	super(props, context)
	this.upload = React.createRef()
	this.awsConf = {
		bucket: props.accessCreds.COA_BUCKET,
		accessKeyId: props.accessCreds.COA_AK,
		secretAccessKey: props.accessCreds.COA_SK
	}
	this.awsLink = `https://${props.accessCreds.COA_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`
	this.fromQC = (props.location.state && props.location.state.fromQC === true) ? true : false
	this.state = this.initialState(this.fromQC)
  }

  initialState = (isQCSample = false) => {
	return {
		preEditNotes: '',
		sample_test_created: null,
		sample_test_updated: null,
		sample: {
			intake_number: intakeNumber(),
			strain_name: '',
			sample_weight: isQCSample ? '1' : '',
			machine_name: localStorage.getItem('machine'),
			notes: '',
			post_notes:'',
			batch_number: '',
			submission_identification: '',
			prepared_for: ''
		},
			sample_type: constants.SAMPLE_TYPE.STANDARD,
			calibration: constants.SAMPLE_TYPE.CALIBRATION,
			form_errors: {},
			prepared_samples: [],
			custom_fields: [],
			custom_fields_updated: [],
			custom_fields_errors: false,
			custom_fields_error: false,
			is_submitted: false,
			apiErrors: {},
			sample_types: [],
			sample_type_id: null,
			test_method_id: null,
			test_methods: [],
			isQCSample,
			is_mobile: false,
			sample_recommendations: {},
			isFormEditable: true,
			role: '',
			weightError: false,
			sampleWeightFlag: false,
			prep_method_id: isQCSample ? 0 : null, // 0 for custom
			prep_methods: [],
			confirmButtonText: isQCSample ? 'Create QC Test' : 'Create Sample Test',
			notEditableExcuse: '',
			location: [],
			image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
			imageError: undefined,
			uploadFlag: false
		}
  }

  resetForm = (isQCSample) => {
    this.setState({
      ...this.initialState(isQCSample)
    }, () => {
      this.loadRemoteFields()
    })
  }

  // TODO: Remove unused is_mobile state property
  resize = () => {
    if (window.screen.width < 426)
      this.setState({ is_mobile: true })
    else {
      this.setState({ is_mobile: false })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.loadRemoteFields()
   
  }
 
  addPreppedSample = (preppedSample) => {
    let preppedSoFar = [...this.state.prepared_samples]
    preppedSoFar.push(preppedSample)
    this.setState({prepared_samples: preppedSoFar})
  }

  addNav = () =>{
  let nav =  navigator.geolocation.getCurrentPosition(this.position)
  }

  position = (position) => {
    var latitude =   position.coords.latitude
    var longitude =  position.coords.longitude
    this.setState({location: [latitude,longitude]})
    }

  loadRemoteFields = () => {
    cannidAPI.get('/prep_methods').then((res) => {
      	this.setState({prep_methods: res.data})
    }).catch((err) => {
      	console.error("couldn't get prep methods:")
     	 console.error(err)
    })
    cannidAPI.get('/methods').then((res) => {
		const defaultQcMethod = res.data.filter(t => t.id === 1 || t.override_method_id)[0]
		const qc_default_id = (this.state.isQCSample && defaultQcMethod && res.data[0].id !== defaultQcMethod.id) ? defaultQcMethod.id : null

      	this.setState({test_methods: res.data, test_method_id: qc_default_id ? qc_default_id : res.data[0].id})
    }).catch((err) => {
      	console.error("couldn't get report methods:")
      	console.error(err)
    })
    this.resize()
    this.getSampleTypes()
    let decodedResponse = decodeJWT(localStorage.getItem('access_token'))
    this.setState({ role: decodedResponse.role.slug })
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.resetForm()
      
    }
    let arr = this.state.location
      if( arr.length  === 0  || arr == null){
        this.addNav()
      }
  }

  getSampleDetails = () => {
    if (!this.isSampleNew()) {
      SampleTestServices.getSample(this.props.match.params.id).then((res) => {
        let sampleObject = {}
        if (res && res.data) {
          let excuse = ''
          let editable = true
          let response = res.data
          let queueIds = this.props.sampleQueues.filter((item) => {if (item.sample) {return item.sample.id}}).map((s) => {return s.sample.id})
          if (queueIds.includes(response.id)) {
            excuse = 'Samples present in the sample queue cannot be edited. Remove this sample from the sample queue to perform edits.'
            editable = false
          } else if (response.results && response.results.length > 0) {
            excuse = 'Samples with test results cannot be edited.'
            editable = false
          }
          
          const sampleRecommendations = (response.sample_type && response.sample_type.id ? ((this.state.sample_types.filter(type => type.value === response.sample_type.id)) ? this.state.sample_types.filter(type => type.value === response.sample_type.id)[0] : {}) : {})
		  let isQCSample = false
		  if (sampleRecommendations && QC_SAMPLE_TYPE_SLUGS.includes(sampleRecommendations.slug)) {
			isQCSample = true
		  }

          sampleObject = {
            intake_number: response.intake_form && response.intake_form.intake_number ? response.intake_form.intake_number : intakeNumber(),
            strain_name: response.intake_form && response.intake_form.strain_name,
            sample_weight: response.intake_form && response.intake_form.sample_weight ? response.intake_form.sample_weight : '750',
            notes: response.intake_form && response.intake_form.notes,
            post_notes: response.intake_form && response.intake_form.post_notes,
            batch_number: (response.intake_form && response.intake_form.batch_number) ? response.intake_form.batch_number : '',
			submission_identification: (response.intake_form && response.intake_form.submission_identification)
				? response.intake_form.submission_identification : '',
			prepared_for: (response.intake_form && response.intake_form.prepared_for) ? response.intake_form.prepared_for : ''
          }
		  // if editing and notes already exist, set aside for later
		  let preEditNotes = (response.intake_form && response.intake_form.notes && this.props.match.params.id)
		  	? response.intake_form.notes : this.state.preEditNotes

		  const sampleImagePresent = (response.intake_form && response.intake_form.sample_image_url) ? true : false
		  if (sampleImagePresent) sampleObject['sample_image_url'] = response.intake_form.sample_image_url

          this.setState({
			preEditNotes: preEditNotes,
            sample: sampleObject,
            test_method_id: response.test_method.id,
            custom_fields: (response.intake_form.custom_fields) ? response.intake_form.custom_fields : [],
            sample_type_id: (response.sample_type && response.sample_type.id) ? response.sample_type.id : null,
            sample_type: (response.sample_type && response.sample_type.name) ? response.sample_type.name : '',
            sample_recommendations: sampleRecommendations ? sampleRecommendations : {},
            isFormEditable: editable,
            prepared_samples: (response.prepared_samples && response.prepared_samples.length > 0) ? response.prepared_samples : [],
            confirmButtonText: 'Save Edits',
            notEditableExcuse: excuse,
			image: sampleImagePresent ? `${response.intake_form.sample_image_url}`
				: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
			imageError: undefined,
			uploadFlag: sampleImagePresent ? true : false,
			isQCSample,
            apiErrors: {}
          }, () => this.setPrepMethod(null, {value: response.prep_method_id})) // after state update, simulate prep method selection sample's with prep_method_id
          if (!constants.USER_ROLE.ADMIN_ROLE_SLUGS.includes(this.state.role) && (response.intake_form && response.intake_form.sample_weight && response.intake_form.sample_weight !== null)) {
            this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE, {}))
          }
        }
      }).catch((error) => {
        this.setState({ apiErrors: (error.data) ? error.data : {} })
      })
      }
    }

  getSampleTypes = () => {
    SampleTestServices.getAllSampleTypes().then((response) => {
      let items = response.data.data.map((item, key) => {
        let var1 = { key: item.id, text: item.name, value: item.id, recommended_size: item.recommended_size, rsd_percent: item.rsd_percent, slug: item.slug, rank: item.rank, itemwtf: item }
        return var1
      })
      items.sort((a,b) => ( a.rank - b.rank ))
      this.setState({ sample_types: items, apiErrors: {} }, () => { this.getSampleDetails() })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {} })
    })
  }

  validateForm = () => {
    let tempFormError = {}
    // if (!this.state.sample.intake_number || !trimWhiteSpace(this.state.sample.intake_number).length) {
    //   tempFormError.intake_number = "Please enter an Intake Number"
    // }
    const re = /^[\w\d\s-_]+$/
    if (!re.test(this.state.sample.strain_name)) {
      tempFormError.strain_name = "Strain/Sample Name can only contain letters, numbers, spaces, dashes, and underscores."
    }
    if (!trimWhiteSpace(this.state.sample.strain_name) || !trimWhiteSpace(this.state.sample.strain_name).length || trimWhiteSpace(this.state.sample.strain_name).length > 50) {
      tempFormError.strain_name = "Please enter a Strain/Sample Name. 50 characters max length."
    }
    if (trimWhiteSpace(this.state.sample.sample_weight) === '' || isNaN(this.state.sample.sample_weight) || parseFloat(this.state.sample.sample_weight) < 0) {
      tempFormError.sample_weight = "Please enter a Sample Weight"
    }
    if (this.state.custom_fields_errors === true) {
      this.setState({ 'custom_fields_error': true, 'is_submitted': true })
      tempFormError.customFieldError = 'Custom Field Error!'
    } else {
      this.setState({ 'custom_fields_error': false, 'is_submitted': false })
    }
    if (!trimWhiteSpace(this.state.sample.batch_number) || !trimWhiteSpace(this.state.sample.batch_number).length) {
      tempFormError.batch_number = "Please enter a Batch Number"
    }
    if (!trimWhiteSpace(this.state.sample_type_id)) {
      tempFormError.material_type = "Please select a Material Type"
    }
    if (!this.state.prep_method_id && this.state.prepared_samples.length === 0 && !this.state.isQCSample) {
      tempFormError.prep_method = "Please select a Preparation Method"
    }
    if (!this.state.test_method_id) {
      tempFormError.test_method_id = "Please select a Report Method"
    }
    this.setState({
      form_errors: {
        ...tempFormError
      }
    })
    return Object.keys(tempFormError).length === 0
  }

  intakeNumber = () => {
    const mmt = moment()
    const mmtMidnight = mmt.clone().startOf('day')
    const diffSeconds = mmt.diff(mmtMidnight, 'seconds')
    const date = mmt.format('MM-DD-YY')
    return `Cann.ID-${date}/${diffSeconds}-TechId`
  }  

  updateForm = (e) => {
    this.setState({
      form_errors: {},
      sample_test_created: null
    })
    const name = e.target.name
    const value = e.target.value
    this.setState((prevState, props) => {
      let newSample = $.extend({}, prevState.sample)
      let newState = $.extend({}, prevState)
      if (name === "sample_weight") {
        let numberVal = value
        newSample[name] = numberVal
      } else {
        if (newSample.hasOwnProperty(name)) {
          newSample[name] = value
        }
      }
      newState['sample'] = newSample
      return newState
    })
  }

  switchToStandardSample = () => {
	if (this.state.isQCSample === false) {
		return
	}

	this.resetForm(false)
  }

  switchToQCSample = () => {
	if (this.state.isQCSample === true) {
		return
	}

	this.resetForm(true)
  }

  isSampleNew() {
	return !(this.props.match.params.id)
  }

  sampleWeightConfirmation = () => {
    let formIsValid = this.validateForm()
    if (!formIsValid) {
      return false
    }
    if ((!isNaN(this.state.sample.dry_weight) && trimWhiteSpace(this.state.sample.dry_weight) !== '') && (isNaN(this.state.sample.as_harvested_weight) || trimWhiteSpace(this.state.sample.as_harvested_weight) === '')) {
      this.setState({ weightError: true })
      return false
    }
    if ((!isNaN(this.state.sample.as_harvested_weight) && trimWhiteSpace(this.state.sample.as_harvested_weight) !== '') && (isNaN(this.state.sample.dry_weight) || trimWhiteSpace(this.state.sample.dry_weight) === '')) {
      this.setState({ weightError: true })
      return false
    }
    this.setState({ sampleWeightFlag: true })
  }

  startTest = () => {
    let formIsValid = this.validateForm()
    if (!formIsValid) {
      return false
    }
    this.setState({ sampleWeightFlag: false })
	let theNotes = trimWhiteSpace(this.state.sample.notes)
	if (trimWhiteSpace(this.state.sample.notes) || (this.state.preEditNotes && !trimWhiteSpace(this.state.sample.notes))) {
		theNotes = `${trimWhiteSpace(this.state.sample.notes)} [${dateFormatterUtcString()}]`
	}
    const requestBody = {
      "sample": {
        "intake_form": {
          "strain_name": trimWhiteSpace(this.state.sample.strain_name),
          "intake_number": trimWhiteSpace(this.state.sample.intake_number),
          "sample_weight": trimWhiteSpace(this.state.sample.sample_weight),
          "acquisition_mode": "standard",
          "notes": theNotes,
          "post_notes": trimWhiteSpace(this.state.sample.post_notes),
          "custom_fields": this.state.custom_fields_updated,
          "batch_number": trimWhiteSpace(this.state.sample.batch_number),
		  "submission_identification": trimWhiteSpace(this.state.sample.submission_identification),
		  "prepared_for": trimWhiteSpace(this.state.sample.prepared_for),
		  "sample_image_url": this.state.sample.sample_image_url
        },
        "sample_type_id": (this.state.sample_type_id === null || this.state.sample_type_id === '') ? null : this.state.sample_type_id,
        "prepared_samples": this.state.prepared_samples,
        // handle custom prep_method_id as null in db in preparation of turning the prep_method_id field into a FK of the prep_methods table. FK wouldn't like 0
        "prep_method_id": this.state.prep_method_id === 0 ? null : this.state.prep_method_id,
        "test_method_id": this.state.test_method_id,
        "latitude": this.state.location[0],
        "longitude": this.state.location[1]
      }
    }

    if (this.props.match.params.id) {
      SampleTestServices.updateSample(requestBody, this.props.match.params.id).then(res => {
        this.props.history.push(`/sample/${this.props.match.params.id}/results`)
      }).catch((error) => {
        this.setState({
          sample_test_updated: false,
          errorMessage: get( error, 'response.statusText') || constants.SAMPLE_TEST.EDIT.FAIL,
          apiErrors: (error.data) ? error.data : {}
        })
      })
    }
    else {
      SampleTestServices.createSample(requestBody).then(res => {
        this.setState({
          sample_test_created: true,
          apiErrors: {}
        })
        setTimeout(() => {
          this.resetForm()
        }, 1000)
      }).catch((error) => {
        this.setState({
          sample_test_created: false,
          errorMessage: get( error, 'response.statusText', false) || constants.SAMPLE_TEST.CREATE.FAIL,
          apiErrors: (error.data) ? error.data : {}
        })
      })
    }
  }

  handleResponseCustomFields = (response, errors) => {
    let custom_errors = false
    errors.forEach(element => {
      if (element.formFields.length > 0) {
        custom_errors = true
      }
    })
    this.setState({
      custom_fields_updated: response,
      custom_fields_errors: custom_errors,
      custom_fields_error: (this.state.custom_fields_error === true ? custom_errors : this.state.custom_fields_error)
    })
  }

	setSampleType = (e, { name, value }) => {
		const newState = {
			form_errors: {},
			sample_test_created: null,
			sample_type_id: (value) ? value : null,
			sample_recommendations: this.state.sample_types.filter((type) => { return type.value === value }) ? this.state.sample_types.filter((type) => { return type.value === value })[0] : {}
		}

		if (this.state.isQCSample && newState.sample_recommendations) {
			const prepedSamplesMatch =  QC_SAMPLE_TYPE_PREPED_SAMPLES_MAP[newState.sample_recommendations.slug]
			if (prepedSamplesMatch) {
				newState.prepared_samples = prepedSamplesMatch
			}
		}

		this.setState(newState)
	}

  closeModal = () => {
    this.setState({
      sampleWeightFlag: false
    })
  }

  setPrepMethod = (e, {name, value}) => {
    let newState = {
      form_errors: {},
      sample_test_created: null,
      prep_method_id: undefined,
      prepared_samples: []
    }
    if (value === 0) {
      this.setState({
        ...newState,
        prep_method_id: 0
      })
    }
    // if no prep method id but prepared_samples exist, then handle as if the custom preparation method
    else if (!value && this.state.prepared_samples.length > 0) {
      let prep_method_id = 0
      this.setState({
        ...newState,
        prep_method_id: prep_method_id,
        prepared_samples: this.state.prepared_samples
      })
    }
    else {
      let prep_method_id = value
      let selected_method = this.state.prep_methods.find((pm) => (pm.id == prep_method_id))
      let prepared_samples = [...selected_method.prep_steps].map((ps) => {ps.acquire = ps.acquire_by_default; return ps})
      this.setState({
        ...newState,
        prep_method_id: value,
        prepared_samples: prepared_samples
      })
    }
  }

	uploadImage = async (image) => {
		try {
			let reader = new FileReader()
			if (image[0].size < constants.ACCOUNT.MAX_FILE_UPLOAD_SIZE) {
				if (image[0].name.match(/.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
					const awsUrl = await ImageUploadAWS(this.awsConf, image[0], this.props.match.params.id)
					if (awsUrl) {
						reader.onload = (e) => {
							this.setState((prevState) => {
								const sample = { ...prevState.sample }
								sample['sample_image_url'] = `${this.awsLink}/${awsUrl}`
								return ({
									image: [e.target.result],
									uploadFlag: true,
									imageError: undefined,
									sample
								})
							})
						}
						reader.readAsDataURL(image[0])
					}
					else {
						this.setState({ imageError: 'Image upload failed. Please try again.', uploadFlag: false })
					}
				}
				else {
					this.setState({
						imageError: 'Error reading selected file. Acceptable image file types: .jpg|.jpeg|.png',
						uploadFlag: false
					})
				}
			}
			else {
				this.setState({
					imageError: `Image size too large. Maximum size ${constants.ACCOUNT.MAX_FILE_UPLOAD_SIZE / 1000000}MB`,
					uploadFlag: false
				})
			}
		}
		catch (e) {
			console.error('error in uploading image:', e)
			this.setState({ imageError: 'Image upload failed. Please try again.' })
		}
	}

	removeImage = () => {
		this.setState((prevState) => {
			const sample = { ...prevState.sample }
			if (sample['sample_image_url']) delete sample['sample_image_url']
			return ({
				image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
				uploadFlag: false,
				imageError: undefined,
				sample
			})
		})
	}

  render() {
	const isSampleNew = this.isSampleNew()
	const isQCSample = this.state.isQCSample

	let breadcrumbPrevLink = null;
	if (isQCSample) {
		breadcrumbPrevLink = <Link to={constants.APPLICATION_ROUTE.QC.LIST.ROUTE}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.QC.LIST.NAME}</Breadcrumb.Section></Link>
	}
	else {
		breadcrumbPrevLink = <Link to={constants.APPLICATION_ROUTE.SAMPLE.LIST.ROUTE}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.SAMPLE.LIST.NAME}</Breadcrumb.Section></Link>
	}

    const breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
		{breadcrumbPrevLink}
        <Breadcrumb.Divider icon="right angle" />
        {!this.props.match.params.id ? <Breadcrumb.Section>{constants.APPLICATION_ROUTE.SAMPLE.CREATE.NAME}</Breadcrumb.Section> : <Breadcrumb.Section>Edit Sample</Breadcrumb.Section>}
      </Breadcrumb>
    )

	const title = isSampleNew ? constants.SAMPLE_TEST.CREATE.HEADER_TITLE : constants.SAMPLE_TEST.EDIT.HEADER_TITLE
	const filteredSampleTypes = this.state.sample_types.filter((sampleType) => {
		if (isQCSample) {
			return QC_SAMPLE_TYPE_SLUGS.includes(sampleType.slug)
		}
		else {
			return !QC_SAMPLE_TYPE_SLUGS.includes(sampleType.slug)
		}
	})

    const prepOptions = this.state.prep_methods.map((method, index) => {
      return (
        {
          key: `${method.id.toString() + index.toString()}`,
          text: method.name,
          value: method.id
        }
      )
    })

	if (isQCSample) {
		prepOptions.push({
			key: 'CUSTOM',
			text: 'Custom',
			value: 0
		})
	}

    if (localStorage.getItem('betaFeatures') === "true") {
      prepOptions.push({
        key: 'custom_prep_method',
        text: 'Custom',
        value: 0
      })
    }

    let prepMethodSelectBox = (
      <Form.Select
        name='prep_method'
        label='Preparation Method'
        value={isQCSample ? 0 : this.state.prep_method_id}
        onChange={this.setPrepMethod}
        options={prepOptions}
        placeholder='Preparation Method'
        error={this.state.form_errors.prep_method}
        disabled={!this.state.isFormEditable || isQCSample}
        required
      />
    )

    const reportMethodDropdown = (
      <Form.Select
        name='test_method_id'
        label='Report Method'
        value={this.state.test_method_id}
        onChange={(e, { name, value }) => {this.setState({form_errors: {}, test_method_id: value})}}
        options={this.state.test_methods.map((test_method, index) => {
          const text = test_method.default ? <span>{test_method.method_name} {<span style={{color:'rgba(0,0,0,.4)'}}>(Default)</span>}</span> : test_method.method_name
          return (
            {
              key: `${test_method.id.toString() + index.toString()}`,
              text: text,
              value: test_method.id
            }
          )
        })}
        placeholder='Report Method'
        error={this.state.form_errors.test_method_id}
        disabled={!this.state.isFormEditable || isQCSample}
        required />
    )

    const reportMethodDisplay = this.state.test_method_id ? this.state.test_methods.map((test_method, meth_index) => {
      if (test_method.id === this.state.test_method_id) {
        const analytes = test_method.analytes.map((analyte, anal_index) => {
          const analyteColor = "#" + analyte.color
          return (
            <Popup key={anal_index} content={analyte.analyte} size='tiny' trigger={
              <div className='ui button dynamicTableActionButton analytePill'
                  style={{backgroundColor: analyteColor}}>
                {analyte.analyte}
              </div>
            } />
          )
        })

        return (
          <Card fluid key={meth_index} className='methodDisplay'>
            <Card.Content>
              <Card.Description textAlign='center'>
                {reportMethodDropdown}
                {analytes}
              </Card.Description>
            </Card.Content>
          </Card>
        )
      }
    }) : reportMethodDropdown

	let qcSwitchUI = null

	if (isSampleNew) {
		qcSwitchUI = (
			<Grid.Row>
				<Button.Group>
					<Button primary={!isQCSample} onClick={this.switchToStandardSample}>
						Sample Test
					</Button>
					<Button.Or />
					<Button primary={isQCSample} onClick={this.switchToQCSample}>
						Quality Control Test
					</Button>
				</Button.Group>
			</Grid.Row>
		)
	}

    return ( 
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{title}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <div className="form screen" id='newSampleTest'>
              <Grid centered>
				{qcSwitchUI}
                <Grid.Column mobile={16} tablet={16} largeScreen={8} computer={8} textAlign='center'>
                  {!this.state.isFormEditable &&
                    <Message warning>{this.state.notEditableExcuse}</Message>
                  }
                  {this.state.sample_test_created === true &&
                    <ToastMessage title='Success' description={constants.SAMPLE_TEST.CREATE.SUCCESS} />}
                  {this.state.sample_test_updated === true &&
                    <ToastMessage title='Success' description={constants.SAMPLE_TEST.EDIT.SUCCESS} />}
                </Grid.Column>
              </Grid>
              <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
              <Form>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label="Batch Number" value={this.state.sample.batch_number}
                      name="batch_number" onChange={this.updateForm} type="text" className='required-field'
                      placeholder="Batch Number" error={this.state.form_errors.batch_number} disabled={!this.state.isFormEditable} />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    {/* certus name limit is 50 characters. strain name is used for sample test certus' so we must restrict here */}
                    <Form.Input maxLength={50} label="Strain/Sample Name" value={this.state.sample.strain_name} name="strain_name"
                      onChange={this.updateForm} type="text" className='required-field'
                      placeholder="Strain/Sample Name" error={this.state.form_errors.strain_name} disabled={!this.state.isFormEditable} />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label="Sample Weight (mg)" value={this.state.sample.sample_weight}
                      name="sample_weight" onChange={this.updateForm} type="text"
                      placeholder="Sample Weight (mg)"
                      error={this.state.form_errors.sample_weight} className='required-field' disabled={!this.state.isFormEditable || isQCSample} />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <div id="thcProfile" className="field required-field">
                      <label>Material Type</label>
                    </div>
                    <Form.Select search selection clearable error={this.state.form_errors.material_type} disabled={!this.state.isFormEditable} className='required-field' label="Material Type" value={this.state.sample_type_id} options={filteredSampleTypes} placeholder='Material Type' name='sample_type_id' onChange={this.setSampleType} />
                    {this.state.sample_recommendations && this.state.sample_type_id && this.state.sample_recommendations.recommended_size && this.state.sample_recommendations.rsd_percent && <Segment className='recommendation' textAlign='center'>Recommended Sample Size: <b>{this.state.sample_recommendations && this.state.sample_recommendations.recommended_size}</b> <br /> % Relative Standard Deviation: <b>{this.state.sample_recommendations && this.state.sample_recommendations.rsd_percent}</b> </Segment>}
                    {this.state.sample_recommendations && this.state.sample_type_id !== '' && (this.state.sample_recommendations.recommended_size === '' || this.state.sample_recommendations.recommended_size === null) && (this.state.sample_recommendations.rsd_percent !== '' && this.state.sample_recommendations.rsd_percent !== null) && <Segment className='recommendation' textAlign='center'>% Relative Standard Deviation: <b>{this.state.sample_recommendations && this.state.sample_recommendations.rsd_percent}</b> </Segment>}
                    {this.state.sample_recommendations && this.state.sample_type_id !== '' && (this.state.sample_recommendations.recommended_size !== '' && this.state.sample_recommendations.recommended_size !== null) && (this.state.sample_recommendations.rsd_percent === '' || this.state.sample_recommendations.rsd_percent === null) && <Segment className='recommendation' textAlign='center'>Recommended Sample Size: <b>{this.state.sample_recommendations && this.state.sample_recommendations.recommended_size}</b></Segment>}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={12} computer={12}>
                    {prepMethodSelectBox}
                    <PreparedSamplesInput previousPreppedSamples={this.state.prepared_samples} addPreppedSample={this.addPreppedSample} editable={this.state.prep_method_id === 0} disabled={!this.state.isFormEditable || isQCSample}/>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={12} computer={12} className="createSampleReportMethod">
                    {reportMethodDisplay}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Form.Input maxLength={50} label="Submission Identification #" value={this.state.sample.submission_identification}
						onChange={this.updateForm} type="text" name="submission_identification"
						placeholder='ILCTS12345' error={this.state.form_errors.submission_identification} disabled={!this.state.isFormEditable} />
					<Form.TextArea maxLength={constants.FIELD_LENGTH.MAX_64} label="Sample Notes" value={this.state.sample.notes}
						name="notes" onChange={this.updateForm} type="text"
						placeholder="Sample Notes (Max 64 Characters)"
						error={this.state.form_errors.notes} disabled={!this.state.isFormEditable} />
					{ this.props.user && this.props.user.organization.id != 2 &&
						<Form.TextArea maxLength={constants.FIELD_LENGTH.MAX_64} label="Prepared For" value={this.state.sample.prepared_for}
							name="prepared_for" onChange={this.updateForm} type="text"
							placeholder="Prepared For (Max 64 Characters)"
							error={this.state.form_errors.prepared_for} disabled={!this.state.isFormEditable || this.props.user.organization.id === 2} />
					}
                  </Grid.Column>
				  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
				  		<div className='field' style={{marginBottom: 0}}><label>Sample Image</label></div>
						<Segment style={{marginTop: 0}}>
							<Grid centered className='sampleImageUploadDisplay'>
								<ImageDropzone image={this.state.image} disabled={(this.state.uploadFlag || !this.state.isFormEditable)}
									uploadImage={(files) => this.uploadImage(files)}
									imageError={(error) => this.setState({ imageError: error })}/>
							</Grid>
							{this.state.isFormEditable &&
								<Grid centered style={{ marginTop: 0 }}>
									<Grid.Column textAlign='center'>
										<input
											disabled={!this.state.isFormEditable}
											type="file"
											accept="image/*"
											ref={this.upload}
											style={{ display: 'none' }}
											onChange={() =>{ this.uploadImage(this.upload.current.files)}}
										/>
										{this.state.imageError && <p className="error">{this.state.imageError}</p>}
										<Button icon labelPosition='left' primary={true} negative={false} size='small'
											disabled={(this.state.uploadFlag || !this.state.isFormEditable)}
											onClick={() => this.upload.current.click()}
											style={{margin: '0 .25em'}}>
											<Icon name={'arrow alternate circle up'} />{'Upload'}
										</Button>
										<Button icon labelPosition='left' primary={false} negative={true} size='small'
											disabled={(!this.state.uploadFlag || !this.state.isFormEditable)}
											onClick={() => this.removeImage()}
											style={{margin: '0 .25em'}}>
											<Icon name={'trash'} />{'Remove'}
										</Button>
									</Grid.Column>
								</Grid>
							}
						</Segment>
				  </Grid.Column>
                  {this.state.custom_fields_error &&
                    <Grid.Column mobile={16} tablet={16} largeScreen={12} computer={12} textAlign='center'>
                      <p className='error'>{constants.SAMPLE_TEST.CREATE.CUSTOM_FIELD_MANDATORY}</p>
                    </Grid.Column>
                  }
                </Grid>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
                    <Button className='confirmButton' type="submit" disabled={!this.state.isFormEditable}
                      onClick={this.props.match.params.id ? this.startTest : this.sampleWeightConfirmation}>
                      {this.state.confirmButtonText}
                    </Button>
                    <Button className='confirmButton' type="submit"
                      onClick={() => this.props.history.push(constants.APPLICATION_ROUTE.SAMPLE.LIST.ROUTE)}>Sample Results</Button>
					<Button className='confirmButton' type="submit"
                      onClick={() => this.props.history.push(constants.APPLICATION_ROUTE.QC.LIST.ROUTE)}>QC Results</Button>
                  </Grid.Column>
                </Grid>
                <Header as='h5' textAlign='right'>
                  <span style={{color: '#db2828'}}>*</span> Required
                </Header>
              </Form>
              <Modal size='mini' open={this.state.sampleWeightFlag} onClose={this.closeModal} closeIcon>
                <Modal.Header>Are you sure you want to proceed?</Modal.Header>
                <Modal.Content>
                  <p>Confirm Sample weight {this.state.sample.sample_weight}mg</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button className='cancelButton' onClick={this.closeModal}>Cancel</Button>
                  <Button className='confirmButton' icon='checkmark' labelPosition='right' content='Ok' onClick={this.startTest}></Button>
                </Modal.Actions>
              </Modal>
            </div>
          </section>
        </section>
      </div>
    )
  }
}
CreateSampleTest.propTypes = {}
export default connect(mapStateToProps)(CreateSampleTest)
